import React, { useContext, useState, useEffect } from "react";
import addOnLogo from "../../assets/icons/calendar + icon.png";
import { withRouter } from "react-router-dom";
import * as classNames from "classnames";
import { ButtonBase } from "components/ui/core/button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import QuickIcon from "@material-ui/icons/Receipt";
import ContractIcon from "@material-ui/icons/EventNote";
import TeamIcon from "@material-ui/icons/Group";
import PreventativeIcon from "@material-ui/icons/EventAvailable";
import ServiceIcon from "assets/icons/serviceIcon";
import VisualInspectionIcon from "assets/icons/visualInspectionsIcon";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormInput from "components/ui/FormContent/formInput";
import Tooltip from "@material-ui/core/Tooltip";
import FormSelect from "components/ui/FormContent/formSelect";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormSelectJobsAuto from "components/ui/FormContent/formSelectJobsAuto";
import FormSelectCheckboxJobsAuto from "components/ui/FormContent/formSelectCheckboxJobsAuto";
import BackIcon from "@material-ui/icons/ArrowBack";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import ProjectCreateJobs from "./projectCreateJobs";
import ConfirmDialog from "./dialog/confirmDialog";
import MessageDialog from "./dialog/messageDialog";
import { getJobsCustomer } from "services/jobService";
import { getCustomers } from "services/userService";
import {
  createProject,
  createProjectPreventative,
} from "services/projectService";
import GlobalUiContext from "contexts/globalUiContext";
import RedirectDialog from "components/ui/dialog/redirectSimpleDialog";
import ProjectDescriptionHelp from "./projectDescriptionHelp";
import { getAssetsAll } from "services/assetService";
import Chip from "@material-ui/core/Chip";
import Joi from "joi-browser";
import { logException } from "components/util/logUtil";

import {
  usePageHeaderState,
  usePageHeaderDispatch,
} from "contexts/pageHeaderContext";
import { projectOptions } from "constants.js";
import useStyles from "./styles";

const InitialData = {
  type: 5,
  description: "",
  customer_id: -1,
  job_id: null,
  job: null,
  job_list: [],
  customer: null,
  title: "",
  owner_type: 3,
  owner_id: null,
  owner: null,
  priority_level: 2,
  is_billable: 1,
  strict_scheduling: 0,
  is_timekeeping: 1,
  customer_contact_name: "",
  customer_contact_job_title: "",
  customer_contact_email: "",
  hasAssets: 0,
  asset_ids: [],
  is_payable: 0,
  assets: [],
  status: 1,
  is_created_from_quick_ticket: 0,
  woid_uid: "",
  external_woid: "",
  workticket_id_created_from: "",
};

const projectDescription = {
  0: "I want a simple way to organize quick tickets",
  1: "I want to perform add on work that I am going to bill the customer for",
  2: "I want to perform work that is part of the contract, so it’s not billable",
  3: "I want to pre-plan work for multiple services",
  4: "I want to perform work that is part of the contract, so it’s not billable",
  5: "Project to organize external staffing that is part of the contract with the customer. Actuals are compared to general labor budget.",
  6: "Project to organize periodic services that are part of the contract with the customer. Actuals are compared to Periodic work budget.",
  7: "This project is to organize inspections, not standard services",
};

const projectMessage = {
  0: "I want a simple way to organize quick tickets",
  1: "I want to perform add on work that I am going to bill the customer for",
  2: "I want to perform work that is part of the contract, so it’s not billable",
  3: "Project actuals are compared to your portfolio budget.",
  4: "I want to perform work that is part of the contract, so it’s not billable",
  5: "Project actuals will be compared to general labor budget.",
  6: "Project actuals are compared to your periodic work budget.",
  7: "This project is to organize inspections, not standard services.",
};

const projectIcons = {
  0: (
    <QuickIcon
      style={{ color: "#747474", width: 44, height: 44, marginBottom: 5 }}
    />
  ),
  1: null,
  2: "",
  3: (
    <PreventativeIcon
      style={{ color: "#747474", width: 44, height: 44, marginBottom: 5 }}
    />
  ),
  4: "",
  5: (
    <TeamIcon
      style={{ color: "#747474", width: 44, height: 44, marginBottom: 5 }}
    />
  ),
  6: (
    <ContractIcon
      style={{ color: "#747474", width: 44, height: 44, marginBottom: 5 }}
    />
  ),
  7: (
    <VisualInspectionIcon style={{ width: 44, height: 44, marginBottom: 5 }} />
  ),
};

const customersExternal = ["Walgreens"];
const VISUAL_INSPECTION_PROJECT_ID = 7;

const ProjectCreate = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState(InitialData);
  const [jobs, setJobs] = useState([]);
  const [assets, setAssets] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [customers, setCustomers] = useState(null);
  const [msgRedirect, setMsgRedirect] = useState("");
  const [linkRedirect, setLinkRedirect] = useState("");
  const [labelRedirect, setLabelRedirect] = useState("");
  const [openRedirect, setOpenRedirect] = useState(false);
  const [isLoadingJob, setIsLoadingJob] = useState(false);
  const [error, setError] = useState([]);
  const [schema, setSchema] = useState({});
  const [managedByOptions, setManagedByOptions] = useState([]);
  const [projectType, setProjectType] = useState("normal");
  const user = JSON.parse(localStorage.getItem("user"));
  const { pageHeaderTabs } = usePageHeaderState();
  const dispatchPageHeader = usePageHeaderDispatch();
  const { users } = globalUi;

  useEffect(() => {
    const loadJob = async () => {
      try {
        setIsLoadingJob(true);
        const resultJob = await getJobsCustomer(data.customer_id);
        const resultForRender = resultJob.data.data.jobs.map((job) => {
          return {
            id: job.id,
            job_description: job.job_description,
            job_number: job.job_number,
            city: job.city,
            state: job.state,
            zip: job.zip,
          };
        });
        setJobs(resultForRender);
        setIsLoadingJob(false);
        if (globalUi.quickTicket) {
          const { job } = globalUi.quickTicket;
          const newData = {
            ...data,
            job: {
              id: job.id,
              job_description: job.job_description,
              job_number: job.job_number,
            },
            job_id: job.id,
            job_list: [
              {
                id: job.id,
                job_description: job.job_description,
                job_number: job.job_number,
              },
            ],
          };
          setData(newData);
        }
      } catch (e) {
        logException(e, "Cannot load jobs data");
      }
    };
    if (data.customer_id >= 0) {
      loadJob();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.customer_id, globalUi.quickTicket]);

  useEffect(() => {
    if (globalUi.quickTicket) {
      handleClick(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalUi.quickTicket]);

  useEffect(() => {
    if (globalUi.quickTicket && customers) {
      const { job } = globalUi.quickTicket;
      if (job) {
        const { customer } = job;
        const newData = {
          ...data,
          customer: { value: customer.id, label: customer.name },
          customer_id: customer.id,
        };
        setData(newData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalUi.quickTicket, customers]);

  useEffect(() => {
    const loadCustomer = async () => {
      try {
        const resultUser = await getCustomers();
        const { customers } = resultUser.data.data;
        const resultForRender = customers.map((customer) => {
          return { value: customer.id, label: customer.name };
        });
        setCustomers(resultForRender);
      } catch (e) {
        logException(e, "Cannot load customer data");
      }
    };
    if (!customers) {
      loadCustomer();
    }
  }, [customers]);

  useEffect(() => {
    const loadAssets = async () => {
      try {
        let valuesJobs = [];
        if (projectType === "normal") {
          valuesJobs.push({ label: "", value: data.job_id });
        }
        if (projectType === "preventative") {
          valuesJobs = data.job_list.map((job) => {
            return { label: "", value: job.id };
          });
        }
        const resultAssets = await getAssetsAll({
          filters: [
            {
              filter: "job",
              group: "Job",
              values: valuesJobs,
            },
          ],
        });
        const { assets } = resultAssets.data.data;
        const resultForRender = assets.map((asset) => {
          return { value: asset.id, label: asset.name };
        });
        const newData = {
          ...data,
          assets: [],
          asset_ids: [],
        };
        setData(newData);
        setAssets(resultForRender);
      } catch (e) {
        logException(e, "Cannot load asset data");
      }
    };
    if (data.job_id || (data.job_list && data.job_list.length)) {
      loadAssets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.job_id, data.job_list]);

  useEffect(() => {
    if (users && users.length) {
      const uList = users
        .filter(
          (u) =>
            ![
              "subcontractor",
              "subcontractor_admin",
              "subcontractor_crew",
              "client",
            ].includes(u.tier_classification)
        )
        .map((user) => {
          return {
            value: user.id,
            label: `${user.first_name} ${user.last_name}`,
          };
        });
      setUsersList(uList);
    }
  }, [users]);

  const handleClick = (option) => {
    let newData = {};
    if (globalUi.quickTicket) {
      newData = {
        ...data,
        type: option,
        is_billable: [0, 2].includes(option) ? 0 : 1,
        description: globalUi.quickTicket.description,
        title: globalUi.quickTicket.subject,
        is_created_from_quick_ticket: 1,
        woid_uid: globalUi.quickTicket.woid_uid,
        external_woid: globalUi.quickTicket.external_woid,
        workticket_id_created_from: globalUi.quickTicket.id,
      };
    } else {
      newData = {
        ...data,
        type: option,
        is_billable: [0, 2].includes(option) ? 0 : 1,
      };
    }

    if ([3, 6, VISUAL_INSPECTION_PROJECT_ID].includes(option)) {
      setProjectType("preventative");
    } else {
      setProjectType("normal");
    }

    setData(newData);
  };

  const changeHasAssets = (option) => {
    let newData = {};
    if (option === 1) {
      newData = {
        ...data,
        hasAssets: option,
      };
    } else {
      newData = {
        ...data,
        hasAssets: option,
        assets: [],
        asset_ids: [],
      };
    }
    setData(newData);
  };

  const storeProject = async () => {
    try {
      if (projectType === "normal") {
        const result = await createProject(data);
        if (globalUi.quickTicket) {
          dispatchPageHeader({
            type: "SET_PAGE_TAB",
            pageHeaderTabs: [
              ...pageHeaderTabs.filter(
                (page) => page.link !== window.location.pathname
              ),
            ],
          });
        }
        if (!globalUi.createFlow) {
          setMsgRedirect("Project has been created.");
          setLabelRedirect("Okay");
          setLinkRedirect(`/project/${result.data.data.project.id}`);
          setOpenRedirect(true);
        } else {
          dispatchGlobalUi({
            type: "SET_FLOW",
            createFlow: null,
          });
          dispatchGlobalUi({
            type: "SET_FLOW_OBJECT",
            createObject: result.data.data.project,
          });
          if (globalUi.createFlow === "quote") {
            dispatchGlobalUi({
              type: "TOGGLE_SIDEDRAWER",
              isDrawerOpen: true,
              drawerContent: "quote",
            });
          } else {
            dispatchGlobalUi({
              type: "TOGGLE_SIDEDRAWER",
              isDrawerOpen: true,
              drawerContent: "workticket",
            });
          }
        }
      } else if (projectType === "preventative") {
        const projectExternal = customersExternal.includes(data.customer.label);
        const job_ids = data.job_list.map((job) => job.id);

        const dataPreventative = {
          ...data,
          job_ids,
          is_external: projectExternal ?? 0,
        };
        const result = await createProjectPreventative(dataPreventative);
        setMsgRedirect("Project has been created.");
        setLabelRedirect("Okay");
        setLinkRedirect(`/project/${result.data.data.project.id}`);
        setOpenRedirect(true);
      }
    } catch (e) {
      logException(e, "Cannot create project");
    }
  };

  const changeStep = (n) => {
    if (n === 1) {
      let jobValidation = {};
      if (projectType === "normal") {
        jobValidation = {
          job: Joi.object()
            .required()
            .label("Job")
            .error(() => {
              return {
                message: "Job is required.",
              };
            }),
        };
      }

      setSchema({
        ...jobValidation,
        customer: Joi.object()
          .required()
          .label("Customer")
          .error(() => {
            return {
              message: "Customer is required.",
            };
          }),
        title: Joi.string()
          .min(1)
          .required()
          .label("Project Name")
          .error(() => {
            return {
              message: "Project Name is required.",
            };
          }),
        description: Joi.string()
          .min(1)
          .required()
          .label("Project Description")
          .error(() => {
            return {
              message: "Project Description is required.",
            };
          }),
      });
      setStep(n);
    } else {
      const errors = validate(schema, data);

      if (!Boolean(errors) || n < step) {
        if (n === 2) {
          if (
            user.is_admin ||
            (globalUi.role &&
              globalUi.role.category === "executive" &&
              globalUi.role.tier === 2) ||
            (globalUi.role &&
              globalUi.role.category === "operations" &&
              globalUi.role.tier === 3)
          ) {
            setManagedByOptions(projectOptions.owner);
          } else {
            setManagedByOptions(
              projectOptions.owner.filter((item) => !item.admin)
            );
          }
          if (data.owner_type !== 1)
            setSchema({
              owner_type: Joi.number()
                .required()
                .min(0)
                .label("Owner")
                .error(() => {
                  return {
                    message: "Owner is required.",
                  };
                }),
            });
          else {
            setSchema({
              owner_type: Joi.number()
                .required()
                .min(0)
                .label("Owner")
                .error(() => {
                  return {
                    message: "Owner is required.",
                  };
                }),
              owner: Joi.object()
                .required()
                .label("Owner")
                .error(() => {
                  return {
                    message: "Team Member is required.",
                  };
                }),
            });
          }
        } else if (n === 3) {
          setSchema({});
        }

        if (n === 4) {
          if (!globalUi.quickTicket) {
            storeProject();
          } else {
            setOpen2(true);
          }
        } else {
          setStep(n);
        }
      }
    }
  };

  const validate = (schema, analyzeData) => {
    const options = { abortEarly: false };
    const keyNames = Object.keys(schema);
    let data = {};
    for (let key of keyNames) {
      data[key] = analyzeData[key];
    }
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    if (Boolean(errors)) {
      const keyNames = Object.keys(errors);
      const errorArray = [];
      for (let key of keyNames) {
        errorArray.push({ key, message: errors[key] });
      }
      setError(errorArray);
    } else {
      setError([]);
    }
    return errors;
  };

  const validateProperty = (name, value, schemaAnalyzed) => {
    const obj = { [name]: value };
    const schema = { [name]: schemaAnalyzed[name] };
    const { error: errors } = Joi.validate(obj, schema);
    if (Boolean(errors)) {
      setError([...error, { key: name, message: errors.details[0].message }]);
    } else {
      setError(error.filter((err) => err.key !== name));
    }
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const closeDialog2 = () => {
    setOpen2(false);
  };

  const closeMessageDialog = () => {
    setOpenMessage(false);
    dispatchGlobalUi({ type: "TOGGLE_SIDEDRAWER", isDrawerOpen: false });
  };

  const changeJob = (event, value) => {
    const newData = {
      ...data,
      job: value,
      job_id: value ? value.id : null,
    };
    setData(newData);
    validateProperty("job", value, schema);
  };

  const changeJobList = (event, value) => {
    const newData = {
      ...data,
      job_list: value ?? [],
    };
    setData(newData);
  };

  const changeJobFilterList = (jobsIds) => {
    const jobList = jobs.filter((job) => jobsIds.includes(job.id));
    const newData = {
      ...data,
      job_list: jobList,
    };
    setData(newData);
  };

  useEffect(() => {
    if (globalUi.createFlow === "workticket" && globalUi.quickTicket === null)
      handleClick(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalUi.createFlow, globalUi.quickTicket]);

  const handleChangeCustomer = (event, value) => {
    const newData = {
      ...data,
      customer: value,
      job: null,
      job_id: null,
      job_list: [],
      customer_id: value ? value.value : null,
    };
    setData(newData);
    validateProperty("customer", value, schema);
  };

  const handleChangeUser = (event, value) => {
    const newData = {
      ...data,
      owner: value,
      owner_id: value ? value.value : null,
    };
    setData(newData);
    validateProperty("owner", value, schema);
  };

  const handleBlur = (event, value) => {
    const newData = {
      ...data,
      [event.name]: event.value,
    };
    setData(newData);
    validateProperty(event.name, event.value, schema);

    if (event.name === "owner_type") {
      let validationSchema = {
        owner_type: Joi.number()
          .required()
          .min(0)
          .label("Owner")
          .error(() => {
            return {
              message: "Owner is required.",
            };
          }),
      };

      if (event.value === 1) {
        validationSchema.owner = Joi.object()
          .required()
          .label("Owner")
          .error(() => {
            return {
              message: "Team Member is required.",
            };
          });
      }

      setSchema(validationSchema);
    }
  };

  const handleCloseRedirect = () => {
    setOpenRedirect(false);
  };

  const handleChangeAsset = (event, value) => {
    const assetList = data.assets;
    const assetIds = data.asset_ids;
    if (value) {
      assetList.push(value);
      assetIds.push(value.value);
      const newData = {
        ...data,
        asset_ids: assetIds,
        assets: assetList,
      };
      setData(newData);
    }
  };

  const handleAssetDelete = (id) => {
    const assetList = data.assets.filter((asset) => asset.value !== id);
    const assetIds = data.asset_ids.filter((asset) => asset !== id);
    const newData = {
      ...data,
      asset_ids: assetIds,
      assets: assetList,
    };

    setData(newData);
  };

  const openDialog = () => {
    setOpen(true);
    setMessage(projectMessage[data.type]);
  };

  return (
    <>
      <MessageDialog
        open={openMessage}
        title={"Success!"}
        message={"Your Project has been created"}
        handleClose={() => closeMessageDialog()}
      />
      <RedirectDialog
        open={openRedirect}
        hasClose={false}
        handleClose={handleCloseRedirect}
        title="Success"
        message={msgRedirect}
        redirectLabel={labelRedirect}
        redirect={linkRedirect}
      />
      {step === 0 ? (
        <>
          <ConfirmDialog
            open={open}
            message={message}
            title={
              [3, 5, 6, VISUAL_INSPECTION_PROJECT_ID].includes(data.type)
                ? "Are you sure?"
                : "Projects"
            }
            icon={projectIcons[data.type]}
            handleConfirm={() => {
              changeStep(1);
              closeDialog();
            }}
            handleClose={() => closeDialog()}
            cancelText={
              [3, 5, 6, VISUAL_INSPECTION_PROJECT_ID].includes(data.type)
                ? "Cancel"
                : "Continue"
            }
            okText={
              [3, 5, 6, VISUAL_INSPECTION_PROJECT_ID].includes(data.type)
                ? "Continue"
                : "Okay"
            }
          />
          <Box className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              Create New Project {globalUi.createFlow ? "For Workticket" : ""}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              Tell us more about the project you're creating.
            </Typography>
          </Box>
          <Box className={classes.optionWrapper}>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.contentTitle}
            >
              What kind of project are you creating?
            </Typography>
            {globalUi.createFlow === "workticket" &&
            globalUi.quickTicket === null ? (
              <Grid container className={classes.optionContainer} spacing={6}>
                <Grid item xs={4} className={classes.optionGrid}>
                  <Tooltip title={projectDescription[0]}>
                    <Paper
                      className={
                        data.type === 0
                          ? classes.optionPaperActive
                          : classes.optionPaper
                      }
                      square={true}
                      elevation={0}
                    >
                      <Box
                        className={classes.optionBox}
                        onClick={() => handleClick(0)}
                      >
                        <Avatar
                          className={classNames(
                            classes.avatarBox,
                            classes.avatarType4
                          )}
                        >
                          <QuickIcon />
                        </Avatar>
                        <Typography
                          variant="body1"
                          className={classes.optionBoxTitle}
                          gutterBottom
                        >
                          Quick Tickets
                        </Typography>
                      </Box>
                    </Paper>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <Grid container className={classes.optionContainer} spacing={6}>
                <Grid item xs={4} className={classes.optionGrid}>
                  <Tooltip title={projectDescription[1]}>
                    <Paper
                      className={
                        data.type === 1
                          ? classes.optionPaperActive
                          : classes.optionPaper
                      }
                      square={true}
                      elevation={0}
                    >
                      <Box
                        className={classes.optionBox}
                        onClick={() => handleClick(1)}
                      >
                        <Avatar
                          className={classNames(
                            classes.avatarBox,
                            classes.avatarType01
                          )}
                        >
                          <img
                            src={addOnLogo}
                            alt="Icon continuous progress"
                            width="18"
                            height="20"
                          />
                        </Avatar>
                        <Typography
                          variant="body1"
                          className={classes.optionBoxTitle}
                          gutterBottom
                        >
                          Add-On Work
                        </Typography>
                      </Box>
                    </Paper>
                  </Tooltip>
                </Grid>
                <Grid item xs={4} className={classes.optionGrid}>
                  <Tooltip title={projectDescription[5]}>
                    <Paper
                      className={
                        data.type === 5
                          ? classes.optionPaperActive
                          : classes.optionPaper
                      }
                      square={true}
                      elevation={0}
                    >
                      <Box
                        className={classes.optionBox}
                        onClick={() => handleClick(5)}
                      >
                        <Avatar
                          className={classNames(
                            classes.avatarBox,
                            classes.avatarType5
                          )}
                        >
                          <TeamIcon />
                        </Avatar>
                        <Typography
                          variant="body1"
                          className={classes.optionBoxTitle}
                          gutterBottom
                        >
                          Temp-Staffing
                        </Typography>
                      </Box>
                    </Paper>
                  </Tooltip>
                </Grid>
                {globalUi.createFlow !== "quote" ? (
                  <Grid item xs={4} className={classes.optionGrid}>
                    <Tooltip title={projectDescription[6]}>
                      <Paper
                        className={
                          data.type === 6
                            ? classes.optionPaperActiveBig
                            : classes.optionPaperBig
                        }
                        square={true}
                        elevation={0}
                      >
                        <Box
                          className={classes.optionBox}
                          onClick={() => handleClick(6)}
                        >
                          <Avatar
                            className={classNames(
                              classes.avatarBox,
                              classes.avatarType2
                            )}
                          >
                            <ContractIcon />
                          </Avatar>
                          <Typography
                            variant="body1"
                            className={classes.optionBoxTitle}
                            gutterBottom
                          >
                            Contract - Periodic Work
                          </Typography>
                        </Box>
                      </Paper>
                    </Tooltip>
                  </Grid>
                ) : null}
                {globalUi.createFlow !== "quote" ? (
                  <Grid item xs={4} className={classes.optionGrid}>
                    <Tooltip title={projectDescription[3]}>
                      <Paper
                        className={
                          data.type === 3
                            ? classes.optionPaperActive
                            : classes.optionPaper
                        }
                        square={true}
                        elevation={0}
                      >
                        <Box
                          className={classes.optionBox}
                          onClick={() => handleClick(3)}
                        >
                          <Avatar
                            className={classNames(
                              classes.avatarBox,
                              classes.avatarNoBg
                            )}
                          >
                            <ServiceIcon width={35} height={35} />
                          </Avatar>
                          <Typography
                            variant="body1"
                            className={classes.optionBoxTitle}
                            gutterBottom
                          >
                            Multisite Services
                          </Typography>
                        </Box>
                      </Paper>
                    </Tooltip>
                  </Grid>
                ) : null}
                {globalUi.createFlow !== "quote" &&
                globalUi.quickTicket === null ? (
                  <Grid item xs={4} className={classes.optionGrid}>
                    <Tooltip title={projectDescription[0]}>
                      <Paper
                        className={
                          data.type === 0
                            ? classes.optionPaperActive
                            : classes.optionPaper
                        }
                        square={true}
                        elevation={0}
                      >
                        <Box
                          className={classes.optionBox}
                          onClick={() => handleClick(0)}
                        >
                          <Avatar
                            className={classNames(
                              classes.avatarBox,
                              classes.avatarType4
                            )}
                          >
                            <QuickIcon />
                          </Avatar>
                          <Typography
                            variant="body1"
                            className={classes.optionBoxTitle}
                            gutterBottom
                          >
                            Quick Tickets
                          </Typography>
                        </Box>
                      </Paper>
                    </Tooltip>
                  </Grid>
                ) : null}
                {globalUi.createFlow !== "quote" ? (
                  <Grid item xs={4} className={classes.optionGrid}>
                    <Tooltip
                      title={projectDescription[VISUAL_INSPECTION_PROJECT_ID]}
                    >
                      <Paper
                        className={
                          data.type === VISUAL_INSPECTION_PROJECT_ID
                            ? classes.optionPaperActive
                            : classes.optionPaper
                        }
                        square={true}
                        elevation={0}
                      >
                        <Box
                          className={classes.optionBox}
                          onClick={() =>
                            handleClick(VISUAL_INSPECTION_PROJECT_ID)
                          }
                        >
                          <Avatar
                            className={classNames(
                              classes.avatarBox,
                              classes.avatarNoBg
                            )}
                          >
                            <VisualInspectionIcon width={35} height={35} />
                          </Avatar>
                          <Typography
                            variant="body1"
                            className={classes.optionBoxTitle}
                            gutterBottom
                          >
                            Inspections
                          </Typography>
                        </Box>
                      </Paper>
                    </Tooltip>
                  </Grid>
                ) : null}
              </Grid>
            )}
            {data.type >= 0 ? (
              <ProjectDescriptionHelp projectType={data.type} />
            ) : null}

            <Box className={classes.actionBox}>
              <ButtonBase
                color="secondary"
                disabled={data.type < 0}
                onClick={() => openDialog()}
                disableElevation
              >
                Begin
              </ButtonBase>
            </Box>
          </Box>
        </>
      ) : null}
      {step === 1 ? (
        <>
          <Box className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              General Information
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              Tell us more about the project you're creating.
            </Typography>
          </Box>
          <Box className={classes.optionWrapper}>
            <Box className={classes.containerInformationCreate}>
              <FormSelectAuto
                gridSizes={[{ size: "md", val: 12 }]}
                options={customers}
                name="customer"
                error={error}
                handleChange={handleChangeCustomer}
                label="Customer*"
                value={data.customer}
              />
              {projectType === "normal" ? (
                !isLoadingJob ? (
                  <FormSelectJobsAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={jobs}
                    name="job"
                    label="Job*"
                    error={error}
                    value={data.job}
                    handleChange={changeJob}
                    readonly={!data.customer}
                  />
                ) : (
                  <LinearProgress color="secondary" />
                )
              ) : null}

              {projectType === "preventative" ? (
                !isLoadingJob ? (
                  <>
                    <ProjectCreateJobs
                      jobs={jobs}
                      jobsSelected={data.job_list}
                      changeJobFilterList={changeJobFilterList}
                    />
                    <FormSelectCheckboxJobsAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={jobs}
                      name="job_list"
                      label="Jobs (Select one or multiple)"
                      error={error}
                      value={data.job_list}
                      handleChange={changeJobList}
                      multiple={true}
                      readonly={!jobs.length}
                    />
                  </>
                ) : (
                  <LinearProgress color="secondary" />
                )
              ) : null}
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="title"
                label="Project Name*"
                error={error}
                value={data.title}
                handleBlur={handleBlur}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="description"
                label="Project Description*"
                error={error}
                value={data.description}
                handleBlur={handleBlur}
                multiline={true}
                rows={4}
              />
              {globalUi?.quickTicket?.workticket_category === 4 ? (
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="external_woid"
                  label="WOID"
                  value={globalUi.quickTicket.external_woid}
                  readonly={true}
                />
              ) : null}
            </Box>
            <Box className={classes.actionBox2}>
              <Button
                color="primary"
                className={classes.buttonBack}
                onClick={() => {
                  changeStep(0);
                }}
                startIcon={<BackIcon />}
              >
                Back
              </Button>
              <Button
                className={classNames(
                  classes.buttonBeginLink,
                  classes.buttonBegin
                )}
                onClick={() => {
                  changeStep(2);
                }}
              >
                Next
              </Button>
            </Box>
          </Box>
        </>
      ) : null}
      {step === 2 ? (
        <>
          <div className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              Project Options
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              We need to know a few more details about your project.
            </Typography>
          </div>
          <Box className={classes.optionWrapper}>
            <Box className={classes.containerInformationCreate}>
              <FormSelect
                gridSizes={[{ size: "md", val: 12 }]}
                options={managedByOptions}
                name="owner_type"
                label="I am creating this project for"
                fieldValue="value"
                error={error}
                fieldText="label"
                fieldKey="value"
                value={data.owner_type}
                handleBlur={handleBlur}
              />
              {data.owner_type === 1 && (
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={usersList}
                  name="owner"
                  handleChange={handleChangeUser}
                  label="Team Member"
                  error={error}
                  value={data.owner}
                />
              )}
              <Box className={classes.containerOptions2}>
                <InputLabel className={classes.inputLabelP}>
                  Are there assets associated with this project?
                </InputLabel>
                <ButtonGroup
                  variant="outlined"
                  color="default"
                  aria-label="primary button group"
                  className={classes.groupOption2}
                  fullWidth={true}
                >
                  <Button
                    className={
                      data.hasAssets === 1 ? classes.groupOptionActive : null
                    }
                    onClick={() => changeHasAssets(1)}
                  >
                    Yes
                  </Button>
                  <Button
                    className={
                      data.hasAssets === 0 ? classes.groupOptionActive : null
                    }
                    onClick={() => changeHasAssets(0)}
                  >
                    No
                  </Button>
                </ButtonGroup>
              </Box>
              {data.hasAssets === 1 && (
                <>
                  <Box className={classes.containerAssetInput}>
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={assets}
                      internal={false}
                      name="assets"
                      label="Assets"
                      multiple={false}
                      handleChange={handleChangeAsset}
                    />
                  </Box>
                  <Box className={classes.containerSelectedElements}>
                    {data.assets.map((asset) => (
                      <Chip
                        key={asset.value}
                        size="small"
                        label={`${asset.label}`}
                        onDelete={() => handleAssetDelete(asset.value)}
                        className={classes.chipAsset}
                      />
                    ))}
                  </Box>
                </>
              )}
            </Box>
            <Box className={classes.actionBox2}>
              <Button
                color="primary"
                className={classes.buttonBack}
                onClick={() => {
                  changeStep(1);
                }}
                startIcon={<BackIcon />}
              >
                Back
              </Button>
              <Button
                className={classNames(
                  classes.buttonBeginLink,
                  classes.buttonBegin
                )}
                onClick={() => {
                  changeStep(4);
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
          <ConfirmDialog
            open={open2}
            message={
              "Project will be created, do you want to continue? This conversion is final and it cannot be changed"
            }
            handleConfirm={() => {
              storeProject();
              closeDialog2();
            }}
            handleClose={() => closeDialog2()}
          />
        </>
      ) : null}
      {step === 5 ? (
        <>
          <Box className={classes.headerContainer}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom={true}
              className={classes.headerContainerTitle}
            >
              Customer Information
            </Typography>
            <Typography
              variant="h6"
              gutterBottom={true}
              className={classes.headerContainerSubTitle}
            >
              Tell us more about the customer you're creating this project for.
            </Typography>
          </Box>
          <Box className={classes.optionWrapper}>
            <Box className={classes.containerInformationCreate}>
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="customer_contact_name"
                label="Contact Name"
                error={error}
                value={data.customer_contact_name}
                handleBlur={handleBlur}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="customer_contact_job_title"
                label="Contact Job Title"
                error={error}
                value={data.customer_contact_job_title}
                handleBlur={handleBlur}
              />
              <FormInput
                gridSizes={[{ size: "md", val: 12 }]}
                name="customer_contact_email"
                label="Contact Email"
                error={error}
                value={data.customer_contact_email}
                handleBlur={handleBlur}
              />
            </Box>
            <Box className={classes.actionBox2}>
              <Button
                color="primary"
                className={classes.buttonBack}
                onClick={() => {
                  changeStep(3);
                }}
                startIcon={<BackIcon />}
              >
                Back
              </Button>
              <Button
                className={classNames(
                  classes.buttonBeginLink,
                  classes.buttonBegin
                )}
                onClick={() => {
                  changeStep(5);
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </>
      ) : null}
    </>
  );
};

export default withRouter(ProjectCreate);
