import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import SearchBar from "../SearchBar/searchBar";

import { useTableState } from "../../../contexts/tableContext";
import { Button } from "@material-ui/core";

const TableToolbarSelected = (props) => {
  const classes = useStyles();
  const { hasSearch, hasFilters } = useTableState();
  const { exportAllowed, handleExportClick } = props;
  return (
    <Box className={classes.toolbarControls}>
      {hasSearch ? (
        <Fragment>
          <SearchBar
            handleSearch={props.handleSearch}
            handleSearchClear={props.handleSearchClear}
            placeholder={props.placeholderSearch}
            controlledSearch={props.controlledSearch}
            isSearchAi={props.isSearchAi}
            isLoadingSearch={props.isLoadingSearch}
            module={props.module}
            searchTriggered={props.searchTriggered}
          />
          {exportAllowed && (
            <Button
              className={classes.exportAllButton}
              onClick={handleExportClick}
            >
              Export All
            </Button>
          )}
        </Fragment>
      ) : null}
      {hasFilters ? (
        <Box className={classes.toolbarFilters}>{props.children}</Box>
      ) : null}
    </Box>
  );
};

export default TableToolbarSelected;
