import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: "bold",
  },
  tabBarAppBar: {
    backgroundColor: "#fbfbfb",
    marginBottom: 0,
    borderBottom: "transparent",
    height: 41,
    minHeight: 41,
    paddingRight: 80,
    position: "relative",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
    },
  },
  tabBarAppBarCompact: {
    backgroundColor: "#fbfbfb",
    marginBottom: 0,
    borderBottom: "transparent",
    height: 41,
    minHeight: 41,
    paddingRight: 80,
    position: "relative",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
    },
    "& .MuiTab-root": {
      width: "130px !important",
    },
  },
  closeButton: {
    position: "absolute",
    right: 5,
    top: 12,
    "&:hover": {
      color: "#d32f2f",
    },
  },
}));

export const StyledTab = withStyles((theme) => ({
  root: {
    fontSize: 14,
    textTransform: "none",
    minWidth: "auto",
    padding: "2px 19px",
    height: 41,
    minHeight: 41,
    width: 206,
    marginRight: 0,
    fontWeight: "bold",
    color: "#dfdfdf",
    textAlign: "left",
    borderBottom: "solid 1px #ececec",
    whiteSpace: "nowrap",
    "&:hover": {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  selected: {
    backgroundColor: "#fff",
    borderRight: "solid 1px #ececec",
    borderLeft: "solid 1px #ececec",
    borderBottom: "solid 1px #ffffff",
    height: 41,
    minHeight: 41,
    position: "relative",
    zIndex: 20,
    color: "#747474 !important",
    [theme.breakpoints.down("sm")]: {
      display: "block !important",
    },
  },
  wrapper: {
    flexDirection: "row",
    justifyContent: "flex-start",
    "& > svg": {
      marginBottom: "0 !important",
      marginRight: 5,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export const StyledTabs = withStyles((theme) => ({
  root: {
    height: 41,
    minHeight: 41,
    borderBottom: "solid 1px #ececec",
    overflow: "visible",
    "& > div > div > button:first-child": {
      borderLeft: "0 !important",
    },
  },
  indicator: {
    display: "none",
  },
  scrollable: {
    overflowX: "visible",
  },
  flexContainer: {
    height: 41,
    minHeight: 41,
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

export default useStyles;
