import "./map.css";

import React, { useContext } from "react";
import DashboardMain from "./dashboardMain";
import DashboardMapbox from "./map/dashboardMapbox";
import { DashboardProvider } from "contexts/dashboardContext";
import GlobalUiContext from "contexts/globalUiContext";
import {
  hasPermission,
  permissionDashboard,
  permissionSurface,
} from "lib/permissions";
import { MapProvider } from "./map/context/useMapContext";

const Dashboard = ({ history }) => {
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const permDashboard =
    hasPermission(permissionSurface.MAP, permissions) ||
    hasPermission(permissionDashboard.MAP, permissions);

  const isSurfaceUser = hasPermission(permissionSurface.MAP, permissions);

  return (
    <DashboardProvider>
      {permDashboard ? (
        // comment out DashboardMapbox component
        <>
          <MapProvider>
            <DashboardMapbox history={history} isSurfaceUser={isSurfaceUser} />
          </MapProvider>
        </>
      ) : null}
    </DashboardProvider>
  );
};

export default Dashboard;
