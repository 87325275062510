import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import MessageDialog from "components/ui/dialog/messageDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import { loadGeo, getGeoLocation } from "components/util/geoUtil";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import SearchBar from "./SearchBar";
import { getReasonFeedback, storeFeedback } from "services/aiServices";
import { LunaLogo } from "assets/icons/lunaLogo";
import useStyles from "./styles";
import { logException } from "components/util/logUtil";
import { ThumbDown, ThumbUp } from "components/common/LunaLens/images/images";

const validateFields = (reason, comment) => {
  const errors = {};
  const validationRules = {
    reason: !reason.length,
    comment: !comment,
  };
  Object.keys(validationRules).forEach((key) => {
    if (validationRules[key]) {
      errors[key] = validationRules[key];
    }
  });
  return errors;
};

export const LunaSearchInput = ({
  isSearchAi,
  module,
  model,
  onHandleLunaSearch,
  onHandleSearchResponse,
  setLunaSearchActive,
  setLunaTriggerRefresh,
}) => {
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [geoLocation, setGeoLocation] = useState();
  const [feedbackReasonOptions, setFeedbackReasonOptions] = useState([]);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState("");
  const [selectedFeedbackReasons, setSelectedFeedbackReasons] = useState([]);
  const [isLoadingDialogVisible, setIsLoadingDialogVisible] = useState(false);
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(true);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isReasonError, setIsReasonError] = useState(false);
  const [isCommentError, setIsCommentError] = useState(false);
  const [lunaSearchData, setLunaSearchData] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const executeGeo = async () => {
      if (loadGeo()) {
        const position = await getGeoLocation();
        if (position) {
          setGeoLocation(position);
        }
      }
    };
    executeGeo();
  }, []);

  useEffect(() => {
    const fetchFeedbackOptions = async () => {
      try {
        setIsFeedbackLoading(true);
        const { data } = await getReasonFeedback();
        setFeedbackReasonOptions(
          data.reasons.map(({ id, name }) => ({ id, label: name }))
        );
      } catch (error) {
        logException(error, "Failed to fetch feedback options");
      } finally {
        setIsFeedbackLoading(false);
      }
    };

    fetchFeedbackOptions();
  }, []);

  const createFeedbackData = (score, requiredComment = false) => {
    const formattedReasons = selectedFeedbackReasons.map((id) => {
      const reason = feedbackReasonOptions.find((option) => option.id === id);
      return `${id},${reason.label}`;
    });

    return {
      feedback_date: new Date().toISOString(),
      feedback_score: score,
      feedback_comment: requiredComment
        ? feedbackComment.trim()
        : feedbackComment,
      feedback_reasons: formattedReasons,
      run_id: lunaSearchData.runId,
      user_id: lunaSearchData.userId,
      user_email: lunaSearchData.userEmail,
      model: model,
      prompt: lunaSearchData.prompt,
    };
  };

  const handleThumbUpClick = async () => {
    const feedbackData = createFeedbackData(1);
    setIsLoadingDialogVisible(true);

    try {
      await storeFeedback(feedbackData);

      setIsLoadingDialogVisible(false);
      handleCloseModal();
      setIsSuccessDialogOpen(true);
    } catch (error) {
      logException(error, "Failed to submit feedback");
    }
  };

  const handleFeedbackClick = (type) => {
    setSelectedFeedbackType(type);
    setIsFeedbackModalOpen(true);
  };

  const handleSearch = (ev, query) => {
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          setSearchTriggered(true);
          setLunaSearchActive(true);
          const lunaSearchResult = await onHandleLunaSearch(
            user.email,
            user.id,
            query,
            geoLocation?.coords.latitude,
            geoLocation?.coords.longitude,
            500
          );
          onHandleSearchResponse(lunaSearchResult);
          setLunaSearchData({
            userEmail: user.email,
            userId: user.id,
            prompt: query,
            runId: lunaSearchResult?.data?.data?.run_id || null,
          });
          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
  };

  const handleSearchClear = () => {
    setLunaSearchActive(false);
    setLunaTriggerRefresh(true);
    setLoadingSearch(true);
    setSearchTriggered(false);
    setLoadingSearch(false);
  };

  const handleCloseModal = () => {
    setIsFeedbackModalOpen(false);
    setSelectedFeedbackType(null);
    setSelectedFeedbackReasons([]);
    setFeedbackComment("");
    setIsReasonError(false);
    setIsCommentError(false);
  };

  const handleFeedbackChange = (event, newValue) => {
    setSelectedFeedbackReasons(newValue.map((r) => r.value) || []);
    setIsReasonError(false);
  };

  const handleConfirm = async () => {
    const errors = validateFields(selectedFeedbackReasons, feedbackComment);
    if (errors.reason || errors.comment) {
      setIsReasonError(!!errors.reason);
      setIsCommentError(!!errors.comment);
      return;
    }

    setIsLoadingDialogVisible(true);
    const feedbackData = createFeedbackData(selectedFeedbackType, true);

    try {
      await storeFeedback(feedbackData);

      setIsLoadingDialogVisible(false);
      handleCloseModal();
      setIsSuccessDialogOpen(true);
    } catch (error) {
      logException(error, "Failed to submit feedback");
    }
  };

  const closeSuccessDialog = () => {
    setIsSuccessDialogOpen(false);
  };

  return (
    <Box className={classes.toolbarControls}>
      <SearchBar
        isSearchAi={isSearchAi}
        module={module}
        handleSearch={handleSearch}
        handleSearchClear={handleSearchClear}
        placeholder="Type search prompt here"
        isLoadingSearch={loadingSearch}
        searchTriggered={searchTriggered}
      />
      <Box className={classes.toolbarFilters}>
        {searchTriggered && (
          <Box className={classes.containerFeedbackButtonSearch}>
            <Button
              className={classes.buttonFeedback}
              onClick={handleThumbUpClick}
              disabled={loadingSearch}
            >
              <ThumbUp width={21} height={21} />
            </Button>
            <Button
              className={classes.buttonFeedback}
              onClick={() => handleFeedbackClick(0)}
              disabled={loadingSearch}
            >
              <ThumbDown width={21} height={21} />
            </Button>
          </Box>
        )}
      </Box>
      <Dialog
        open={isLoadingDialogVisible}
        aria-labelledby="loading-dialog-title"
        aria-describedby="loading-dialog-description"
        maxWidth="xs"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent>
          <LoadingIndicator />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isFeedbackModalOpen && !isLoadingDialogVisible}
        onClose={handleCloseModal}
        aria-labelledby="feedback-modal-title"
        maxWidth="xs"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: isFeedbackLoading ? "transparent" : "white",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle className={classes.wrapperDialog}>
          <Box className={classes.wrapperTitle}>
            <Box display="flex" alignItems="center">
              <LunaLogo />
              <Typography
                variant="caption"
                gutterBottom
                className={classes.titleDialog}
              >
                Luna Feedback
              </Typography>
            </Box>
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          {isFeedbackLoading ? (
            <LinearProgress color="secondary" />
          ) : (
            <Box className={classes.selectContainer}>
              <FormSelectAuto
                multiple
                name="tellUsMore"
                label="Tell us more"
                placeholder="Select Reasons"
                noTruncate
                options={feedbackReasonOptions.map((option) => ({
                  value: option.id,
                  label: option.label,
                }))}
                value={feedbackReasonOptions
                  .filter((option) =>
                    selectedFeedbackReasons.includes(option.id)
                  )
                  .map((option) => ({
                    value: option.id,
                    label: option.label,
                  }))}
                handleChange={handleFeedbackChange}
                error={
                  isReasonError
                    ? [
                        {
                          key: "tellUsMore",
                          message: "At least one feedback reason is required",
                        },
                      ]
                    : []
                }
              />
            </Box>
          )}
          <Box className={classes.selectContainer}>
            <FormControl fullWidth>
              <TextField
                multiline
                id="comment"
                size="small"
                variant="standard"
                name="comment"
                placeholder="Write here ..."
                minRows={5}
                onChange={(event) => {
                  setFeedbackComment(event.target.value);
                  setIsCommentError(false);
                }}
                value={feedbackComment}
                error={isCommentError}
                InputProps={{ disableUnderline: true }}
                className={classes.textArea}
              />
              {isCommentError && (
                <FormHelperText className={classes.wrapperError} error>
                  Comment is required
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actionItemButton}>
          <Button onClick={handleCloseModal} className={classes.buttonCancel}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            className={classes.buttonConfirm}
          >
            Send Feedback
          </Button>
        </DialogActions>
      </Dialog>

      <MessageDialog
        open={isSuccessDialogOpen}
        handleClose={closeSuccessDialog}
        title="Success"
        message="Feedback has been successfully submitted."
      />
    </Box>
  );
};

export default LunaSearchInput;
