import React from "react";
import { Box, Card, CardActionArea, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import WorkticketIcon from "assets/icons/workticket.png";
import InspectionIcon from "assets/icons/inspection.png";

const workticketTypes = [
  {
    id: 1,
    name: "Service",
    icon: <img src={WorkticketIcon} alt="Service" height={15} />,
  },
  {
    id: 2,
    name: "Inspection",
    icon: <img src={InspectionIcon} alt="Inspection" height={15} />,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: 350,
    backgroundColor: "#ffffff",
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
    paddingLeft: 8,
  },
  typesContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.75),
    marginTop: theme.spacing(1.5),
  },
  card: {
    backgroundColor: "#ffffff",
    borderRadius: 6,
    boxShadow: "none",
    border: `1px solid ${theme.palette.divider}`,
    "&.selected": {
      border: `1px solid #4F98BC`,
    },
  },
  cardAction: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "auto",
    height: 32,
  },
  typeIconContainer: {
    width: "auto",
    height: 20,
    borderRadius: 4,
    marginRight: theme.spacing(0.75),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
  },
  typeName: {
    fontSize: 13,
    color: theme.palette.text.primary,
    textAlign: "left",
    lineHeight: 1.2,
  },
}));

const WorkticketTypeFilter = ({ selectedTypes = [], onTypeSelect }) => {
  const classes = useStyles();

  const handleTypeSelect = (type) => {
    const isSelected = selectedTypes.some((t) => t.id === type.id);

    if (isSelected) {
      onTypeSelect(selectedTypes.filter((t) => t.id !== type.id));
    } else {
      onTypeSelect([...selectedTypes, type]);
    }
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Filter by Workticket Type
      </Typography>

      <Box className={classes.typesContainer}>
        {workticketTypes.map((type) => (
          <Card
            key={type.id}
            className={`${classes.card} ${
              selectedTypes.some((t) => t.id === type.id) ? "selected" : ""
            }`}
          >
            <CardActionArea
              className={classes.cardAction}
              onClick={() => handleTypeSelect(type)}
            >
              <Box className={classes.typeIconContainer}>{type.icon}</Box>
              <Typography className={classes.typeName}>{type.name}</Typography>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default WorkticketTypeFilter;
