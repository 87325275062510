import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import BackIcon from "@material-ui/icons/ArrowBack";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import BillingIcon from "@material-ui/icons/MonetizationOn";
import InfoIcon from "@material-ui/icons/Info";
import CheckIcon from "@material-ui/icons/CheckCircle";
import { ButtonBase } from "components/ui/core/button";
import FormInput from "components/ui/FormContent/formInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControl, FormLabel, FormGroup } from "@material-ui/core";
import ServicesTask from "./servicesTask";
import ServicesPreview from "./servicesPreview";
import FormSelectCheckboxJobsAuto from "components/ui/FormContent/formSelectCheckboxJobsAuto";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import MessageDialog from "components/ui/dialog/messageDialog";
import FileUpload from "components/common/FileUpload/fileUpload";
import FilesDisplay from "components/ui/FilesDisplay/filesDisplay";
import FilesDisplayUploaded from "components/ui/FilesDisplayUploaded/filesDisplayUploaded";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import {
  useServiceState,
  useServiceDispatch,
} from "contexts/projectServiceContext";
import { useProjectView } from "contexts/projectViewContext";
import GlobalUiContext from "contexts/globalUiContext";
import {
  createPreventativeServices,
  updatePreventativeServiceItem,
  getPreventativeServiceItem,
  getPreventativeServices,
} from "services/projectService";
import {
  getCategories,
  getCategoriesCustomers,
} from "services/categoryService";
import {
  getSubcontractors,
  getSubcontractorMembers,
} from "services/subcontractorService";
import { formatStringToNumber } from "components/util/stringFormat";

import { switchOptions } from "constants.js";
import {
  formHeaderTitles,
  visualInspectionTitles,
  billingOptions,
  performService,
  paymentInHouse,
  paymentSubcontractor,
  frequencyOptions,
  intervalOptions,
  optionsDays,
  optionsDaysMonth,
  initialStepValidation,
  statusLoadingTitles,
  serviceGLTypeOptions,
  billingOptionsMonthlyService,
  billingTimelineOptions,
  optionsDaysAbbreviation,
  optionsInspectionType,
} from "./serviceOptions.js";
import { logException } from "components/util/logUtil";
import Joi from "joi-browser";
import useStyles from "./styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import ClockIcon from "@material-ui/icons/WatchLater";
import { ArrowDown } from "../../partnerPage/icons/ArrowDown";
import { AccessTimeIcon } from "./Icons/AccessTimeIcon";
import { ArrowDownService } from "./Icons/ArrowDown";
import Popover from "@material-ui/core/Popover";
import { TimeComponent } from "./TimeComponent";
import { changeBillingItem } from "../../../services/quoteService";
import { da } from "date-fns/locale";

const schema = Joi.object({
  name: Joi.string().max(255),
  summary: Joi.string().max(255),
});

const validateFields = ["name", "summary"];
const VISUAL_INSPECTION_PROJECT_ID = 7;

const initialData = {
  name: "",
  service_category: "",
  service_category_id: "",
  service_gl_type: "",
  service_gl_type_id: "",
  is_billable: null,
  units_per_instance: 0,
  unit_billing: 0,
  billing_price: 0,
  billing_option: "",
  billing_option_type: "",
  summary: "",
  description: "",
  tasks: null,
  perform_service: "",
  perform_service_type: "",
  estimation_time: "",
  payment_in_house: "",
  payment_in_house_type: "",
  team_member: "",
  team_member_id: "",
  subcontractor: "",
  subcontractor_id: "",
  sub_member: "",
  sub_member_id: "",
  payment_subcontractor: "",
  payment_subcontractor_type: "",
  unit_cost_in_house: 0,
  cost_in_house: 0,
  unit_cost_subcontractor: 0,
  cost_subcontractor: 0,
  cost_flat_subcontractor: 0,
  cost_monthly_subcontractor: 0,
  frequency: "",
  start_date: null,
  frequency_type: "",
  interval_type: "",
  interval_type_id: "",
  interval_size: "",
  interval_days: "",
  interval_week_days: [],
  interval_month_days: [],
  interval_end_date: null,
  interval_skip_weekdays: false,
  interval_no_end_date: false,
  effective_date: "",
  effective_limit: null,
  service_files: [],
  remove_files_id: [],
  //new fields
  billing_option_service_month: "",
  billing_option_service_month_type: "",
  way_of_billing_per_month: "",
  way_of_billing_per_month_input_cost: "",
  billing_monthly_day: null,
  billing_monthly_day_input_cost: null,
  end_date_billing_per_month: null,
  service_available_days: [],
  availability_time_frame: "",
  availability_time_frame_same_day: false,
  inspection_types: [],
};

export const getFormHeaderTitles = (step, projectType) => {
  if (projectType === VISUAL_INSPECTION_PROJECT_ID) {
    return visualInspectionTitles;
  }
  return formHeaderTitles[step];
};

const ServicesManagerDrawer = (props) => {
  const classes = useStyles();
  const [isLoadingJob, setIsLoadingJob] = useState(true);
  const [stepValidation, setStepValidation] = useState(initialStepValidation);
  const [data, setData] = useState(initialData);
  const [dataUpdateService, setDataUpdateService] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [jobsList, setJobsList] = useState([]);
  const [serviceCategories, setServiceCategory] = useState([]);
  const [step, setStep] = useState(1);
  const [teamMembersList, setTeamMembersList] = useState([]);
  const [subcontractorList, setSubcontractorsList] = useState([]);
  const [subMemberList, setSubMemberList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [isLoadingSubCrew, setIsLoadingSubCrew] = useState(false);
  const [statusLoading, setStatusLoading] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [files, setFiles] = useState([]);
  const [updateFiles, setUpdateFiles] = useState(0);
  const [dirtyRecurrence, setDirtyRecurrence] = useState(false);
  const [totalSteps, setTotalSteps] = useState(5);
  const [inputError, setInputError] = useState([]);
  const [inspectionSelected, setInspectionSelected] = useState([]);
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const { serviceManageOpen, serviceManageMode, serviceRowId } =
    useServiceState();
  const dispatchServices = useServiceDispatch();
  const [stateContextProject] = useProjectView();
  const { project } = stateContextProject ?? null;

  // Load service for update - INI
  useEffect(() => {
    const loadService = async () => {
      try {
        setIsLoading(true);
        setStatusLoading(3);
        const responseService = await getPreventativeServiceItem(serviceRowId);
        const responseServiceData = responseService.data.data;
        const {
          service: serviceData,
          user: userData,
          crew_user: crewData,
        } = responseServiceData;

        const activeJobs = serviceData.jobs.map((job) => job.id);
        const selectedJobs = jobs.filter((job) => activeJobs.includes(job.id));
        setJobsList(selectedJobs);

        if (jobs.length - 1 === selectedJobs.length) {
          const jobsUpdate = jobs.filter((job) => job.id !== -1);
          setJobs(jobsUpdate);
        }

        const selectedCategory = serviceCategories.find(
          (category) => category.value === serviceData.service_category_id
        );

        const selectedGlType = serviceGLTypeOptions.find(
          (type) => type.value === serviceData.service_gl_type
        );

        if (serviceData?.preventativeserviceinspections?.length > 0) {
          const selectedInspectionTypes =
            serviceData.preventativeserviceinspections.map((inspection) => {
              return optionsInspectionType.find(
                (type) => type.value === Number(inspection.inspection_type)
              );
            });

          const validSelectedInspectionTypes =
            selectedInspectionTypes.filter(Boolean);

          setInspectionSelected(validSelectedInspectionTypes);
        }

        const selectedBillingOptions = billingOptions.find(
          (option) => option.value === serviceData.billing_type
        );

        const selectedPerformService = performService.find(
          (option) => option.value === serviceData.work_type
        );

        const selectedFrequency = frequencyOptions.find(
          (option) => option.value === (serviceData.is_recurring ? 2 : 1)
        );

        let recurrenceProps = {};

        if (serviceData.is_recurring) {
          let intervalWeekDays = [];
          let intervalMonthDays = [];
          const selectedInterval = intervalOptions.find(
            (option) => option.id === serviceData.recurrence.interval_type
          );

          if (serviceData.recurrence.interval_type === "weeks") {
            intervalWeekDays = serviceData.recurrence.interval_days.map((day) =>
              Number(day)
            );
          }

          if (serviceData.recurrence.interval_type === "months") {
            intervalMonthDays = optionsDaysMonth.filter((option) =>
              serviceData.recurrence.interval_days.includes(`${option.value}`)
            );
          }

          recurrenceProps = {
            interval_type: selectedInterval ?? "",
            interval_type_id: selectedInterval?.value ?? "",
            interval_size: serviceData.recurrence.interval_size,
            interval_week_days: intervalWeekDays,
            interval_month_days: intervalMonthDays,
            interval_end_date: serviceData.recurrence.stop_date
              ? moment(serviceData.recurrence.stop_date)
              : null,
            interval_skip_weekdays: serviceData.recurrence.skip_weekends,
            interval_no_end_date:
              serviceData.recurrence.stop_date === null ? true : false,
            effective_date: "",
            effective_limit: serviceData?.effective_limit ?? null,
          };
        }
        if (serviceData.billing_type === 5) {
          handleBillable(false);
        }
        let billing_option_service_month = null;
        let way_of_billing_per_month = null;
        let billing_monthly_day = null;
        let billing_option_service_month_type = null;

        //payment

        let way_of_billing_per_month_input_cost = null;
        let billing_monthly_day_input_cost = null;
        let cost_monthly_subcontractor = null;
        if (serviceData.billing_type === 6) {
          billing_option_service_month = billingOptionsMonthlyService[1];
          billing_option_service_month_type = 1;
          way_of_billing_per_month = billingTimelineOptions.find(
            (option) => option.value.toString() === serviceData.billing_timeline
          );
          if (serviceData.billing_timeline === "3") {
            billing_monthly_day = optionsDaysMonth.find(
              (option) =>
                option.value.toString() === serviceData.billing_timeline_day
            );
          }
          setTimelineOption(way_of_billing_per_month);
        } else {
          billing_option_service_month = billingOptionsMonthlyService[0];
          billing_option_service_month_type = 0;
        }
        if (serviceData.work_type === 2) {
          //set state subcontractor
          // timelineOptionInputCost;
          setState({
            inhouse: false,
            subcontractor: true,
          });
          way_of_billing_per_month_input_cost = billingTimelineOptions.find(
            (option) => option.value.toString() === serviceData.payment_timeline
          );
          setTimelineOptionInputCost(way_of_billing_per_month_input_cost);
          if (
            serviceData.payment_timeline === "3" ||
            serviceData.payment_timeline === "1" ||
            serviceData.payment_timeline === "2"
          ) {
            billing_monthly_day_input_cost = optionsDaysMonth.find(
              (option) =>
                option.value.toString() === serviceData.payment_timeline_day
            );
            cost_monthly_subcontractor = serviceData.payment_per_instance;
          }
        } else if (serviceData.work_type === 1) {
          setState({
            inhouse: true,
            subcontractor: false,
          });
        }
        const updateData = {
          name: serviceData.name,
          service_category: selectedCategory,
          service_category_id: selectedCategory?.value ?? "",
          service_gl_type: selectedGlType,
          service_gl_type_id: selectedGlType?.value ?? "",
          units_per_instance: serviceData.units_per_instance,
          is_billable: serviceData.billing_type === 5 ? false : true,
          billing_price: serviceData.bill_per_instance,
          unit_billing: serviceData.unit_price,
          billing_option: selectedBillingOptions ?? "",
          billing_monthly_day: billing_monthly_day,
          billing_option_service_month_type: billing_option_service_month_type,
          billing_option_type: selectedBillingOptions?.value ?? "",
          billing_monthly_day_input_cost: billing_monthly_day_input_cost,
          summary: serviceData.summary,
          cost_monthly_subcontractor: cost_monthly_subcontractor,
          description: serviceData.description ?? "",
          way_of_billing_per_month: way_of_billing_per_month ?? "",
          perform_service: selectedPerformService ?? "",
          billing_option_service_month: billing_option_service_month,
          perform_service_type: selectedPerformService?.value ?? "",
          frequency: selectedFrequency ?? "",
          frequency_type: selectedFrequency?.value ?? "",
          way_of_billing_per_month_input_cost:
            way_of_billing_per_month_input_cost,
          start_date: moment(serviceData.start_date),
          tasks: serviceData.tasks.map((task) => {
            return { id: task.id, name: task.name };
          }),
          service_files: serviceData.files,
          ...recurrenceProps,
          inspection_types: inspectionSelected,
        };

        setData({ ...initialData, ...updateData });
        setDataUpdateService({
          ...serviceData,
          crew_user: crewData,
          user: userData,
        });
        setIsLoading(false);
      } catch (e) {
        logException(e, "Cannot load service");
      }
    };

    if (serviceRowId && serviceManageMode === "EDIT" && !isLoadingJob) {
      loadService();
    } else if (serviceManageMode === "NEW") {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceManageMode, isLoadingJob, serviceRowId]);
  // Load service for update - END

  // Validation steps - INI
  useEffect(() => {
    const updateStepValidation = {
      ...initialStepValidation,
    };

    // Fields step 1
    const {
      service_category_id,
      service_gl_type_id,
      inspection_types,
      name,
      is_billable,
      billing_price,
      billing_option_type,
      billing_option_service_month_type,
      way_of_billing_per_month,
      billing_monthly_day,
    } = data;

    // Fields step 2
    const { summary, description } = data;

    // Fields step 3
    const {
      perform_service_type,
      payment_in_house_type,
      units_per_instance,
      unit_cost_in_house,
      team_member_id,
      subcontractor_id,
      payment_subcontractor,
      cost_subcontractor,
      way_of_billing_per_month_input_cost,
      billing_monthly_day_input_cost,
      cost_flat_subcontractor,
      unit_cost_subcontractor,
      cost_monthly_subcontractor,
    } = data;

    // Fields step 4
    const {
      frequency_type,
      start_date,
      interval_type_id,
      interval_size,
      interval_end_date,
      interval_no_end_date,
      interval_week_days,
      interval_month_days,
      effective_date,
    } = data;

    if (
      jobsList.length &&
      (service_category_id ||
        [VISUAL_INSPECTION_PROJECT_ID].includes(project.type)) && // Does not validate when it is a form of the visual inspection project type
      (service_gl_type_id || ![3].includes(project.type)) &&
      name &&
      name.length <= 255 &&
      is_billable !== null &&
      (project.type !== VISUAL_INSPECTION_PROJECT_ID ||
        inspection_types.length > 0 ||
        inspectionSelected.length > 0) // Only validates inspection_types when it is VISUAL_INSPECTION_PROJECT_ID
    ) {
      if (billing_option_service_month_type === 0) {
        if (
          is_billable &&
          billing_price > 0 &&
          (billing_option_type === 0 || billing_option_type === 3)
        ) {
          updateStepValidation.step1 = true;
        }
      } else if (billing_option_service_month_type === 1) {
        if (
          (is_billable &&
            billing_price > 0 &&
            way_of_billing_per_month?.value === 1) ||
          way_of_billing_per_month?.value === 2
        ) {
          updateStepValidation.step1 = true;
        }
        if (
          is_billable &&
          billing_price > 0 &&
          way_of_billing_per_month?.value === 3
        ) {
          if (billing_monthly_day !== null) {
            updateStepValidation.step1 = true;
          }
        }
      } else if (!is_billable) {
        updateStepValidation.step1 = true;
      }
    }
    if (
      jobsList.length &&
      service_category_id &&
      (service_gl_type_id || ![3].includes(project.type)) &&
      name &&
      name.length <= 255
    ) {
      if (!is_billable) {
        updateStepValidation.step1 = true;
      }
    }
    if (summary && summary.length <= 255 && description) {
      updateStepValidation.step2 = true;
    }

    if (perform_service_type) {
      // 1 = In House, 2 = Subcontractor
      if (perform_service_type === 1) {
        if (
          payment_in_house_type === 1 &&
          team_member_id &&
          units_per_instance >= 0 &&
          unit_cost_in_house >= 0
        ) {
          updateStepValidation.step3 = true;
        }

        if (payment_in_house_type === 5 && team_member_id) {
          updateStepValidation.step3 = true;
        }
      } else if (perform_service_type === 2) {
        //value 6 is per month
        //value  0 is flat fee
        //value  3 hourly rate
        //value 5 is no payment
        if (subcontractor_id && payment_subcontractor?.value === 6) {
          if (cost_monthly_subcontractor > 0) {
            if (
              way_of_billing_per_month_input_cost?.value === 1 ||
              way_of_billing_per_month_input_cost?.value === 2
            ) {
              updateStepValidation.step3 = true;
            } else if (
              way_of_billing_per_month_input_cost?.value === 3 &&
              billing_monthly_day_input_cost !== null &&
              cost_monthly_subcontractor > 0
            ) {
              updateStepValidation.step3 = true;
            }
          }
        } else if (subcontractor_id && payment_subcontractor?.value === 0) {
          if (cost_flat_subcontractor > 0) {
            updateStepValidation.step3 = true;
          }
          // if (subcontractor_id || project?.is_external) {
          //   updateStepValidation.step3 = true;
          // }
        } else if (subcontractor_id && payment_subcontractor?.value === 3) {
          if (units_per_instance > 0 && unit_cost_subcontractor > 0) {
            updateStepValidation.step3 = true;
          }
        } else if (payment_subcontractor?.value === 5) {
          updateStepValidation.step3 = true;
        }
      }
    }

    if (frequency_type) {
      if (frequency_type === 1 && start_date) {
        updateStepValidation.step4 = true;
      }

      if (
        frequency_type === 2 &&
        interval_type_id &&
        interval_size &&
        interval_size > 0 &&
        start_date &&
        (interval_end_date || interval_no_end_date)
      ) {
        updateStepValidation.step4 = true;
        if (
          (interval_type_id === 2 && !interval_week_days.length) ||
          (interval_type_id === 3 && !interval_month_days.length)
        ) {
          updateStepValidation.step4 = false;
        }

        if (
          !effective_date &&
          serviceManageMode === "EDIT" &&
          dirtyRecurrence
        ) {
          updateStepValidation.step4 = false;
        }
      }
    }

    setStepValidation(updateStepValidation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobsList, data]);
  // Validation steps - END

  // Loads jobs and service category - INI
  useEffect(() => {
    const loadJobs = async () => {
      setIsLoadingJob(true);
      const resultService = await getPreventativeServices(project.id);
      const activeJobs = resultService.data.data.jobs.map((job) => {
        return {
          id: job.id,
          job_description: job.job_description,
          job_number: job.job_number,
        };
      });

      activeJobs.unshift({
        id: -1,
        job_description: "ALL",
        job_number: "",
      });

      setJobs(activeJobs);

      let resultServiceCategories = null;

      if (project?.is_external) {
        resultServiceCategories = await getCategoriesCustomers(
          project.customer.id
        );
        setTotalSteps(4);
      } else {
        resultServiceCategories = await getCategories();
      }

      const resultForRender = resultServiceCategories.data.data.categories.map(
        (item) => {
          return {
            value: item.id,
            label: item.name,
          };
        }
      );
      setServiceCategory(resultForRender);
      setIsLoadingJob(false);
    };
    if (serviceManageOpen && !jobs.length) {
      loadJobs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceManageOpen]);
  // Loads jobs and service category - END

  useEffect(() => {
    const loadTeamMembers = () => {
      setIsLoadingUser(true);
      const userTeamMembers = users.filter(
        (user) =>
          user.tier_classification !== "subcontractor" &&
          user.employee_number > 0
      );
      const resultForRender = userTeamMembers.map((item) => {
        return {
          value: item.id,
          label: `${item.first_name} ${item.last_name}`,
        };
      });
      setTeamMembersList(resultForRender);

      if (serviceManageMode === "EDIT") {
        const selectedTeamMember = resultForRender.find(
          (option) => option.value === dataUpdateService?.user?.id
        );

        const payment = paymentInHouse.find(
          (option) => option.value === dataUpdateService.payment_type
        );

        let extraProps = {};
        if (dataUpdateService.payment_type === 1) {
          extraProps = {
            unit_cost_in_house: dataUpdateService.unit_cost,
          };
        }

        setData({
          ...data,
          team_member: selectedTeamMember,
          team_member_id: selectedTeamMember?.value ?? "",
          payment_in_house: payment,
          payment_in_house_type: payment?.value ?? "",
          ...extraProps,
        });
      }
      setIsLoadingUser(false);
    };

    const loadSubcontractors = async () => {
      setIsLoadingUser(true);
      const resultSub = await getSubcontractors();
      const resultForRender = resultSub.data.data.subs.map((item) => {
        return {
          value: Number(item.id),
          label: `${item.first_name} ${item.last_name}`,
        };
      });
      setSubcontractorsList(resultForRender);

      if (serviceManageMode === "EDIT") {
        const selectedSubcontractor = resultForRender.find(
          (option) => option.value === dataUpdateService?.user?.id
        );

        const payment = paymentSubcontractor.find(
          (option) => option.value === dataUpdateService.payment_type
        );

        let extraProps = {};
        if (dataUpdateService.payment_type === 0) {
          extraProps = {
            cost_flat_subcontractor: dataUpdateService.payment_per_instance,
          };
        } else if (
          dataUpdateService.payment_type === 2 ||
          dataUpdateService.payment_type === 3
        ) {
          extraProps = {
            unit_cost_subcontractor: dataUpdateService.unit_cost,
            cost_subcontractor: (
              dataUpdateService.units_per_instance * dataUpdateService.unit_cost
            ).toFixed(2),
          };
        }

        setData({
          ...data,
          subcontractor: selectedSubcontractor,
          subcontractor_id: selectedSubcontractor?.value ?? "",
          sub_member: "",
          sub_member_id: "",
          payment_subcontractor: payment,
          payment_subcontractor_type: payment?.value ?? "",
          ...extraProps,
        });
      }
      setIsLoadingUser(false);
    };

    if (
      serviceManageMode === "NEW" ||
      (serviceManageMode === "EDIT" && dataUpdateService)
    ) {
      if (data.perform_service_type === 1 && !teamMembersList.length) {
        // Team Members load
        loadTeamMembers();
      }

      if (data.perform_service_type === 2 && !subcontractorList.length) {
        // Subcontractor Load
        loadSubcontractors();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.perform_service_type, dataUpdateService]);

  useEffect(() => {
    const loadSubcontractorMember = async () => {
      setIsLoadingSubCrew(true);
      const resultSubMembers = await getSubcontractorMembers(
        data.subcontractor_id
      );
      const resultForRender = resultSubMembers.data.data.team.map((item) => {
        return {
          value: Number(item.id),
          label: `${item.first_name} ${item.last_name}`,
        };
      });
      setSubMemberList(resultForRender);
      if (serviceManageMode === "EDIT") {
        const selectedSubMember = resultForRender.find(
          (option) => option.value === dataUpdateService.crew_user?.id
        );
        setData({
          ...data,
          sub_member: selectedSubMember,
          sub_member_id: selectedSubMember?.value ?? "",
        });
      }
      setIsLoadingSubCrew(false);
    };
    if (data.subcontractor_id) {
      loadSubcontractorMember();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.subcontractor_id]);

  // Create submit - INI
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setStatusLoading(1);
      let dataCreate = {};
      let user_assign = 0;
      const jobsListIds = jobsList.map((job) => job.id);

      if (data.perform_service_type === 1 && data.team_member_id) {
        user_assign = data.team_member_id;
      } else if (data.perform_service_type === 2 && data.subcontractor_id) {
        user_assign = data.subcontractor_id;
      }

      const paymentType =
        data.perform_service_type === 1
          ? data.payment_in_house_type
          : data.payment_subcontractor_type;

      let costTotal =
        data.perform_service_type === 1
          ? data.payment_in_house_type === 1
            ? data.cost_in_house
            : 0
          : data.payment_subcontractor_type === 0
          ? data.cost_flat_subcontractor
          : data.cost_subcontractor;

      //when paying subcontractor monthly
      if (
        data.perform_service_type === 2 &&
        data.payment_subcontractor_type === 6
      ) {
        costTotal = data.cost_monthly_subcontractor;
      }
      // let unitsPerInstance = -1;
      let costUnit = costTotal;
      let billingUnit = 0;

      if (data.perform_service_type === 1 && data.payment_in_house_type === 1) {
        costUnit = data.unit_cost_in_house;
      }

      if (
        data.perform_service_type === 2 &&
        data.payment_subcontractor_type === 3
      ) {
        costUnit = data.unit_cost_subcontractor;
      }
      let billingType = data.is_billable ? data.billing_option_type : 5;
      let billing_timeline = timelineOption?.value ?? null;
      let billing_timeline_date = null;
      if (billingType === 3) {
        billingUnit = data.unit_billing;
      }
      if (data.billing_option_service_month_type === 1) {
        //it will be billed per month
        billingType = 6;
        billing_timeline = timelineOption?.value;
        if (billing_timeline === 3) {
          billing_timeline_date = data.billing_monthly_day.value;
        }
      }
      console.log("billingType", billingType);
      console.log("billing_timeline", billing_timeline);
      console.log("billing_timeline_date", billing_timeline_date);
      // billing_type monthly 6
      //if true billing_timeline
      //start 1, end, 2, specific day 3
      //billing_timeline_date
      //payment fields:
      // payment_timeline;
      // payment_timeline_day;
      // payment_type;
      let payment_timeline = null;
      let payment_timeline_day = null;
      if (paymentType === 6) {
        payment_timeline = timelineOptionInputCost?.value;
        if (payment_timeline === 3) {
          payment_timeline_day = data.billing_monthly_day_input_cost.value;
        }
      }
      // console.log("paymenttype", paymentType);
      // console.log("payment_timeline", payment_timeline);
      // console.log("payment_timeline_day", payment_timeline_day);
      console.log("cost total", costTotal);
      if (data.frequency_type === 1) {
        dataCreate = {
          is_recurring: 0,
          job_ids: jobsListIds,
          user_id: user_assign,
          name: data.name,
          summary: data.summary,
          service_gl_type:
            data.service_gl_type_id !== "" ? data.service_gl_type_id : 2,
          description: data.description,
          billing_timeline: billing_timeline,
          billing_timeline_day: billing_timeline_date,
          payment_timeline: payment_timeline,
          payment_timeline_day: payment_timeline_day,
          service_category_id: data.service_category_id,
          work_type: data.perform_service_type,
          billing_type: billingType,
          payment_type: paymentType,
          unit_cost: costUnit,
          unit_price: billingUnit,
          units_per_instance: data.units_per_instance,
          bill_per_instance: data.billing_price,
          payment_per_instance: costTotal,
          tasks: data.tasks?.map((task) => task.name) ?? null,
          files: files.length ? files : null,
          wt_user_ids: data?.sub_member_id ? [data.sub_member_id] : null,
          start_date: moment(data.start_date).format("YYYY-MM-DD"),
          inspection_types: inspectionSelected.map(
            (selected) => selected.value
          ),
        };
      } else if (data.frequency_type === 2) {
        let intervalDays = [];

        if (data.interval_type.id === "weeks") {
          intervalDays = data.interval_week_days;
        }

        if (data.interval_type.id === "months") {
          intervalDays = data.interval_month_days.map((day) => day.value);
        }

        dataCreate = {
          is_recurring: 1,
          job_ids: jobsListIds,
          user_id: user_assign,
          name: data.name,
          summary: data.summary,
          service_gl_type:
            data.service_gl_type_id !== "" ? data.service_gl_type_id : 2,
          description: data.description,
          service_category_id: data.service_category_id,
          work_type: data.perform_service_type,
          billing_type: billingType,
          payment_type: paymentType,
          unit_cost: costUnit,
          unit_price: billingUnit,
          billing_timeline: billing_timeline,
          billing_timeline_day: billing_timeline_date,
          payment_timeline: payment_timeline,
          payment_timeline_day: payment_timeline_day,
          units_per_instance: data.units_per_instance,
          bill_per_instance: data.billing_price,
          payment_per_instance: costTotal,
          tasks: data.tasks?.map((task) => task.name) ?? null,
          files: files.length ? files : null,
          wt_user_ids: data?.sub_member_id ? [data.sub_member_id] : null,
          inspection_types: inspectionSelected.map(
            (selected) => selected.value
          ),
          start_date: moment(data.start_date).format("YYYY-MM-DD"),
          interval_type: data.interval_type.id,
          interval_size: data.interval_size,
          interval_days: intervalDays,
          stop_date: data.interval_no_end_date
            ? ""
            : moment(data.interval_end_date).format("YYYY-MM-DD"),
          skip_weekends:
            data.interval_type_id === 3
              ? data.interval_skip_weekdays
                ? 1
                : 0
              : 0,
        };
      } else {
        dataCreate = {
          is_recurring: 0,
          job_ids: jobsListIds,
          user_id: user_assign,
          name: data.name,
          summary: data.summary,
          payment_timeline_day: payment_timeline_day,
          payment_timeline: payment_timeline,
          billing_timeline: billing_timeline,
          billing_timeline_day: billing_timeline_date,
          service_gl_type:
            data.service_gl_type_id !== "" ? data.service_gl_type_id : 2,
          description: data.description,
          service_category_id: data.service_category_id,
          work_type: data.perform_service_type,
          billing_type: billingType,
          payment_type: paymentType,
          unit_cost: costUnit,
          unit_price: billingUnit,
          units_per_instance: data.units_per_instance,
          bill_per_instance: data.billing_price,
          payment_per_instance: costTotal,
          wt_user_ids: data?.sub_member_id ? [data.sub_member_id] : null,
          start_date: moment().format("YYYY-MM-DD"),
          inspection_types: inspectionSelected.map(
            (selected) => selected.value
          ),
        };
      }
      await createPreventativeServices(project.id, dataCreate);
      setIsLoading(false);
      setIsCompleted(true);
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: true,
      });
    } catch (e) {
      logException(e, "Cannot save service");
    }
  };
  // Create submit - END

  // Update submit - INI
  const handleUpdateSubmit = async () => {
    try {
      setIsLoading(true);
      setStatusLoading(2);
      const dataUpdate = {};

      // Compare jobs to add and remove
      const prevJobs = dataUpdateService.jobs.map((job) => job.id);
      const currentJobs = jobsList.map((job) => job.id);

      const addJobs = currentJobs.filter((jobId) => !prevJobs.includes(jobId));
      const removeJobs = prevJobs.filter(
        (jobId) => !currentJobs.includes(jobId)
      );

      // Compare tasks to add and remove
      const prevTasks = dataUpdateService.tasks.map((task) => task.id);
      const currentTasks = data.tasks.map((task) => task.id);

      const addTasks = currentTasks.filter(
        (taskId) => !prevTasks.includes(taskId)
      );
      const removeTasks = prevTasks.filter(
        (taskId) => !currentTasks.includes(taskId)
      );

      const addListTasks = data.tasks.filter((task) =>
        addTasks.includes(task.id)
      );

      // Jobs
      if (addJobs.length) {
        dataUpdate.add_job_ids = addJobs;
      }

      if (removeJobs.length) {
        dataUpdate.remove_job_ids = removeJobs;
      }

      // Tasks
      if (addTasks.length) {
        dataUpdate.add_tasks = addListTasks.map((task) => task.name);
      }

      if (removeTasks.length) {
        dataUpdate.remove_task_ids = removeTasks;
      }

      // Billing
      let billingType = data.is_billable ? data.billing_option_type : 5;
      console.log("billling update", data);
      console.log("data update service", dataUpdateService);
      if (billingType !== dataUpdateService.billing_type) {
        if (billingType === 5) {
          dataUpdate.billing_type = billingType;
          dataUpdate.bill_per_instance = 0;
        } else if (billingType === 0) {
          dataUpdate.billing_type = billingType;
          dataUpdate.bill_per_instance = data.billing_price;
        }
      } else if (
        billingType === dataUpdateService.billing_type &&
        (billingType === 0 || billingType === 3) &&
        Number(data.billing_price) !==
          Number(dataUpdateService.bill_per_instance)
      ) {
        dataUpdate.bill_per_instance = data.billing_price;
      }

      if (data.service_category_id !== dataUpdateService.service_category_id) {
        dataUpdate.service_category_id = data.service_category_id;
      }

      if (data.service_gl_type_id !== dataUpdateService.service_gl_type_id) {
        dataUpdate.service_gl_type = data.service_gl_type_id;
      }

      if (data.name !== dataUpdateService.name) {
        dataUpdate.name = data.name;
      }

      if (data.summary !== dataUpdateService.summary) {
        dataUpdate.summary = data.summary;
      }

      if (data.description !== dataUpdateService.description) {
        dataUpdate.description = data.description;
      }

      if (
        data.perform_service_type === 1 &&
        data.team_member_id !== dataUpdateService?.user?.id
      ) {
        dataUpdate.change_user_id = data.team_member_id;
      } else if (
        data.perform_service_type === 2 &&
        data.subcontractor_id !== dataUpdateService?.user?.id
      ) {
        dataUpdate.change_user_id = data.subcontractor_id;
        dataUpdate.wt_user_ids = [data.sub_member_id];
      } else if (
        data.perform_service_type === 2 &&
        data.sub_member_id !== dataUpdateService.crew_user?.id
      ) {
        dataUpdate.wt_user_ids = [data.sub_member_id];
      }

      // Sub payment - Flat
      if (
        data.payment_subcontractor_type === 0 &&
        data.cost_flat_subcontractor !== dataUpdateService.payment_per_instance
      ) {
        dataUpdate.payment_per_instance = data.cost_flat_subcontractor;
      }

      // Sub payment - Timekeeping Actual
      if (
        data.payment_subcontractor_type === 3 &&
        data.cost_subcontractor !== dataUpdateService.payment_per_instance
      ) {
        dataUpdate.unit_cost = data.unit_cost_subcontractor;
        dataUpdate.payment_per_instance = data.cost_subcontractor;
      }

      if (data.units_per_instance !== dataUpdateService.units_per_instance) {
        dataUpdate.units_per_instance = data.units_per_instance;
      }

      if (!project?.is_external) {
        // Recurrence
        const frequencyType = dataUpdateService.is_recurring ? 2 : 1;
        if (data.frequency_type !== frequencyType) {
          if (data.frequency_type === 1) {
            dataUpdate.is_recurring = 0;
            dataUpdate.stop_date = moment(data.effective_date).format(
              "YYYY-MM-DD"
            );
          } else if (data.frequency_type === 2) {
            let intervalDays = [];

            if (data.interval_type.id === "weeks") {
              intervalDays = data.interval_week_days;
            }

            if (data.interval_type.id === "months") {
              intervalDays = data.interval_month_days.map((day) => day.value);
            }

            dataUpdate.is_recurring = 1;
            dataUpdate.start_date = moment(data.effective_date).format(
              "YYYY-MM-DD"
            );
            dataUpdate.interval_type = data.interval_type.id;
            dataUpdate.interval_size = data.interval_size;
            dataUpdate.interval_days = intervalDays;
            dataUpdate.stop_date = data.interval_no_end_date
              ? ""
              : moment(data.interval_end_date).format("YYYY-MM-DD");
            dataUpdate.skip_weekends =
              data.interval_type_id === 3
                ? data.interval_skip_weekdays
                  ? 1
                  : 0
                : 0;
          }
        } else if (data.frequency_type === 1 && dirtyRecurrence) {
          dataUpdate.start_date = moment(data.start_date).format("YYYY-MM-DD");
        } else if (data.frequency_type === 2 && dirtyRecurrence) {
          let intervalDays = [];

          if (data.interval_type.id === "weeks") {
            intervalDays = data.interval_week_days;
          }

          if (data.interval_type.id === "months") {
            intervalDays = data.interval_month_days.map((day) => day.value);
          }

          // dataUpdate.start_date = moment(data.start_date).format("YYYY-MM-DD");
          dataUpdate.interval_type = data.interval_type.id;
          dataUpdate.interval_size = data.interval_size;
          dataUpdate.interval_days = intervalDays;
          dataUpdate.stop_date = data.interval_no_end_date
            ? ""
            : moment(data.interval_end_date).format("YYYY-MM-DD");
          dataUpdate.skip_weekends =
            data.interval_type_id === 3
              ? data.interval_skip_weekdays
                ? 1
                : 0
              : 0;
          dataUpdate.recurrence_change_date = moment(
            data.effective_date
          ).format("YYYY-MM-DD");
        }

        // Files
        if (files.length) {
          dataUpdate.files = files;
        }

        if (data.remove_files_id?.length) {
          dataUpdate.remove_file_ids = data.remove_files_id;
        }
      }
      let billing_timeline = timelineOption?.value;
      let billing_timeline_date = null;
      if (billingType === 3) {
        dataUpdate.unit_price = data.unit_billing;
      }
      if (data.billing_option_service_month_type === 1) {
        //it will be billed per month
        billingType = 6;
        console.log("here updating billing");
        billing_timeline = timelineOption?.value;
        if (billing_timeline === 3) {
          billing_timeline_date = data.billing_monthly_day.value;
          dataUpdate.billing_timeline_day = billing_timeline_date;
        }
        dataUpdate.bill_per_instance = data.billing_price;
        dataUpdate.billing_timeline = billing_timeline;
        dataUpdate.billing_type = 6;
      }
      if (
        data.perform_service_type === 2 &&
        data.payment_subcontractor_type === 6
      ) {
        let payment_timeline = null;
        let payment_timeline_day = null;
        if (data.payment_subcontractor_type === 6) {
          dataUpdate.payment_subcontractor_type = 6;
          dataUpdate.payment_timeline = timelineOptionInputCost?.value;
          if (dataUpdate.payment_timeline === 3) {
            dataUpdate.payment_timeline_day =
              data.billing_monthly_day_input_cost.value;
          }
        }
        dataUpdate.payment_per_instance = data.cost_monthly_subcontractor;
      }

      if (inspectionSelected.length > 0) {
        dataUpdate.inspection_types = inspectionSelected.map(
          (selected) => selected.value
        );
      }

      await updatePreventativeServiceItem(serviceRowId, dataUpdate);

      setIsLoading(false);
      setIsCompleted(true);

      dispatchServices({
        type: "SET_SERVICE_ROW_ID",
        serviceRowId: null,
      });
      dispatchServices({
        type: "SET_SERVICE_MANAGE_MODE",
        serviceManageMode: null,
      });
      dispatchGlobalUi({
        type: "SET_REFRESH_DATA",
        refreshData: true,
      });
    } catch (e) {
      logException(e, "Cannot update service");
    }
  };

  // Update submit - END

  useEffect(() => {
    const updateFields = {};

    if (data.payment_in_house_type === 1) {
      updateFields.cost_in_house = (
        data.units_per_instance * data.unit_cost_in_house
      ).toFixed(2);
    }

    if (data.payment_subcontractor_type === 3) {
      updateFields.cost_subcontractor = (
        data.units_per_instance * data.unit_cost_subcontractor
      ).toFixed(2);
    }

    if (data.billing_option_type === 3) {
      updateFields.billing_price = (
        data.units_per_instance * data.unit_billing
      ).toFixed(2);
    }

    const newData = {
      ...data,
      ...updateFields,
    };
    setData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data.units_per_instance,
    data.unit_cost_in_house,
    data.unit_cost_subcontractor,
    data.unit_billing,
  ]);

  const handleBlur = (event, value) => {
    const newData = {
      ...data,
      [event.name]: event.value,
    };

    if (validateFields.includes(event.name)) {
      const validateField = {
        [event.name]: event.value,
      };

      const { error } = schema.validate(validateField);
      if (error) {
        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message;
        if (Boolean(errors)) {
          const keyNames = Object.keys(errors);
          const errorArray = [];
          for (let key of keyNames) {
            errorArray.push({ key, message: errors[key] });
          }
          setInputError(errorArray);
        } else {
          setInputError([]);
        }
      } else {
        setInputError([]);
      }
    }
    setData(newData);
  };

  const handleTimeBlur = (event, value) => {
    const newData = {
      ...data,
      units_per_instance: event.value,
    };
    setData(newData);
  };

  const changeJobList = (event, value) => {
    const findAll = value.find((val) => val.id === -1);
    if (findAll) {
      const jobsUpdate = jobs.filter((job) => job.id !== -1);
      setJobs(jobsUpdate);
      setJobsList(jobsUpdate);
    } else {
      if (value.length !== jobs.length) {
        const findAllJobs = jobs.find((val) => val.id === -1);
        if (!findAllJobs) {
          jobs.unshift({
            id: -1,
            job_description: "ALL",
            job_number: "",
          });
          setJobs(jobs);
        }
      }
      setJobsList(value ?? []);
    }
  };

  const handleChangeDaysMonth = (event, value) => {
    setDirtyRecurrence(true);
    const newData = {
      ...data,
      interval_month_days: value ?? [],
    };
    setData(newData);
  };

  const handleBillable = (value) => {
    const newData = {
      ...data,
      is_billable: value,
      billing_option_service_month: "",
      billing_option_service_month_type: "",
    };
    setData(newData);
  };

  const handleChangeService = (event, selected) => {
    const newData = {
      ...data,
      service_category: selected,
      service_category_id: selected?.value ?? "",
    };
    setData(newData);
  };

  const handleChangeGlType = (event, selected) => {
    const newData = {
      ...data,
      service_gl_type: selected,
      service_gl_type_id: selected?.value ?? "",
    };
    setData(newData);
  };
  const [state, setState] = React.useState({
    inhouse: false,
    subcontractor: false,
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setState({
      inhouse: name === "inhouse" ? checked : false,
      subcontractor: name === "subcontractor" ? checked : false,
    });
    const selectedForNewData = {
      value: name === "inhouse" ? 1 : 2,
      label: name === "inhouse" ? "In-House" : "Sub-Contractor",
    };
    const newData = {
      ...data,
      perform_service: selectedForNewData,
      perform_service_type: selectedForNewData?.value ?? "",
    };
    setData(newData);
  };
  const { inhouse, subcontractor } = state;
  const handleInspectionsChange = (event, value) => {
    setInspectionSelected(value ?? []);

    setData((prevData) => ({
      ...prevData,
      inspection_types: value || [],
    }));
  };

  // const handleChangePerformService = (event) => {
  //   const { name } = event.target;
  //   const selectedService = performService.find(
  //     (service) => service.label === name
  //   );

  //   const newData = {
  //     ...data,
  //     perform_service: selectedService || {},
  //     perform_service_type: selectedService ? selectedService.value : "",
  //   };

  //   setData(newData);
  // };

  const handleChangeBillingOption = (event, selected) => {
    const newData = {
      ...data,
      billing_option: selected,
      billing_option_type: selected?.value ?? "",
      billing_price: (data.units_per_instance * data.unit_billing).toFixed(2),
    };
    setData(newData);
  };
  const handleChangeBillingOptionType = (event, selected) => {
    console.log(selected, "selected");
    const newData = {
      ...data,
      billing_option_service_month: selected,
      billing_option_service_month_type: selected?.value ?? "",
      // billing_price: (data.units_per_instance * data.unit_billing).toFixed(2),
    };
    setData(newData);
  };

  const [timelineOption, setTimelineOption] = useState();
  const [timelineOptionInputCost, setTimelineOptionInputCost] = useState();

  const handleChangeTimelines = (setter, field) => (event, selected) => {
    const newData = {
      ...data,
      [field]: selected,
    };
    setData(newData);
    setter(selected);
  };

  const [willBeBilledNextMonth, setWillBeBilledNextMonth] = useState(false);
  const [willBeBilledNextMonthInputCost, setWillBeBilledNextMonthInputCost] =
    useState(false);
  // const handleChangeBillingDayMonthly = (event, selected) => {
  //   if (selected.value <= moment().date()) {
  //     setWillBeBilledNextMonth(true);
  //   } else {
  //     setWillBeBilledNextMonth(false);
  //   }
  //   const newData = {
  //     ...data,
  //     billing_monthly_day: selected,
  //   };
  //   setData(newData);
  // };
  // const handleChangeBillingDayMonthlyInputCost = (event, selected) => {
  //   if (selected.value <= moment().date()) {
  //     setWillBeBilledNextMonthInputCost(true);
  //   } else {
  //     setWillBeBilledNextMonthInputCost(false);
  //   }
  //   const newData = {
  //     ...data,
  //     billing_monthly_day_input_cost: selected,
  //   };
  //   setData(newData);
  // };
  const [dateNextMonth, setDateNextMonth] = useState(null);
  const handleChangeBillingDayMonthly =
    (setter, field) => (event, selected) => {
      setDateNextMonth(null);
      // Update the 'will be billed next month' state based on the selected date
      setter(true);
      //set whateter date it is next month based on the current date
      let date = moment().date(selected.value).add(1, "month");
      if (!date.isValid()) {
        date = date.endOf("month");
      } else if (date.month() === 1 && selected.value > 28) {
        date = date.endOf("month");
      }
      setDateNextMonth(date);
      const newData = {
        ...data,
        [field]: selected,
      };
      setData(newData);
    };
  const handleChangeBillingMonthlyEndDate = (event) => {
    const newData = {
      ...data,
      end_date_billing_per_month: event,
    };
    setData(newData);
  };
  const handleChangePaymentInHouse = (event, selected) => {
    const newData = {
      ...data,
      payment_in_house: selected,
      payment_in_house_type: selected?.value ?? "",
    };

    if (newData.payment_in_house_type === 1) {
      newData.cost_in_house = data.units_per_instance * data.unit_cost_in_house;
    }
    setData(newData);
  };

  const handleChangeTeamMember = (event, selected) => {
    const userSelected = users.find(
      (user) => user.id === selected?.value ?? ""
    );

    const newData = {
      ...data,
      team_member: selected,
      team_member_id: selected?.value ?? "",
      unit_cost_in_house: userSelected?.hour_rate ?? "",
    };
    setData(newData);
  };

  const handleChangeSubcontractor = (event, selected) => {
    const newData = {
      ...data,
      subcontractor: selected,
      subcontractor_id: selected?.value ?? "",
    };
    setData(newData);
  };

  const handleChangeSubMember = (event, selected) => {
    const newData = {
      ...data,
      sub_member: selected,
      sub_member_id: selected?.value ?? "",
    };
    setData(newData);
  };

  const handleChangePaymentSubcontractor = (event, selected) => {
    console.log("selected", selected);
    //value 6 is per month
    //value  0 is flat fee
    //value  3 hourly rate
    //value 5 no payment
    const newData = {
      ...data,
      payment_subcontractor: selected,
      payment_subcontractor_type: selected?.value ?? "",
    };
    setData(newData);
  };

  const handleChangeFrequencyService = (event, selected) => {
    const newData = {
      ...data,
      frequency: selected,
      frequency_type: selected?.value ?? "",
    };
    setData(newData);
    setDirtyRecurrence(false);
  };

  const handleChangeInterval = (event, selected) => {
    setDirtyRecurrence(true);
    const newData = {
      ...data,
      interval_type: selected,
      interval_type_id: selected?.value ?? "",
    };
    setData(newData);
  };

  const handleChangeSize = (event, value) => {
    setDirtyRecurrence(true);
    const newData = {
      ...data,
      [event.name]: event.value,
    };
    setData(newData);
  };

  const handleChangeDays = (e, option) => {
    setDirtyRecurrence(true);
    const days = data.interval_week_days;
    if (e.target.checked) {
      days.push(option);
    } else {
      days.splice(days.indexOf(option), 1);
    }
    const newData = {
      ...data,
      interval_week_days: days,
    };
    setData(newData);
  };
  const [labelServiceAvailableDays, setLabelServiceAvailableDays] =
    useState("");
  const handleChangeDaysPopover = (e, option) => {
    // setDirtyRecurrence(true);
    const days = data.service_available_days;
    if (e.target.checked) {
      days.push(option.value);
    } else {
      days.splice(days.indexOf(option.value), 1);
    }
    const newData = {
      ...data,
      service_available_days: days,
    };
    const labels = optionsDaysAbbreviation.filter((item) =>
      days.includes(item.value)
    );
    // make abbreviations like this Mon, Tue
    const label = labels.map((item) => item.abbreviation).join(", ");
    setLabelServiceAvailableDays(label);
    setData(newData);
  };
  const handleChangeDueDate = async (event) => {
    setDirtyRecurrence(true);
    const newData = {
      ...data,
      start_date: event,
    };
    setData(newData);
  };

  const handleChangeEffectiveDate = async (event) => {
    const newData = {
      ...data,
      effective_date: event,
    };
    setData(newData);
  };

  const handleChangeEndDate = async (event) => {
    setDirtyRecurrence(true);
    const newData = {
      ...data,
      interval_end_date: event,
    };
    setData(newData);
  };

  const handleChangeSkipWeekends = (event) => {
    setDirtyRecurrence(true);
    const newData = {
      ...data,
      interval_skip_weekdays: event.target.checked ? true : false,
    };
    setData(newData);
  };

  const handleChangeNoEndDate = (event) => {
    setDirtyRecurrence(true);
    const newData = {
      ...data,
      interval_no_end_date: event.target.checked ? true : false,
    };
    setData(newData);
  };
  const handleChangeSameDayAvailability = (event) => {
    const newData = {
      ...data,
      availability_time_frame_same_day: event.target.checked ? true : false,
      availability_time_frame: event.target.checked ? 1 : 0,
    };
    setData(newData);
  };
  const handleChangeTask = (task) => {
    const newData = {
      ...data,
      tasks: task,
    };
    setData(newData);
  };

  const validateStepRecurrence = () => {
    if (
      data.billing_option_service_month?.value === 1 ||
      data.payment_subcontractor_type === 6
    ) {
      handleChangeFrequencyService(null, frequencyOptions[1]);
    }
    setStep(4);
  };

  const closeMessageDialog = () => {
    setIsCompleted(false);
    dispatchServices({
      type: "SET_SERVICE_MANAGE_OPEN",
      serviceManageOpen: false,
    });
  };

  const handleFilesRemove = (indexInput) => {
    const newFileList = files.filter((item, index) => index !== indexInput);
    setFiles(newFileList);
    setUpdateFiles(newFileList.length);
  };

  const handleRemoveDisplayFiles = (id) => {
    const filesDisplay = data.service_files.filter((file) => file.id !== id);
    const removeFiles = data.remove_files_id;
    removeFiles.push(id);
    const newData = {
      ...data,
      service_files: filesDisplay,
      remove_files_id: removeFiles,
    };
    setData(newData);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [endDaysAnchorEl, setEndDaysAnchorEl] = React.useState(null);
  const [daysAnchorEl, setDaysAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEndDaysPopoverClick = (event) => {
    setEndDaysAnchorEl(event.currentTarget);
  };
  const handleDaysPopoverClick = (event) => {
    setDaysAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDaysPopoverClose = () => {
    setDaysAnchorEl(null);
  };
  const handleEndDaysPopoverClose = () => {
    setEndDaysAnchorEl(null);
  };
  const [startDate, setStartDate] = useState({
    hours: "",
    minutes: "",
  });
  const [endDate, setEndDate] = useState({
    hours: "",
    minutes: "",
  });
  //validate if the start date is less than the end date
  const [startEndDatesError, setStartEndDatesError] = useState(false);
  useEffect(() => {
    if (startDate.hours !== "" && endDate.hours !== "") {
      if (Number(startDate.hours) > Number(endDate.hours)) {
        if (Number(startDate.hours) === 12 && Number(endDate.hours) < 12) {
          setStartEndDatesError(false);
        } else {
          setStartEndDatesError(true);
        }
      }
      if (
        Number(startDate.hours) === Number(endDate.hours) &&
        Number(startDate.minutes) > Number(endDate.minutes)
      ) {
        setStartEndDatesError(true);
      }
      if (Number(startDate.hours) < Number(endDate.hours)) {
        setStartEndDatesError(false);
      }
      if (
        Number(startDate.hours) === Number(endDate.hours) &&
        Number(startDate.minutes) < Number(endDate.minutes)
      ) {
        setStartEndDatesError(false);
      }
    }
  }, [startDate, endDate]);
  const open = Boolean(anchorEl);
  const endDaysOpen = Boolean(endDaysAnchorEl);
  const daysOpen = Boolean(daysAnchorEl);
  const id = open ? "simple-popover" : undefined;
  const costTotal =
    data.perform_service_type === 1
      ? data.payment_in_house_type === 1
        ? data.cost_in_house
        : 0
      : data.payment_subcontractor_type === 0
      ? data.cost_flat_subcontractor
      : data.cost_subcontractor;

  const inspectionLabels = Array.isArray(inspectionSelected)
    ? inspectionSelected
        .map(
          (selectedValue) =>
            optionsInspectionType.find(
              (option) => option.value === selectedValue.value
            )?.label
        )
        .filter(Boolean)
        .join(", ")
    : "";

  return !isCompleted ? (
    <Drawer
      anchor="right"
      open={serviceManageOpen}
      className={classes.drawerWrapper}
    >
      <IconButton
        className={classes.buttonClose}
        aria-label="Close"
        onClick={() =>
          dispatchServices({
            type: "SET_SERVICE_MANAGE_OPEN",
            serviceManageOpen: false,
          })
        }
      >
        <CloseIcon className={classes.iconClose} />
      </IconButton>
      <Box className={classes.headerContainer}>
        <Typography variant="h5" className={classes.headerContainerSteps}>
          Step {step}/{totalSteps}
        </Typography>
        <Typography
          variant="h3"
          gutterBottom={true}
          className={classes.headerContainerTitle}
        >
          {[VISUAL_INSPECTION_PROJECT_ID].includes(project.type)
            ? visualInspectionTitles[step].title
            : formHeaderTitles[step].title}
        </Typography>
        <Typography
          variant="h6"
          gutterBottom={true}
          className={classes.headerContainerSubTitle}
        >
          {[VISUAL_INSPECTION_PROJECT_ID].includes(project.type)
            ? visualInspectionTitles[step].subtitle
            : formHeaderTitles[step].subtitle}
        </Typography>
      </Box>
      {isLoading || isLoadingJob || isLoadingUser || isLoadingSubCrew ? (
        <Box>
          <Box className={classes.centerLoadingDraw}>
            <LoadingStateHorizontal isVisible />
          </Box>
          <Typography variant="h6" className={classes.loadingText}>
            {statusLoadingTitles[statusLoading]}
          </Typography>
        </Box>
      ) : (
        <Box className={classes.containerInformationCreate}>
          {step === 1 ? (
            <>
              <Box className={classes.containerInnerWrapper}>
                {!isLoadingJob ? (
                  jobs.length ? (
                    <Box className={classes.formInputContainer}>
                      <FormSelectCheckboxJobsAuto
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={jobs}
                        name="job_list"
                        placeholder="Select Job/s"
                        label="Job/s (Service will be created for each job selected)*"
                        value={jobsList}
                        handleChange={changeJobList}
                        multiple={true}
                      />
                    </Box>
                  ) : null
                ) : (
                  <LinearProgress color="secondary" />
                )}
                {![VISUAL_INSPECTION_PROJECT_ID].includes(project.type) ? (
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={serviceCategories}
                    name="service_category"
                    label="Service Category*"
                    placeholder="Select Category"
                    value={data.service_category}
                    handleChange={handleChangeService}
                  />
                ) : null}
                {[3, VISUAL_INSPECTION_PROJECT_ID].includes(project.type) ? (
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    options={serviceGLTypeOptions}
                    name="service_gl_type"
                    label="Service Type*"
                    placeholder="Select Service Type "
                    value={data.service_gl_type}
                    handleChange={handleChangeGlType}
                  />
                ) : null}
                {[VISUAL_INSPECTION_PROJECT_ID].includes(project.type) ? (
                  <FormSelectAuto
                    gridSizes={[{ size: "md", val: 12 }]}
                    multiple
                    id="service_inspection_type"
                    name="service_inspection_type"
                    placeholder="Select Inspection Type"
                    label="Inspection Type*"
                    options={optionsInspectionType}
                    value={inspectionSelected}
                    handleChange={handleInspectionsChange}
                  />
                ) : null}
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="name"
                  label={`${
                    [VISUAL_INSPECTION_PROJECT_ID].includes(project.type)
                      ? "Inspection"
                      : "Service"
                  } Name*`}
                  placeholder={`Write ${
                    [VISUAL_INSPECTION_PROJECT_ID].includes(project.type)
                      ? "Inspection"
                      : "Service"
                  } Name*`}
                  value={data.name}
                  handleBlur={handleBlur}
                  error={inputError}
                />
                <Grid
                  container
                  spacing={2}
                  className={classes.containerOptions}
                >
                  <Grid item md={6} sm={12}>
                    <InputLabel className={classes.inputLabel}>
                      Is this service billable?*
                    </InputLabel>
                    <ButtonGroup
                      variant="outlined"
                      color="default"
                      aria-label="primary button group"
                      className={classes.groupOption}
                      fullWidth={true}
                    >
                      {switchOptions.map((opt) => (
                        <Button
                          key={`billable-${opt.value}`}
                          className={
                            data.is_billable === opt.value
                              ? classes.groupOptionActive
                              : null
                          }
                          onClick={() => handleBillable(opt.value)}
                          disabled={serviceManageMode === "EDIT"}
                        >
                          {opt.label}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </Grid>
                  <Grid item md={12} sm={12}>
                    {data.is_billable && (
                      <FormSelectAuto
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={billingOptionsMonthlyService}
                        name="billing_option_service_month"
                        label="Billing Options*"
                        value={data.billing_option_service_month}
                        handleChange={handleChangeBillingOptionType}
                        readonly={serviceManageMode === "EDIT"}
                      />
                    )}
                  </Grid>
                  {data.is_billable && (
                    <>
                      {data.billing_option_service_month?.value === 0 && (
                        <>
                          <Grid item md={12} sm={12}>
                            <FormSelectAuto
                              gridSizes={[{ size: "md", val: 12 }]}
                              options={billingOptions}
                              name="billing_option"
                              label="test"
                              hideLabel={true}
                              value={data.billing_option}
                              handleChange={handleChangeBillingOption}
                            />
                          </Grid>
                          {data.billing_option_type === 3 && (
                            <Grid item>
                              <Box
                                className={
                                  classes.timeAllotmentBillingUnitsContainer
                                }
                              >
                                <FormInput
                                  gridSizes={[{ size: "md", val: 12 }]}
                                  name="bill_units_per_instance"
                                  label="Time Allotment*"
                                  value={data.units_per_instance}
                                  handleBlur={handleTimeBlur}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      hrs
                                    </InputAdornment>
                                  }
                                />
                                <FormInput
                                  gridSizes={[{ size: "md", val: 12 }]}
                                  name="unit_billing"
                                  label="Billing Unit*"
                                  value={data.unit_billing}
                                  handleBlur={handleBlur}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  }
                                />
                              </Box>
                            </Grid>
                          )}
                        </>
                      )}
                      <Grid item md={4} sm={12}>
                        {/*//price ee*/}
                        <FormInput
                          gridSizes={[{ size: "md", val: 12 }]}
                          name="billing_price"
                          label="Price*"
                          value={data.billing_price}
                          handleBlur={handleBlur}
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                          readonly={data.billing_option?.value === 3}
                        />
                      </Grid>
                      <Grid item md={8} sm={12}>
                        <Box className={classes.infoWrapper}>
                          <BillingIcon className={classes.iconGreen} />
                          <Typography variant="caption">
                            What are you charging per{" "}
                            {data.billing_option_service_month?.value === 1
                              ? "month"
                              : "service"}
                            ?
                          </Typography>
                        </Box>
                      </Grid>
                      {data.billing_option_service_month?.value === 1 &&
                        data.is_billable && (
                          <>
                            <Grid item md={12} sm={12}>
                              <FormSelectAuto
                                gridSizes={[{ size: "md", val: 12 }]}
                                options={billingTimelineOptions}
                                name="billing_timeline"
                                label="Billing Timeline Service Cost*"
                                value={timelineOption}
                                handleChange={handleChangeTimelines(
                                  setTimelineOption,
                                  "way_of_billing_per_month"
                                )}
                              />
                            </Grid>
                          </>
                        )}
                      {timelineOption?.value === 3 &&
                        data.billing_option_service_month?.value === 1 &&
                        data.is_billable && (
                          <>
                            <Grid item md={12} sm={12}>
                              <FormSelectAuto
                                gridSizes={[{ size: "md", val: 12 }]}
                                options={optionsDaysMonth}
                                name="interval_month_days"
                                label="On Day First*"
                                handleChange={handleChangeBillingDayMonthly(
                                  setWillBeBilledNextMonth,
                                  "billing_monthly_day"
                                )}
                                multiple={false}
                                value={data.billing_monthly_day}
                              />
                            </Grid>

                            {willBeBilledNextMonth && (
                              // <Typography
                              //   className={classes.willBeBilledNextMonthText}
                              // >
                              //   This service will be billed next month on{" "}
                              //   {dateNextMonth?.format("MMMM Do YY")}
                              // </Typography>
                              <Grid item md={12} sm={12}>
                                <FormInputDateAdo
                                  name="start_date"
                                  label={"Billing Start Date"}
                                  disabled={true}
                                  readonly={true}
                                  internal={true}
                                  value={dateNextMonth}
                                  // handleChange={handleChangeDueDate}
                                  // disabled={serviceManageMode === "EDIT"}
                                />
                              </Grid>
                            )}
                            {/*<Grid item md={6} sm={12}>*/}
                            {/*  <FormInputDateAdo*/}
                            {/*    name="interval_end_date"*/}
                            {/*    label="End Date First*"*/}
                            {/*    value={data.end_date_billing_per_month}*/}
                            {/*    handleChange={handleChangeBillingMonthlyEndDate}*/}
                            {/*    disabled={data.interval_no_end_date}*/}
                            {/*  />*/}
                            {/*  <Box className={classes.endDateContainer}>*/}
                            {/*    <FormControlLabel*/}
                            {/*      control={*/}
                            {/*        <Checkbox*/}
                            {/*          checked={data.interval_no_end_date}*/}
                            {/*          onChange={handleChangeNoEndDate}*/}
                            {/*          className={classes.filterCheckbox}*/}
                            {/*        />*/}
                            {/*      }*/}
                            {/*      label={"Don't define end date"}*/}
                            {/*      value={1}*/}
                            {/*    />*/}
                            {/*  </Box>*/}
                            {/*</Grid>*/}
                          </>
                        )}
                    </>
                  )}
                </Grid>
              </Box>
              <Box className={classes.actionNavigator}>
                <ButtonBase
                  color="secondary"
                  onClick={() => setStep(2)}
                  disabled={!stepValidation.step1}
                >
                  Next
                </ButtonBase>
              </Box>
            </>
          ) : null}
          {step === 2 ? (
            <>
              <Box className={classes.containerInnerWrapper}>
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="summary"
                  label={`${
                    [VISUAL_INSPECTION_PROJECT_ID].includes(project.type)
                      ? "Inspection"
                      : "Service"
                  } Summary*`}
                  placeholder={"Write Summary"}
                  value={data.summary}
                  handleBlur={handleBlur}
                  multiline={true}
                  rows={2}
                  error={inputError}
                />
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="description"
                  label={`${
                    [VISUAL_INSPECTION_PROJECT_ID].includes(project.type)
                      ? "Inspection"
                      : "Service"
                  } Description*`}
                  placeholder={"Write description"}
                  value={data.description}
                  handleBlur={handleBlur}
                  multiline={true}
                  rows={4}
                />
                {!project?.is_external ? (
                  <>
                    <InputLabel className={classes.inputLabel}>
                      Task List
                    </InputLabel>
                    <ServicesTask
                      taskList={data.tasks}
                      onChange={handleChangeTask}
                    />
                    <Box className={classes.fileWrapper}>
                      {!files.length ? (
                        <InputLabel className={classes.inputLabel}>
                          Documents
                        </InputLabel>
                      ) : null}
                      <FileUpload
                        value={files}
                        handleChange={setFiles}
                        handleUpdate={setUpdateFiles}
                        filesDisplay={
                          updateFiles ? (
                            <FilesDisplay
                              files={files}
                              handleRemove={handleFilesRemove}
                            />
                          ) : null
                        }
                      />
                      <Box className={classes.containerDisplayFiles}>
                        <FilesDisplayUploaded
                          files={data.service_files}
                          handleRemoveDisplay={handleRemoveDisplayFiles}
                          controlled={true}
                        />
                      </Box>
                    </Box>
                  </>
                ) : null}
              </Box>

              <Box className={classes.actionNavigator}>
                <ButtonBase
                  color="secondary"
                  variant="text"
                  className={classes.buttonBack}
                  startIcon={<BackIcon />}
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  Back
                </ButtonBase>
                <ButtonBase
                  color="secondary"
                  onClick={() => setStep(3)}
                  disabled={!stepValidation.step2}
                >
                  Next
                </ButtonBase>
              </Box>
            </>
          ) : null}
          {step === 3 ? (
            <>
              <FormControl
                required
                component="fieldset"
                className={classes.formControl}
                disabled={serviceManageMode === "EDIT"}
              >
                <FormLabel focused={false}>
                  Who is performing the service?
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={inhouse}
                        onChange={handleChange}
                        name="inhouse"
                      />
                    }
                    label="In-House"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={subcontractor}
                        onChange={handleChange}
                        name="subcontractor"
                      />
                    }
                    label="Sub-Contractor"
                  />
                </FormGroup>
              </FormControl>
              <Box className={classes.containerInnerWrapper}>
                {data.perform_service_type === 1 ? (
                  <>
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={teamMembersList}
                      name="team_member"
                      label="Team Member Responsible*"
                      value={data.team_member}
                      handleChange={handleChangeTeamMember}
                    />
                    <Grid container>
                      <Grid item md={8} sm={12}>
                        <FormSelectAuto
                          gridSizes={[{ size: "md", val: 12 }]}
                          options={paymentInHouse}
                          name="payment_in_house"
                          label="Payment*"
                          value={data.payment_in_house}
                          handleChange={handleChangePaymentInHouse}
                          readonly={serviceManageMode === "EDIT"}
                        />
                      </Grid>
                    </Grid>
                    {data.payment_in_house_type === 1 ? (
                      <>
                        <Grid spacing={4} container>
                          <Grid item md={6} sm={12}>
                            <FormInput
                              gridSizes={[{ size: "md", val: 12 }]}
                              name="in_house_units_per_instance"
                              label="Time Allotment"
                              value={data.units_per_instance}
                              handleBlur={handleTimeBlur}
                              endAdornment={
                                <InputAdornment position="end">
                                  hrs
                                </InputAdornment>
                              }
                            />
                          </Grid>
                          <Grid item md={6} sm={12}>
                            <FormInput
                              gridSizes={[{ size: "md", val: 12 }]}
                              name="unit_cost_in_house"
                              label="Unit Cost"
                              value={data.unit_cost_in_house}
                              handleBlur={handleBlur}
                              startAdornment={
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              }
                            />
                          </Grid>
                        </Grid>
                        <Box className={classes.inputWrapper}>
                          <Grid container spacing={2}>
                            <Grid item md={5} sm={12}>
                              <FormInput
                                gridSizes={[{ size: "md", val: 12 }]}
                                name="cost_in_house"
                                label="Cost"
                                value={data.cost_in_house}
                                readonly={true}
                                startAdornment={
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                }
                              />
                            </Grid>
                            <Grid item md={7} sm={12}>
                              <Box className={classes.infoWrapper}>
                                <InfoIcon className={classes.iconYellow} />
                                <Typography variant="caption">
                                  Your estimated per service.
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    ) : null}
                  </>
                ) : null}
                {data.perform_service_type === 2 ? (
                  <>
                    <FormSelectAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={subcontractorList}
                      name="subcontractor"
                      label="Partner Responsible*"
                      value={data.subcontractor}
                      handleChange={handleChangeSubcontractor}
                    />
                    {subMemberList?.length ? (
                      <FormSelectAuto
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={subMemberList}
                        name="sub_member"
                        label="Member Responsible"
                        value={data.sub_member}
                        handleChange={handleChangeSubMember}
                      />
                    ) : null}

                    <Grid container>
                      <Grid item md={8} sm={12}>
                        <FormSelectAuto
                          gridSizes={[{ size: "md", val: 12 }]}
                          options={paymentSubcontractor}
                          name="payment_subcontractor"
                          label="Payment*"
                          value={data.payment_subcontractor}
                          handleChange={handleChangePaymentSubcontractor}
                          readonly={serviceManageMode === "EDIT"}
                        />
                      </Grid>
                    </Grid>
                    {data.payment_subcontractor_type === 0 ? (
                      <Box className={classes.inputWrapper}>
                        <Grid container spacing={2}>
                          <Grid item md={5} sm={12}>
                            <FormInput
                              gridSizes={[{ size: "md", val: 12 }]}
                              name="cost_flat_subcontractor"
                              label="Cost*"
                              handleBlur={handleBlur}
                              value={data.cost_flat_subcontractor}
                              startAdornment={
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              }
                            />
                          </Grid>
                          <Grid item md={7} sm={12}>
                            <Box className={classes.infoWrapper}>
                              <InfoIcon className={classes.iconYellow} />
                              <Typography variant="caption">
                                Your cost per service.
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    ) : null}
                    {data.payment_subcontractor_type === 3 ? (
                      <>
                        <Grid spacing={2} container>
                          <Grid item md={6} sm={12}>
                            <FormInput
                              gridSizes={[{ size: "md", val: 12 }]}
                              name="sub_units_per_instance"
                              label="Time Allotment*"
                              value={data.units_per_instance}
                              handleBlur={handleTimeBlur}
                              endAdornment={
                                <InputAdornment position="end">
                                  hrs
                                </InputAdornment>
                              }
                            />
                          </Grid>
                          <Grid item md={6} sm={12}>
                            <FormInput
                              gridSizes={[{ size: "md", val: 12 }]}
                              name="unit_cost_subcontractor"
                              label="Unit Cost*"
                              value={data.unit_cost_subcontractor}
                              handleBlur={handleBlur}
                              startAdornment={
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              }
                            />
                          </Grid>
                        </Grid>
                        <Box className={classes.inputWrapper}>
                          <Grid container spacing={2}>
                            <Grid item md={5} sm={12}>
                              <FormInput
                                gridSizes={[{ size: "md", val: 12 }]}
                                name="cost_subcontractor"
                                label="Cost*"
                                value={data.cost_subcontractor}
                                readOnly={true}
                                handleBlur={handleBlur}
                                startAdornment={
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                }
                              />
                            </Grid>
                            <Grid item md={7} sm={12}>
                              <Box className={classes.infoWrapper}>
                                <InfoIcon className={classes.iconYellow} />
                                <Typography variant="caption">
                                  Your cost per service.
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    ) : null}
                    {data.payment_subcontractor_type === 6 && (
                      <>
                        <Box>
                          <Box className={classes.inputWrapper}>
                            <Grid container spacing={2}>
                              <Grid item md={5} sm={12}>
                                <FormInput
                                  gridSizes={[{ size: "md", val: 12 }]}
                                  name="cost_monthly_subcontractor"
                                  label="Costs Per Month*"
                                  value={data.cost_monthly_subcontractor}
                                  handleBlur={handleBlur}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  }
                                />
                              </Grid>
                              <Grid item md={7} sm={12}>
                                <Box className={classes.infoWrapper}>
                                  <InfoIcon className={classes.iconYellow} />
                                  <Typography variant="caption">
                                    What you are paying per month sub
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                        <FormSelectAuto
                          gridSizes={[{ size: "md", val: 12 }]}
                          options={billingTimelineOptions}
                          name="billing_timeline_input_cost"
                          label="Billing Timeline Input Cost*"
                          value={timelineOptionInputCost}
                          handleChange={handleChangeTimelines(
                            setTimelineOptionInputCost,
                            "way_of_billing_per_month_input_cost"
                          )}
                        />
                        {timelineOptionInputCost?.value === 3 && (
                          <>
                            <FormSelectAuto
                              gridSizes={[{ size: "md", val: 12 }]}
                              options={optionsDaysMonth}
                              name="interval_month_days_input_cost"
                              label="On Day Input Cost*"
                              handleChange={handleChangeBillingDayMonthly(
                                setWillBeBilledNextMonthInputCost,
                                "billing_monthly_day_input_cost"
                              )}
                              multiple={false}
                              value={data.billing_monthly_day_input_cost}
                            />
                            {willBeBilledNextMonthInputCost && (
                              // <Typography
                              //   className={classes.willBeBilledNextMonthText}
                              // >
                              //   This service will be billed next month on{" "}
                              //   {dateNextMonth?.format("MMMM Do YY")}
                              // </Typography>
                              <FormInputDateAdo
                                name="start_date"
                                label={"Billing Start Date Input Cost"}
                                disabled={true}
                                readonly={true}
                                internal={true}
                                value={dateNextMonth}
                                // handleChange={handleChangeDueDate}
                                // disabled={serviceManageMode === "EDIT"}
                              />
                            )}
                            {/*<Grid item md={6} sm={12}>*/}
                            {/*  <FormInputDateAdo*/}
                            {/*    name="interval_end_date"*/}
                            {/*    label="End Date Second*"*/}
                            {/*    value={data.end_date_billing_per_month}*/}
                            {/*    handleChange={handleChangeBillingMonthlyEndDate}*/}
                            {/*    disabled={data.interval_no_end_date}*/}
                            {/*  />*/}
                            {/*  <Box className={classes.endDateContainer}>*/}
                            {/*    <FormControlLabel*/}
                            {/*      control={*/}
                            {/*        <Checkbox*/}
                            {/*          checked={data.interval_no_end_date}*/}
                            {/*          onChange={handleChangeNoEndDate}*/}
                            {/*          className={classes.filterCheckbox}*/}
                            {/*        />*/}
                            {/*      }*/}
                            {/*      label={"Don't define end date"}*/}
                            {/*      value={1}*/}
                            {/*    />*/}
                            {/*  </Box>*/}
                            {/*</Grid>*/}
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : null}
              </Box>
              <Box className={classes.actionNavigator}>
                <ButtonBase
                  color="secondary"
                  variant="text"
                  className={classes.buttonBack}
                  startIcon={<BackIcon />}
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  Back
                </ButtonBase>
                <ButtonBase
                  color="secondary"
                  onClick={() => validateStepRecurrence()}
                  disabled={!stepValidation.step3}
                >
                  Next
                </ButtonBase>
              </Box>
            </>
          ) : null}
          {step === 4 && totalSteps === 5 ? (
            <>
              <Box className={classes.containerInnerWrapper}>
                <FormSelectAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={frequencyOptions}
                  name="frequency"
                  label={`${
                    [VISUAL_INSPECTION_PROJECT_ID].includes(project.type)
                      ? "Inspection"
                      : "Service"
                  } Frequency*`}
                  value={data.frequency}
                  handleChange={handleChangeFrequencyService}
                  readonly={
                    data.billing_option_service_month?.value === 1 ||
                    data.payment_subcontractor_type === 6
                  }
                />

                {data.frequency_type === 1 ? (
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={12}>
                      <FormInputDateAdo
                        name="start_date"
                        label={"Due Date*"}
                        internal={true}
                        value={data.start_date}
                        handleChange={handleChangeDueDate}
                        // disabled={serviceManageMode === "EDIT"}
                      />
                    </Grid>
                    {serviceManageMode === "EDIT" &&
                    data.frequency_type !==
                      (dataUpdateService.is_recurring ? 2 : 1) ? (
                      <Grid item md={6} sm={12}>
                        <FormInputDateAdo
                          name="effective_date"
                          label={"Effective Date*"}
                          value={data.effective_date}
                          handleChange={handleChangeEffectiveDate}
                          minDate={data.start_date}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                ) : null}
                {data.frequency_type === 2 ? (
                  <Box className={classes.intervalWrapper}>
                    <Grid container spacing={2}>
                      <Grid item md={6} sm={12}>
                        <FormSelectAuto
                          gridSizes={[{ size: "md", val: 12 }]}
                          options={intervalOptions}
                          name="interval_type"
                          label="Interval*"
                          value={data.interval_type}
                          handleChange={handleChangeInterval}
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <FormInput
                          styleOverride={{ height: 38 }}
                          gridSizes={[{ size: "md", val: 12 }]}
                          name="interval_size"
                          label="Every*"
                          value={data.interval_size}
                          handleBlur={handleChangeSize}
                          endAdornment={
                            <InputAdornment position="end">
                              {data.interval_type?.label ?? ""}
                            </InputAdornment>
                          }
                        />
                      </Grid>
                      {data.interval_type_id === 3 ? (
                        <Grid item md={6} sm={12} style={{ paddingBottom: 20 }}>
                          <Box className={classes.skipContainer}>
                            <Box className={classes.flexAlignCenter}>
                              <Checkbox
                                checked={data.interval_skip_weekdays}
                                onChange={handleChangeSkipWeekends}
                                className={classes.filterCheckbox}
                              />
                              <Typography className={classes.labelCheckbox}>
                                Skip weekends
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ) : null}
                    </Grid>
                    {data.interval_type_id === 2 ? (
                      <Grid container style={{ padding: 10 }}>
                        {optionsDays.map((option) => {
                          return (
                            <Grid item xs={6} md={4} key={option.value}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={data.interval_week_days.includes(
                                      option.value
                                    )}
                                    onChange={(e) =>
                                      handleChangeDays(e, option.value)
                                    }
                                    className={classes.filterCheckbox}
                                  />
                                }
                                label={option.label}
                                value={option.value}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    ) : null}
                    {data.interval_type_id === 3 ? (
                      <FormSelectAuto
                        gridSizes={[{ size: "md", val: 12 }]}
                        options={optionsDaysMonth}
                        name="interval_month_days"
                        label="On Days*"
                        handleChange={handleChangeDaysMonth}
                        multiple={true}
                        value={data.interval_month_days}
                      />
                    ) : null}
                    <Grid container spacing={2} style={{ paddingTop: 15 }}>
                      <Grid item md={6} sm={12}>
                        <FormInputDateAdo
                          name="start_date"
                          label="Start Date*"
                          value={data.start_date}
                          handleChange={handleChangeDueDate}
                          disabled={serviceManageMode === "EDIT"}
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <FormInputDateAdo
                          name="interval_end_date"
                          label="End Date*"
                          value={data.interval_end_date}
                          handleChange={handleChangeEndDate}
                          disabled={data.interval_no_end_date}
                        />
                        <Box className={classes.endDateContainer}>
                          <Box className={classes.flexAlignCenter}>
                            <Checkbox
                              checked={data.interval_no_end_date}
                              onChange={handleChangeNoEndDate}
                              className={classes.filterCheckbox}
                            />
                            <Typography className={classes.labelCheckbox}>
                              Don't define end date
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      {serviceManageMode === "EDIT" &&
                      (dirtyRecurrence ||
                        data.frequency_type !==
                          (dataUpdateService.is_recurring ? 2 : 1)) ? (
                        <Grid item md={6} sm={12}>
                          <FormInputDateAdo
                            name="effective_date"
                            label={"Effective Date*"}
                            value={data.effective_date}
                            handleChange={handleChangeEffectiveDate}
                            minDate={data.effective_limit}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                ) : null}
                <ServicesPreview
                  jobsList={jobsList}
                  data={data}
                  active={stepValidation.step4}
                />
              </Box>
              <Box className={classes.actionNavigator}>
                <ButtonBase
                  color="secondary"
                  variant="text"
                  className={classes.buttonBack}
                  startIcon={<BackIcon />}
                  onClick={() => {
                    setStep(3);
                  }}
                >
                  Back
                </ButtonBase>
                <ButtonBase
                  color="secondary"
                  onClick={() => setStep(5)}
                  disabled={!stepValidation.step4}
                >
                  View Summary
                </ButtonBase>
              </Box>
            </>
          ) : null}
          {(step === 5 || (step === 4 && totalSteps === 4)) &&
          ![VISUAL_INSPECTION_PROJECT_ID].includes(project.type) ? (
            <>
              <Box className={classes.containerInnerWrapper}>
                <Box className={classes.summaryContainer}>
                  <InputLabel className={classes.inputLabel}>
                    Price (Per Service)
                  </InputLabel>
                  <Box className={classes.summaryInnerContainer}>
                    <Box className={classes.summaryField}>
                      <Typography
                        variant="h4"
                        className={classes.summaryFieldText}
                      >
                        ${formatStringToNumber(data.billing_price)}
                      </Typography>
                    </Box>
                    <Box className={classes.summaryIcon}>
                      <BillingIcon className={classes.iconGreen} />
                    </Box>
                    <Typography
                      variant="caption"
                      className={classes.summaryDesc}
                    >
                      What you charging per service
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.summaryContainer}>
                  <InputLabel className={classes.inputLabel}>
                    Cost and Assignment (Per Service)
                  </InputLabel>
                  <Box className={classes.summaryInnerContainer}>
                    {data.payment_subcontractor_type === 5 && (
                      <Typography
                        variant={"h4"}
                        className={classes.summaryFieldText}
                      >
                        No Payment
                      </Typography>
                    )}

                    <Box className={classes.summaryField}>
                      <Typography
                        variant="h4"
                        className={classes.summaryFieldText}
                      >
                        {data.payment_subcontractor_type === 6 && (
                          <>
                            $
                            {formatStringToNumber(
                              data.cost_monthly_subcontractor
                            )}
                          </>
                        )}
                        {costTotal !== 0 &&
                          data.payment_subcontractor_type !== 6 && (
                            <>${formatStringToNumber(costTotal)}</>
                          )}
                      </Typography>
                    </Box>
                    {data.payment_subcontractor_type !== 5 && (
                      <>
                        <Box className={classes.summaryIcon}>
                          <InfoIcon className={classes.iconYellow} />
                        </Box>
                        <Typography
                          variant="caption"
                          className={classes.summaryDesc}
                        >
                          Estimate cost per service
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
                {data.is_billable ? (
                  <Box className={classes.summaryContainer}>
                    <InputLabel className={classes.inputLabel}>
                      Profit (Per Service)
                    </InputLabel>
                    <Box className={classes.summaryInnerGrayContainer}>
                      <Box className={classes.summaryField}>
                        <Typography
                          variant="h4"
                          className={classes.summaryFieldText}
                        >
                          {data.payment_subcontractor_type === 6 && (
                            <>
                              $
                              {formatStringToNumber(
                                data.billing_price -
                                  data.cost_monthly_subcontractor
                              )}
                            </>
                          )}
                          {costTotal !== 0 &&
                            data.payment_subcontractor_type !== 6 && (
                              <>
                                $
                                {formatStringToNumber(
                                  data.billing_price - costTotal
                                )}
                              </>
                            )}
                        </Typography>
                      </Box>
                      <Box className={classes.summaryIcon}>
                        <CheckIcon className={classes.iconBlue} />
                      </Box>
                      <Typography
                        variant="caption"
                        className={classes.summaryDesc}
                      >
                        Estimate profit per service
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                {/*<Box className={classes.summaryContainer}>*/}
                {/*  <InputLabel className={classes.inputLabel}>*/}
                {/*    Billing Option Service*/}
                {/*  </InputLabel>*/}
                {/*  <Box className={classes.summaryInnerGrayContainer}>*/}
                {/*    <Box className={classes.summaryIcon}>*/}
                {/*      <CheckIcon className={classes.iconBlue} />*/}
                {/*    </Box>*/}
                {/*    <Typography variant="h6" className={classes.summaryDetail}>*/}
                {/*      {timelineOption?.label}*/}
                {/*    </Typography>*/}
                {/*  </Box>*/}
                {/*</Box>*/}
                {/*{timelineOption?.value === 3 && (*/}
                {/*  <Box className={classes.summaryContainer}>*/}
                {/*    <InputLabel className={classes.inputLabel}>*/}
                {/*      Service will be billed on the following day*/}
                {/*    </InputLabel>*/}
                {/*    <Box className={classes.summaryInnerGrayContainer}>*/}
                {/*      <Box className={classes.summaryIcon}>*/}
                {/*        <CheckIcon className={classes.iconBlue} />*/}
                {/*      </Box>*/}
                {/*      <Typography*/}
                {/*        variant="h6"*/}
                {/*        className={classes.summaryDetail}*/}
                {/*      >*/}
                {/*        {data.billing_monthly_day?.value} of each month*/}
                {/*      </Typography>*/}
                {/*    </Box>*/}
                {/*  </Box>*/}
                {/*)}*/}
                <Box className={classes.summaryContainer}>
                  <InputLabel className={classes.inputLabel}>
                    Service Detail
                  </InputLabel>
                  <Box className={classes.summaryInnerGrayContainer}>
                    <Box className={classes.summaryIcon}>
                      <CheckIcon className={classes.iconBlue} />
                    </Box>
                    <Typography variant="h6" className={classes.summaryDetail}>
                      {data.summary}
                    </Typography>
                  </Box>
                </Box>
                {!project?.is_external ? (
                  <Box className={classes.summaryContainer}>
                    <InputLabel className={classes.inputLabel}>
                      Frequency
                    </InputLabel>
                    <Box className={classes.summaryInnerGrayContainer}>
                      <Box className={classes.summaryIcon}>
                        <CheckIcon className={classes.iconBlue} />
                      </Box>
                      <Typography
                        variant="h6"
                        className={classes.summaryDetail}
                      >
                        {data.frequency_type === 1
                          ? "One-Time"
                          : `${data.interval_type?.labelPlural} Recurrence`}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                <Box className={classes.summaryContainer}>
                  <InputLabel className={classes.inputLabel}>
                    Service being performed by
                  </InputLabel>
                  <Box className={classes.summaryInnerGrayContainer}>
                    <Box className={classes.summaryIcon}>
                      <CheckIcon className={classes.iconBlue} />
                    </Box>
                    <Typography variant="h6" className={classes.summaryDetail}>
                      {inhouse ? "In-House" : "Sub-Contractor"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.actionNavigator}>
                <ButtonBase
                  color="secondary"
                  variant="text"
                  className={classes.buttonBack}
                  startIcon={<BackIcon />}
                  onClick={() => {
                    setStep(totalSteps === 4 ? 3 : 4);
                  }}
                >
                  Back
                </ButtonBase>
                <ButtonBase
                  color="secondary"
                  onClick={
                    serviceManageMode === "NEW"
                      ? handleSubmit
                      : handleUpdateSubmit
                  }
                  disabled={!stepValidation.step5}
                >
                  {serviceManageMode === "NEW" ? "Finalize" : "Update"}
                </ButtonBase>
              </Box>
            </>
          ) : null}
          {step === 5 &&
          [VISUAL_INSPECTION_PROJECT_ID].includes(project.type) ? (
            <>
              <Box className={classes.containerInnerWrapper}>
                <Box className={classes.summaryContainer}>
                  <InputLabel className={classes.inputLabel}>
                    Inspection Name
                  </InputLabel>
                  <Box className={classes.summaryInnerGrayContainer}>
                    <Box className={classes.summaryIcon}>
                      <CheckIcon className={classes.iconBlue} />
                    </Box>
                    <Typography variant="h6" className={classes.summaryDetail}>
                      {data.name}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.summaryContainer}>
                  <InputLabel className={classes.inputLabel}>
                    Inspection Frequency
                  </InputLabel>
                  <Box className={classes.summaryInnerGrayContainer}>
                    <Box className={classes.summaryIcon}>
                      <CheckIcon className={classes.iconBlue} />
                    </Box>
                    <Typography variant="h6" className={classes.summaryDetail}>
                      {data.frequency_type === 1
                        ? "One-Time"
                        : `${data.interval_type?.labelPlural} Recurrence`}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.summaryContainer}>
                  <InputLabel className={classes.inputLabel}>
                    Inspection Type
                  </InputLabel>
                  <Box className={classes.summaryInnerGrayContainer}>
                    <Box className={classes.summaryIcon}>
                      <CheckIcon className={classes.iconBlue} />
                    </Box>
                    <Typography variant="h6" className={classes.summaryDetail}>
                      {inspectionLabels}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.summaryContainer}>
                  <InputLabel className={classes.inputLabel}>
                    Payment
                  </InputLabel>
                  <Box className={classes.summaryInnerGrayContainer}>
                    <Box className={classes.summaryIcon}>
                      <InfoIcon className={classes.iconYellow} />
                    </Box>

                    <Typography variant="h6" className={classes.summaryDetail}>
                      {data.perform_service.value === 1
                        ? data.payment_in_house.label
                        : data.payment_subcontractor.label}
                    </Typography>
                  </Box>
                </Box>

                {/* <FormInputDateAdo
                  name="start_date"
                  label={"Due Date"}
                  internal={true}
                  value={data.start_date}
                  disabled={true}
                /> */}
              </Box>

              <Box className={classes.actionNavigator}>
                <ButtonBase
                  color="secondary"
                  variant="text"
                  className={classes.buttonBack}
                  startIcon={<BackIcon />}
                  onClick={() => {
                    setStep(totalSteps === 4 ? 3 : 4);
                  }}
                >
                  Back
                </ButtonBase>
                <ButtonBase
                  color="secondary"
                  onClick={
                    serviceManageMode === "NEW"
                      ? handleSubmit
                      : handleUpdateSubmit
                  }
                  disabled={!stepValidation.step5}
                >
                  {serviceManageMode === "NEW" ? "Finalize" : "Update"}
                </ButtonBase>
              </Box>
            </>
          ) : null}
        </Box>
      )}
    </Drawer>
  ) : (
    <MessageDialog
      open={isCompleted}
      title={"Success!"}
      message={`Service has been ${
        serviceManageMode === "NEW" ? "created" : "updated"
      }.`}
      handleClose={closeMessageDialog}
    />
  );
};

export default ServicesManagerDrawer;
