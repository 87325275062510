import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputField: {
    flexGrow: 1,
    width: 300,
    maxWidth: 300,
    minWidth: 300,
    height: 42,
    backgroundColor: "white",
    borderRadius: 4,
    border: "solid 1px #d6dde1",
    "& .Mui-focused": {
      border: "solid 1px #359be0",
    },
    "& > input": {
      padding: 8,
    },
    "& > fieldset": {
      border: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: 160,
      maxWidth: 160,
      minWidth: 160,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      clear: "both",
      marginTop: 10,
      zIndex: 100,
      width: "100%",
      maxWidth: "100%",
    },
  },
  inputFieldSearchAi: {
    flexGrow: 1,
    width: "100%",
    maxWidth: "700px",
    height: "42px",
    backgroundColor: "white",
    borderRadius: 4,
    border: "solid 1px #d6dde1",
    paddingLeft: "2px",
    "& div:first-child": {
      marginRight: 0,
    },
    "& .Mui-focused": {
      border: "solid 1px #359be0",
    },
    "& > input": {
      padding: "8px",
    },
    "& > fieldset": {
      border: "none",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "500px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      marginBottom: "10px",
      clear: "both",
      marginTop: "10px",
      zIndex: 100,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: "1px",
      height: "42px",
    },
  },
  inputFieldDashboardSearchAi: {
    flexGrow: 1,
    width: "100%",
    maxWidth: "100%",
    height: "42px",
    backgroundColor: "white",
    borderRadius: 4,
    border: "solid 1px #d6dde1",
    paddingLeft: "2px",
    "& div:first-child": {
      marginRight: 0,
    },
    "& .Mui-focused": {
      border: "solid 1px #359be0",
    },
    "& > input": {
      padding: "8px",
    },
    "& > fieldset": {
      border: "none",
    },
  },
  toolbarControls: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      marginTop: 0,
    },
  },
  toolbarFilters: {
    textAlign: "right",
    flexGrow: 4,
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  clearSearchButton: {
    padding: 0,
  },
  searchIcon: {
    color: "#ececec",
    cursor: "pointer",
  },
  containerFeedbackButtonSearch: {
    marginLeft: 14,
    gap: 4,
    display: "flex",
    float: "left",
    "& button": {
      marginLeft: 2,
      marginRight: 2,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "left",
    },
  },
  feedbackButton: {
    width: 40,
    height: 40,
    border: "1px solid #4F98BC",
    borderRadius: 4,
  },
  wrapperDialog: {
    textAlign: "center",
    paddingTop: 24,
  },
  wrapperTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleDialog: {
    color: "#4D4D4D",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 0,
    paddingLeft: 10,
    marginLeft: 8,
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 15,
  },
  selectContainer: {
    padding: 10,
  },
  textArea: {
    "& .MuiInputBase-input": {
      padding: 10,
      fontSize: 14,
      fontStyle: "normal",
    },
    border: "1px solid #d6dde1",
    borderRadius: 4,
  },
  actionItemButton: {
    textAlign: "right",
    paddingTop: 0,
    paddingRight: 35,
    paddingBottom: 28,
  },
  buttonCancel: {
    padding: "8px 32px",
    borderRadius: 4,
    border: "1px solid #4F98BC",
    textTransform: "capitalize",
    marginRight: 10,
    color: "#4F98BC",
  },
  buttonConfirm: {
    padding: "8px 32px",
    borderRadius: 4,
    border: "1px solid #4F98BC",
    color: "#ffffff",
    fontSize: 14,
    textTransform: "capitalize",
    backgroundColor: "#4f97bb",
    "&:hover": {
      backgroundColor: "#4F98BC",
    },
  },
  buttonFeedback: {
    border: "0.75px solid #4F98BC",
    borderRadius: "4px",
    padding: "10px 10px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
  },
}));

export default useStyles;
