import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { Box, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { FilterProvider } from "contexts/filterContext";
import { SectionHeader } from "pages/locationDetails/components";
import { BarChart, PieChart } from "./charts";
import { useLocationDetailsContext } from "pages/locationDetails/context";
import GlobalUiContext from "contexts/globalUiContext";
import { permissionDashboard, hasPermission } from "lib/permissions";
import { ButtonBase } from "components/ui/core/button";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
  chartsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 30,
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const KeyMetrics = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  const {
    details,
    metrics: { isLoading, data },
  } = useLocationDetailsContext();

  const buildQueryString = (details, metaDataDates) => {
    return `filters[job_number][]=${details?.data?.job_number}&filters[start_date]=${metaDataDates.start_date}%2000:00:00&filters[end_date]=${metaDataDates.end_date}%2023:59:59`;
  };

  const handleGoKpi = () => {
    const { history } = props;
    if (!details || !data) {
      console.error("Details or data not available");
      return; // Optionally show a notification to the user
    }
    const metaDataDates = data.metaData;
    const dataString = buildQueryString(details, metaDataDates);
    history.push(`/dashboard-kpi/worktickets?${dataString}`);
  };

  if (isLoading) {
    return <Skeleton variant="text" width="100%" height={250} />;
  }

  const metaData = data?.metaData;
  return (
    <FilterProvider>
      <Box className={classes.root}>
        <SectionHeader
          title="Key Metrics"
          actions={["date", "filter"]}
          metaData={metaData}
        />
        <Box className={classes.chartsContainer}>
          <PieChart />
          <BarChart />
        </Box>
        {hasPermission(permissionDashboard.KPI, permissions) && (
          <Box className={classes.actionContainer}>
            <ButtonBase onClick={handleGoKpi} color="secondary">
              View KPI Dashboard
            </ButtonBase>
          </Box>
        )}
      </Box>
    </FilterProvider>
  );
};

KeyMetrics.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(KeyMetrics);
