import React, { useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import useStyles from "./styles";
import BadgeIcon from "./badgeIcon";

const iconColors = [
  "#4F98BC",
  "#328914",
  "#E3B100",
  "#DB6F00",
  "#EA5168",
  "#844CB4",
];

const backgroundColors = [
  "#EDF5F8",
  "#E8F8CB",
  "#FFF5D2",
  "#FFEAD5",
  "#FFDEE3",
  "#F0DFFF",
];

const LunaSearchSuggestedPrompts = (props) => {
  const classes = useStyles();
  const containerRef = useRef(null);
  const { suggestedPrompts, selectedCategory, onHandleClick, selectedLayer } =
    props;

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [selectedLayer]);

  const filteredSuggestedPrompts =
    selectedCategory?.value === "All"
      ? suggestedPrompts
      : suggestedPrompts?.filter(
          (suggestedPrompt) =>
            parseInt(suggestedPrompt.category_id) ===
            parseInt(selectedCategory.id)
        );

  return (
    <Box className={classes.suggestedPromptsContainer} ref={containerRef}>
      <Box className={classes.suggestedPromptTitleContainer}>
        <Typography className={classes.title}>Suggested Prompts</Typography>
      </Box>
      <Box className={classes.suggestedPromptsButtonContainer}>
        {filteredSuggestedPrompts.map((suggestedPrompt, index) => (
          <Button
            key={index}
            className={classes.suggestedPromptButton}
            onClick={() =>
              onHandleClick({
                ...suggestedPrompt,
                iconColor: iconColors[index % iconColors.length],
                backgroundColor:
                  backgroundColors[index % backgroundColors.length],
              })
            }
            startIcon={
              <BadgeIcon
                iconType={suggestedPrompt.icon}
                color={iconColors[index % iconColors.length]}
                backgroundColor={
                  backgroundColors[index % backgroundColors.length]
                }
              />
            }
          >
            {suggestedPrompt.value}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default LunaSearchSuggestedPrompts;
