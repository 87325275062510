import { useQuery } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";
import { useParams } from "react-router-dom";

export const useFetchLocationActiveWorktickets = (
  queryString,
  tab,
  page = 1
) => {
  const { locationId } = useParams();

  const baseQueryString = queryString || `?limit=8&page=${page}`;
  const queryStringWithTab = baseQueryString.includes("?")
    ? `${baseQueryString}&filters[tab]=${tab}`
    : `${baseQueryString}?filters[tab]=${tab}`;

  return useQuery({
    queryKey: [
      "locationActiveWorktickets",
      locationId,
      queryStringWithTab,
      page,
    ],
    queryFn: () =>
      http.get(
        `${apiUrl}/locations/${locationId}/active-worktickets${queryStringWithTab}`
      ),
    enabled: !!locationId,
    keepPreviousData: true,
    initialData: {
      data: {
        data: {
          active_worktickets: [],
          pagination: {},
        },
      },
    },
    select: (data) => {
      const { active_worktickets, ...pagination } = data?.data?.data;
      return {
        cardData: active_worktickets,
        pagination: {
          currentPage: pagination?.current_page,
          itemsPerPage: pagination?.items_per_page,
          totalItems: pagination?.total_items,
          totalPages: pagination?.total_pages,
        },
        metaData: data?.data?.meta_data,
      };
    },
  });
};
