import { useState } from "react";
import { Box, Card, CardActionArea, Typography } from "@material-ui/core";
import { makeStyles, alpha } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  ClipboardDocumentCheckIcon,
  FlagIcon,
  ShieldExclamationIcon,
  CheckBadgeIcon,
  ExclamationTriangleIcon,
  DocumentPlusIcon,
} from "@heroicons/react/24/solid";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

const iconComponents = {
  clipboardDocumentCheck: ClipboardDocumentCheckIcon,
  flag: FlagIcon,
  shieldExclamation: ShieldExclamationIcon,
  checkBadge: CheckBadgeIcon,
  exclamationTriangle: ExclamationTriangleIcon,
  documentPlus: DocumentPlusIcon,
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: 400,
    backgroundColor: "#ffffff",
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
    paddingLeft: 8,
  },
  insightsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.75),
    marginTop: theme.spacing(1.5),
  },
  card: {
    backgroundColor: "#ffffff",
    borderRadius: 6,
    boxShadow: "none",
    border: `1px solid ${theme.palette.divider}`,
    "&.selected": {
      border: `1px solid #4F98BC`,
    },
  },
  cardAction: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "auto",
    height: 32,
  },
  iconContainer: {
    width: 20,
    height: 20,
    borderRadius: 4,
    marginRight: theme.spacing(0.75),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
  },
  insightName: {
    fontSize: 13,
    color: theme.palette.text.primary,
    textAlign: "left",
    lineHeight: 1.2,
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(4),
  },
  selectedInsightsSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectedInsightsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  selectedInsightsHeaderText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  clearAllButton: {
    color: "#ffffff",
    backgroundColor: theme.palette.secondary.main,
    textTransform: "none",
    padding: theme.spacing(0, 1.5),
    minWidth: "auto",
    borderRadius: 4,
    fontSize: 13,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  filterDivider: {
    borderTop: "solid 1px #ececec",
    margin: theme.spacing(2, 0),
  },
  selectedInsightsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.75),
  },
  closeIcon: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 16,
    marginLeft: theme.spacing(0.5),
    cursor: "pointer",
  },
}));

const InsightsFilter = ({
  insightsFilterOptions = [],
  loading = false,
  error = null,
  selectedInsights = [],
  onInsightSelect,
}) => {
  const classes = useStyles();
  console.log("=== insightsFilterOptions ===", insightsFilterOptions);

  const handleInsightToggle = (insight) => {
    const isSelected = selectedInsights.some(
      (selected) => selected.id === insight.id
    );
    if (isSelected) {
      onInsightSelect(
        selectedInsights.filter((selected) => selected.id !== insight.id)
      );
    } else {
      onInsightSelect([
        ...selectedInsights,
        { id: insight.id, label: insight.label },
      ]);
    }
  };

  const handleClearAll = () => {
    onInsightSelect([]);
  };

  if (loading) {
    return (
      <Box className={classes.root}>
        <Typography variant="h6" className={classes.title}>
          Filter by Insights Category
        </Typography>
        <Box className={classes.loadingContainer}>
          <CircularProgress size={24} />
        </Box>
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={classes.root}>
        <Typography variant="h6" className={classes.title}>
          Filter by Insights Category
        </Typography>
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Filter by Insights Category
      </Typography>

      {selectedInsights.length > 0 && (
        <Box className={classes.selectedInsightsSection}>
          <Box className={classes.selectedInsightsHeader}>
            <Typography
              className={classes.selectedInsightsHeaderText}
              variant="subtitle2"
            >
              Selected
            </Typography>
            <Button
              className={classes.clearAllButton}
              onClick={handleClearAll}
              size="small"
            >
              Clear All
            </Button>
          </Box>
          <Box className={classes.selectedInsightsContainer}>
            {selectedInsights.map((selectedInsight) => {
              const insight = insightsFilterOptions.find(
                (opt) => opt.id === selectedInsight.id
              );
              const IconComponent = iconComponents[insight?.icon];

              return (
                <Card key={insight?.id} className={`${classes.card} selected`}>
                  <CardActionArea
                    className={classes.cardAction}
                    onClick={() => handleInsightToggle(insight)}
                  >
                    <Box
                      className={classes.iconContainer}
                      style={{
                        color: insight?.icon_color,
                        backgroundColor: alpha(insight?.icon_color, 0.1),
                      }}
                    >
                      {IconComponent && (
                        <IconComponent style={{ width: 12, height: 16 }} />
                      )}
                    </Box>
                    <Typography className={classes.insightName}>
                      {insight?.label}
                    </Typography>
                    <CloseIcon
                      className={classes.closeIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleInsightToggle(insight);
                      }}
                    />
                  </CardActionArea>
                </Card>
              );
            })}
          </Box>
          <div className={classes.filterDivider} />
        </Box>
      )}

      <Box className={classes.insightsContainer}>
        {insightsFilterOptions
          .filter(
            (insight) => !selectedInsights.some((si) => si.id === insight.id)
          )
          .map((insight) => {
            const IconComponent = iconComponents[insight.icon];
            return (
              <Card key={insight.id} className={classes.card}>
                <CardActionArea
                  className={classes.cardAction}
                  onClick={() => handleInsightToggle(insight)}
                >
                  <Box
                    className={classes.iconContainer}
                    style={{
                      color: insight.icon_color,
                      backgroundColor: alpha(insight.icon_color, 0.1),
                    }}
                  >
                    {IconComponent && (
                      <IconComponent style={{ width: 12, height: 16 }} />
                    )}
                  </Box>
                  <Typography className={classes.insightName}>
                    {insight.label}
                  </Typography>
                </CardActionArea>
              </Card>
            );
          })}
      </Box>
    </Box>
  );
};

export default InsightsFilter;
