import { makeStyles, Box } from "@material-ui/core";
import { TradeChip } from "components/ui/Trades";

const useStyles = makeStyles({
  tradesContainer: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: 4,
    rowGap: 8,
  },
});

export const Trades = ({ trades }) => {
  const classes = useStyles();

  return (
    <Box className={classes.tradesContainer}>
      {trades.map((trade) => (
        <TradeChip key={trade.name} label={trade.name} icon={trade.icon} />
      ))}
    </Box>
  );
};
