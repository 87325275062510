import { MAP_CONFIG } from "./mapConfig";

export const GeoJsonTransformers = {
  // Generic transformation helper
  createFeature(coordinates, properties) {
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: coordinates.map(parseFloat),
      },
      properties,
    };
  },

  // Move existing transformers
  locationsAsGeoJSON(dashboardState) {
    return {
      type: "FeatureCollection",
      features: dashboardState.mapLocationList.data.jobs
        .map((location) =>
          this.createFeature([location.longitude, location.latitude], location)
        )
        .filter((feature) => this.hasValidCoordinates(feature)),
    };
  },

  partnersAsGeoJSON(dashboardState) {
    return {
      type: "FeatureCollection",
      features: dashboardState.mapPartnerList.data.partners
        .map((partner) =>
          this.createFeature([partner.longitude, partner.latitude], partner)
        )
        .filter((feature) => this.hasValidCoordinates(feature)),
    };
  },

  workticketsAsGeoJSON(worktickets, currentZoom = 4) {
    const baseRadius =
      MAP_CONFIG.COORDINATES.DESIRED_SPREAD_METERS /
      MAP_CONFIG.COORDINATES.METERS_PER_DEGREE;

    const maxRadius = Math.min(
      baseRadius * Math.sqrt(worktickets.length),
      baseRadius * MAP_CONFIG.COORDINATES.MAX_SPREAD_MULTIPLIER
    );

    return {
      type: "FeatureCollection",
      features: worktickets
        .map((ticket) => this.createWorkticketFeature(ticket, maxRadius))
        .filter((feature) => this.hasValidCoordinates(feature)),
    };
  },

  // Helper methods
  hasValidCoordinates(feature) {
    return feature.geometry.coordinates.every((coord) => !isNaN(coord));
  },

  deterministicRandomOffset(id, maxRadius) {
    const hash = id
      .toString()
      .split("")
      .reduce((acc, char) => {
        return char.charCodeAt(0) + ((acc << 5) - acc);
      }, 0);

    const angle = (hash % 360) * (Math.PI / 180);
    const r = maxRadius * (((hash >> 8) % 100) / 100);

    return [r * Math.cos(angle), r * Math.sin(angle)];
  },

  createWorkticketFeature(ticket, maxRadius) {
    const [offsetX, offsetY] = this.deterministicRandomOffset(
      ticket.id,
      maxRadius
    );

    const latitudeRadians = parseFloat(ticket.latitude) * (Math.PI / 180);
    const longitudeCorrection = Math.cos(latitudeRadians);

    const offsetLng =
      parseFloat(ticket.longitude) + offsetX / longitudeCorrection;
    const offsetLat = parseFloat(ticket.latitude) + offsetY;

    return this.createFeature([offsetLng, offsetLat], {
      ...ticket,
      workticket_type: parseInt(ticket.workticket_type) || 1,
      status: parseInt(ticket.badge_status) || 0,
    });
  },
};
