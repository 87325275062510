import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Avatar,
} from "@material-ui/core";
import { getUserType } from "./mapUtils";
import LocationOnIcon from "@material-ui/icons/LocationOn";
// import PhoneIcon from "@material-ui/icons/Phone";
import { PhoneIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { getLocationPartnerDetails } from "services/partnerService";
import { Skeleton } from "@material-ui/lab";
import MyLocationIcon from "@material-ui/icons/MyLocation";

const PartnerCard = ({ partner, onFlyTo, onView, onClose }) => {
  const { id } = partner;
  const [cardState, setCardState] = useState("normal");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const userType = getUserType(currentUser);
  const [partnerDetails, setPartnerDetails] = useState(null);
  const shouldShowLocation = userType === "internal";
  const shouldShowPhone = userType === "internal";

  const handleOnFlyTo = () => {
    onFlyTo();
    setCardState("selected");
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      width: 300,
      boxShadow: "0 4px 20px rgba(89, 89, 89, 0.25)",
      borderRadius: 8,
      position: "relative",
    },
    avatarContainer: {
      marginBottom: 8,
    },
    workticketIcon: {
      width: 24,
      height: 24,
      marginRight: 4,
    },
    avatar: {
      marginRight: 4,
      width: 20,
      height: 20,
      border: "2px solid white",
    },
    workticketNumber: {
      fontSize: 12,
      fontWeight: 600,
      color: theme.palette.text.main,
    },
    stackedAvatar: {
      marginLeft: -12, // Negative margin to overlap avatars
    },
    statusContainer: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      marginTop: 10,
    },
    workticketSubject: {
      fontSize: 14,
      fontWeight: 600,
      color: "#4D4D4D",
      lineHeight: "19px",
    },
    workticketDueDate: {
      height: 21,
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      justifyContent: "center",
      fontSize: 10,
      fontWeight: 600,
      color: theme.palette.primary.main,
      backgroundColor: "#F8F8F8",
      borderRadius: 4,
    },
    cardContent: {
      padding: 12,
    },
    customerName: {
      fontSize: 12,
      fontWeight: 400,
      marginBottom: 4,
    },

    partnerName: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1,
      color: "#4D4D4D",
    },
    partnerTitle: {
      fontSize: 12,
      fontWeight: 400,
    },
    nameContainer: {
      marginLeft: theme.spacing(1),
    },
    locationHeader: {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontWeight: 600,
      marginBottom: 10,
    },
    imageContainer: {
      position: "relative",
      height: "100%",
    },
    tradesContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
      marginTop: 10,
    },
    trade: {
      padding: "4px 8px",
      border: "1px solid #ECECEC",
      borderRadius: 50,
      fontSize: 12,
      lineHeight: 1.1,
    },
    divider: {
      margin: "10px 0",
    },
    actionButtons: {
      display: "flex",
      gap: 10,
      height: 32,
      marginTop: 10,
    },
    darkButton: {
      textTransform: "none",
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      borderColor: theme.palette.secondary.main,
      "&:hover": {
        transition: "0.2s ease-in",
        opacity: 0.8,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    lightButton: {
      textTransform: "none",
      color: "#4F98BC",
      borderColor: "#4F98BC",
      minWidth: 0,
      padding: 5,
    },
    bodyText: {
      fontSize: 12,
    },
    contactInfo: {
      display: "flex",
      alignItems: "center",
      gap: 4,
      marginTop: 4,
      color: theme.palette.text.secondary,
      fontSize: 12,
    },
    sectionHeader: {
      display: "inline",
      marginRight: 4,
      color: theme.palette.text.secondary,
      fontSize: 12,
      fontWeight: 600,
    },
    sectionText: {
      display: "inline",
      color: theme.palette.text.secondary,
      fontSize: 12,
    },
    icon: {
      width: 16,
      height: 16,
      color: "#D9D9D9",
    },
    skeleton: {
      backgroundColor: "#F8F8F8",
      opacity: 0.7,
    },
    skeletonText: {
      backgroundColor: "#F8F8F8",
      borderRadius: 4,
      opacity: 0.7,
    },
    disabledButton: {
      opacity: 0.5,
      pointerEvents: "none",
    },
    partnerEmail: {
      fontSize: 12,
      color: theme.palette.text.secondary,
      lineHeight: 1.2,
    },
    companyName: {
      fontSize: 14,
      fontWeight: 600,
      color: "#4D4D4D",
      marginBottom: 8,
    },
    closeButton: {
      position: "absolute",
      top: 12,
      right: 12,
      cursor: "pointer",
      width: 20,
      height: 20,
      color: "#747474",
      "&:hover": {
        color: "#4D4D4D",
      },
    },
  }));

  const fetchPartnerDetails = async () => {
    try {
      const response = await getLocationPartnerDetails(partner.id);
      console.log("=== partner details response", response);
      setPartnerDetails(response.data.data);
    } catch (error) {
      console.error("Error fetching partner details:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchPartnerDetails();
    }
  }, [id]);

  const hasValidAddress = (location) => {
    return (
      location &&
      (location.address || location.city || location.state || location.zip)
    );
  };

  const formatAddress = (location) => {
    return [location.address, location.city, location.state, location.zip]
      .filter(Boolean)
      .join(", ");
  };

  const classes = useStyles();

  if (!partnerDetails) {
    return (
      <Box>
        <Card className={classes.root}>
          <XMarkIcon className={classes.closeButton} onClick={onClose} />
          <CardContent className={classes.cardContent}>
            <Skeleton
              variant="text"
              width={140}
              className={classes.skeletonText}
              style={{ marginBottom: 8 }}
            />

            <Box display="flex" alignItems="center">
              <Skeleton
                variant="circle"
                width={20}
                height={20}
                className={classes.skeleton}
              />
              <Box
                display="flex"
                flexDirection="column"
                className={classes.nameContainer}
              >
                <Skeleton
                  variant="text"
                  width={120}
                  className={classes.skeletonText}
                />
                <Skeleton
                  variant="text"
                  width={80}
                  className={classes.skeletonText}
                />
              </Box>
            </Box>

            {shouldShowLocation && (
              <Box className={classes.contactInfo}>
                <Skeleton
                  variant="rect"
                  width={16}
                  height={16}
                  className={classes.skeleton}
                />
                <Skeleton
                  variant="text"
                  width="80%"
                  className={classes.skeletonText}
                />
              </Box>
            )}

            {shouldShowPhone && (
              <Box className={classes.contactInfo}>
                <Skeleton
                  variant="rect"
                  width={16}
                  height={16}
                  className={classes.skeleton}
                />
                <Skeleton
                  variant="text"
                  width="60%"
                  className={classes.skeletonText}
                />
              </Box>
            )}

            <Box className={classes.tradesContainer}>
              <Skeleton
                variant="rect"
                width={80}
                height={26}
                className={classes.skeleton}
                style={{ borderRadius: 50 }}
              />
              <Skeleton
                variant="rect"
                width={100}
                height={26}
                className={classes.skeleton}
                style={{ borderRadius: 50 }}
              />
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.actionButtons}
            >
              <Button
                variant="outlined"
                className={`${classes.lightButton} ${classes.disabledButton}`}
                fullWidth
                disabled
              >
                Zoom In
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box>
      <Card className={classes.root}>
        <XMarkIcon className={classes.closeButton} onClick={onClose} />
        <CardContent className={classes.cardContent}>
          {partnerDetails.company && (
            <Typography className={classes.companyName}>
              {partnerDetails.company}
            </Typography>
          )}

          <Box
            className={classes.avatarContainer}
            display="flex"
            alignItems="center"
          >
            <Avatar
              style={{ width: 28, height: 28 }}
              key={partnerDetails.name}
              src={partnerDetails.avatar}
              alt={partnerDetails.name}
            />
            <Box
              display="flex"
              flexDirection="column"
              className={classes.nameContainer}
            >
              <Typography className={classes.partnerName}>
                {partnerDetails.name}
              </Typography>
              <Typography className={classes.partnerEmail}>
                {partnerDetails.email}
              </Typography>
              <Typography className={classes.partnerTitle}>
                {partnerDetails.title}
              </Typography>
            </Box>
          </Box>

          {shouldShowLocation && hasValidAddress(partnerDetails.location) && (
            <Box className={classes.contactInfo}>
              <MyLocationIcon className={classes.icon} />
              <Box component="span">
                <Typography component="span" className={classes.sectionHeader}>
                  Current Location:{" "}
                </Typography>
                <Typography component="span" className={classes.sectionText}>
                  {formatAddress(partnerDetails.location)}
                </Typography>
              </Box>
            </Box>
          )}

          {shouldShowPhone && partnerDetails.phone && (
            <Box className={classes.contactInfo}>
              <PhoneIcon className={classes.icon} />
              <Box component="span">
                <Typography component="span" className={classes.sectionHeader}>
                  Phone:{" "}
                </Typography>
                <Typography component="span" className={classes.sectionText}>
                  {partnerDetails.phone}
                </Typography>
              </Box>
            </Box>
          )}

          <Box className={classes.tradesContainer}>
            {partnerDetails.trades.map((trade) => (
              <Typography
                key={typeof trade === "object" ? trade.id : trade}
                className={classes.trade}
              >
                {typeof trade === "object" ? trade.name : trade}
              </Typography>
            ))}
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            className={classes.actionButtons}
          >
            {cardState === "normal" && (
              <>
                <Button
                  variant="outlined"
                  onClick={onFlyTo}
                  className={classes.lightButton}
                  fullWidth
                >
                  Zoom In
                </Button>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PartnerCard;
