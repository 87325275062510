import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    paddingTop: 8,
    flexGrow: 1,
    height: "100%",
    overflowY: "scroll",
  },
  leaderboardRoot: {
    padding: 20,
    paddingTop: 8,
    flexGrow: 1,
    height: "100%",
  },
  wrapper: {
    position: "relative",
    marginBottom: theme.spacing(2),
    "& > div": {
      paddingRight: theme.spacing(2),
    },
    "& > div:last-child": {
      paddingRight: 0,
    },
  },
  chartContainerNoMB: {
    position: "relative",
    backgroundColor: "#F9F9F9",
    borderRadius: 4,
    overflow: "hidden",
    padding: 20,
    height: "100%",
    "& .recharts-wrapper": {
      margin: "auto",
    },
  },
  chartContainer: {
    position: "relative",
    backgroundColor: "#F9F9F9",
    borderRadius: 4,
    overflow: "hidden",
    padding: 20,
    height: "100%",
    marginBottom: 8,
    "& .recharts-wrapper": {
      margin: "auto",
    },
  },
  chartContainerVariant: {
    position: "relative",
    backgroundColor: "#F9F9F9",
    overflow: "hidden",
    padding: 20,
    minHeight: 365,
    height: "100%",
    marginBottom: 8,
    "& .recharts-wrapper": {
      margin: "auto",
    },
  },
  chartContainerInnerVariant: {
    display: "flex",
    justifyContent: "space-between",
  },
  chartWrapper: {
    overflow: "hidden",
    padding: 4,
  },
  chartWrapperTalent: {
    paddingTop: 80,
    overflow: "hidden",
    padding: 4,
  },
  chartTitle: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  chartTitle2: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  chartTvTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  chartTitleWithMenu: {
    marginBottom: theme.spacing(3),
    position: "relative",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  tableTitleLine: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderBottom: "2px solid #DFDFDF",
    textTransform: "capitalize",
  },
  chartTitleLine: {
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    borderBottom: "2px solid #DFDFDF",
  },
  chartSubtitleValue: {
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    borderBottom: "2px solid #DFDFDF",
    textAlign: "center",
    paddingLeft: 60,
  },
  chartSubtitleValueFlat: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
    paddingLeft: 60,
  },
  chartSubtitleValueLarge: {
    marginBottom: theme.spacing(3),
    paddingBottom: 18,
    borderBottom: "2px solid #DFDFDF",
    textAlign: "center",
    paddingLeft: 60,
    fontSize: 30,
  },
  tabsContainer: {
    marginBottom: theme.spacing(3),
    borderBottom: "1px solid #d4d4d4",
  },
  tabItem: {
    marginRight: 0,
    minWidth: theme.spacing(10),
    maxWidth: theme.spacing(20),
    "&.Mui-selected": {
      color: "#4f98bc",
      fontWeight: "bold",
      borderBottom: "2px solid #4f98bc",
    },
  },
  tabItemLabel: {
    textTransform: "capitalize",
    minWidth: 100,
    fontSize: 14,
    fontWeight: "bold",
  },
  tabsWithChildren: {
    "& > div:first-child": {
      marginBottom: 4,
    },
  },
  widgetContainer: {
    position: "relative",
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    backgroundColor: "#F9F9F9",
    padding: 20,
    height: "100%",
  },
  widgetContainerTotals: {
    position: "relative",
    backgroundColor: "#FFFFFF",
    height: "100%",
  },
  widgetContainerInner: {
    paddingBottom: theme.spacing(11),
    backgroundColor: "#F9F9F9",
    padding: 20,
    height: "100%",
  },
  widgetContainerSlim: {
    backgroundColor: "#F9F9F9",
    padding: 20,
    height: "100%",
  },
  linkLabel: {
    marginLeft: theme.spacing(3),
    color: "#4f98bc",
  },
  linkLabelBlock: {
    display: "block",
    color: "#4f98bc",
    fontSize: 16,
    marginTop: 8,
    textDecoration: "none",
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 10,
    marginRight: 10,
    fontWeight: "bold",
  },
  buttonContainer: {
    position: "relative",
  },
  buttonPrimary: {
    color: "#4f98bc",
    background: "#ffffff",
    fontSize: 12,
    position: "absolute",
    right: 0,
    marginTop: -70,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  buttonMenuContainer: {
    marginTop: -12,
    marginRight: -16,
  },
  buttonXlsContainer: {
    position: "absolute",
    right: 30,
    marginTop: -70,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  buttonTableXlsContainer: {
    position: "absolute",
    right: 20,
    marginTop: -55,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  buttonPrimaryTable: {
    color: "#4f98bc",
    background: "#ffffff",
    fontSize: 12,
    height: 22,
    whiteSpace: "nowrap",
    "& svg": {
      fontSize: 14,
      marginLeft: 3,
    },
  },
  buttonSecondary: {
    backgroundColor: "#4f98bc",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  tableContainerReport: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
    width: "100%",
    maxHeight: 400,
  },
  tableContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
    width: "100%",
    maxHeight: 400,
    marginBottom: -50,
  },
  rootContainer: {
    display: "flex",
    flexGrow: 1,
  },
  fullHeightTable: {
    flex: "1 1",
    display: "flex",
    maxHeight: "92%",
    maxWidth: "100%",
    overflowX: "hidden",
    "& table": {
      minWidth: 1200,
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "98%",
    },
  },
  wrapperName: {
    paddingLeft: theme.spacing(4),
    position: "relative",
    minWidth: 140,
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 12,
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    left: 0,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  popover: {
    pointerEvents: "none",
  },
  filterContainer: {
    "& > div": {
      width: "100%",
      padding: theme.spacing(1),
    },
    "& button": {
      width: "100%",
      border: "1px solid #9B9B9B",
      height: 40,
    },
  },
  filterContainerTable: {
    "& > div": {
      width: "100%",
      paddingRight: theme.spacing(1),
    },
    "& button": {
      width: "100%",
      minWidth: 160,
      border: "1px solid #4f97bb",
      textTransform: "capitalize",
      color: "#4f97bb",
      top: -5,
    },
  },
  btnBreakdownView: {
    border: "1px solid #4f97bb !important",
    textTransform: "capitalize",
    top: 10,
    marginLeft: 8,
    padding: "4px 8px",
    fontWeight: "bold",
    maxWidth: 160,
  },
  actionsTvMode: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    "& button": {
      border: "1px solid #9B9B9B",
      marginLeft: 8,
      textTransform: "none",
      [theme.breakpoints.down("md")]: {
        paddingTop: theme.spacing(1),
      },
    },
  },
  filterBreakdownContainer: {
    paddingTop: 8,
    paddingLeft: 32,
    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
      paddingLeft: 0,
    },
    "& button": {
      textTransform: "capitalize",
      fontWeight: "bold",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  },
  chartTypeDisplay: {
    position: "absolute",
    right: 20,
    top: 20,
    "& button": {
      textTransform: "capitalize",
    },
    [theme.breakpoints.down("md")]: {
      position: "relative",
      marginBottom: theme.spacing(1),
      right: 0,
      top: 0,
    },
  },
  chartTypeDisplayWrapper: {
    marginLeft: 8,
    height: 35,
    "& button": {
      textTransform: "capitalize",
    },
  },
  chartContainerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  chartTypeButton: {
    backgroundColor: "#ffffff",
  },
  chartTypeDisplay2: {
    position: "absolute",
    right: 320,
    top: 20,
    "& button": {
      textTransform: "capitalize",
    },
    [theme.breakpoints.down("md")]: {
      position: "relative",
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(2),
      right: 0,
      left: 0,
      top: 0,
    },
  },
  legendChart: {
    position: "relative",
    marginBottom: theme.spacing(3),
    right: 10,
    left: 0,
    top: 0,
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(1),
    },
  },
  legendChartItem: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(0.5),
    borderRadius: "50%",
  },
  legendChartItem2: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(0.5),
    borderRadius: "50%",
    border: "1px solid #979797",
  },
  tooltipContainerNew: {
    width: 320,
    height: 160,
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    border: "none !important",
  },
  tooltipContainerTypes: {
    width: 260,
    height: 100,
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    border: "none !important",
  },
  tooltipContainerWT: {
    width: 260,
    height: 160,
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    border: "none !important",
  },
  tooltipContainerWTCompleted: {
    width: 320,
    height: 128,
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    border: "none !important",
  },
  tooltipTittleContainer: {
    backgroundColor: "#ECECEC",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tooltipTittleNew: {
    fontSize: 14,
    fontWeight: "bold",
  },
  tooltipContentTotalsContainer: {
    backgroundColor: "#FFFFFF",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tooltipContentTotalsContainerBox: {
    backgroundColor: "#FFFFFF",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "& > div": {
      marginBottom: 12,
    },
  },
  tooltipContentItem: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(1),
    borderRadius: "50%",
  },
  tooltipContentItem2: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(1),
    borderRadius: "50%",
    border: "1px solid #979797",
  },
  tooltipContentText: {
    fontSize: 14,
    display: "inline",
  },
  tooltipContentText2: {
    fontSize: 14,
    maxWidth: 150,
  },
  tooltipContentTextBold: {
    fontSize: 14,
    display: "inline",
    fontWeight: "bold",
  },
  tooltipContentTextBold2: {
    fontSize: 14,
    fontWeight: "bold",
    maxWidth: 250,
  },
  tooltipContentTextBoldBlue: {
    fontSize: 14,
    display: "inline",
    fontWeight: "bold",
    color: "#17457A !important",
  },
  tooltipContentTextBoldGreen: {
    fontSize: 14,
    display: "inline",
    fontWeight: "bold",
    color: "#9CBB65 !important",
  },
  tooltipContentTextBoldRed: {
    fontSize: 14,
    display: "inline",
    fontWeight: "bold",
    color: "#F07A8C !important",
  },
  tooltipContentDetailsContainer: {
    backgroundColor: "#F8F8F8",
    paddingRight: theme.spacing(2),
    paddingLeft: 36,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tooltipContentBadge: {
    fontSize: 14,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 4,
    color: "#ffffff !important",
  },
  tooltipContentBadge2: {
    fontSize: 14,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 4,
    color: "#ffffff !important",
    fontWeight: "bold",
  },
  tooltipContainerBox: {
    width: 300,
    height: 150,
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    border: "1px solid #ECECEC !important",
    zIndex: 9999,
  },
  filterDisplayContainer: {
    textAlign: "right",
  },
  chartNavigatorSearchBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  chartNavigatorBox: {
    textAlign: "right",
    marginBottom: theme.spacing(1),
  },
  chartFeatureBox: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  chartNavigatorGrid: {
    textAlign: "right",
  },
  chartNavigator: {
    display: "inline-block",
    marginLeft: theme.spacing(2),
  },
  chartNavigatorCounter: {
    display: "inline-block",
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(1),
    fontWeight: "bold",
    fontSize: 14,
  },
  chartNavigatorCounterEnd: {
    marginLeft: "auto",
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(1),
    fontWeight: "bold",
    fontSize: 14,
  },
  tooltipContainer: {
    width: 220,
    height: 100,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    border: "1px solid #d4d4d4",
  },
  tooltipContainerExtended: {
    width: 220,
    height: 200,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    border: "1px solid #d4d4d4",
  },
  tooltipAvgContainer: {
    width: 180,
    height: 50,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    paddingTop: 15,
    border: "1px solid #d4d4d4",
    "& span": {
      marginRight: 30,
    },
  },
  tooltipCaption: {
    color: "#ffffff",
  },
  tooltipTitle: {
    color: "#ffffff",
  },
  tooltipRow: {
    textTransform: "capitalize",
  },
  emptyGraph: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 210,
    "& svg": {
      fontSize: 80,
    },
  },
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  iconDialog: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconSuccessDialog: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconsDialogClose: {
    color: "#979797",
    width: 20,
    height: 20,
  },
  titleDialog: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  contentDialog: {
    fontSize: 14,
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  buttonDialog: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    marginLeft: 10,
    fontWeight: "bold",
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  buttonOutlinedDialog: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    color: "#4f98bc",
    marginRight: 40,
  },
  formContainerDialog: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "left",
  },
  chartUpdateDate: {
    fontSize: 14,
    display: "inline",
  },
  chartLegendText: {
    fontSize: 14,
    display: "inline",
    marginRight: 10,
  },
  pieAdj: {
    display: "flex",
    minHeight: 376,
  },
  avgTimeToFill: {
    height: "100%",
    paddingBottom: theme.spacing(7),
    backgroundColor: "#F9F9F9",
    padding: 20,
  },
  avgTimeToFillLabel: {
    fontSize: 20,
    fontWeight: "bold",
  },
  dateTextAdj: {
    position: "absolute",
    left: 21,
    top: 48,
    fontSize: 14,
    display: "inline",
  },
  filterChip: {
    fontSize: 12,
    marginLeft: 10,
    marginBottom: 3,
  },
  modalBreakdown: {
    backgroundColor: "#f8f8f8",
  },
  surveyBreakdownContainer: {
    maxWidth: 800,
    margin: "0 auto",
    border: "1px solid #f8f8f8",
    padding: 16,
    minHeight: "90vh",
    backgroundColor: "#ffffff",
  },
  surveyHeader: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 24,
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      "& > svg": {
        left: -5,
        position: "relative",
      },
    },
  },
  surveyHeaderDot: {
    width: 20,
    height: 20,
    background: "#4F98BC",
    borderRadius: 5,
    top: -5,
    position: "relative",
  },
  surveyTitle: {
    fontSize: 18,
    paddingBottom: 12,
    color: "#747474",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      paddingLeft: 5,
      marginBottom: theme.spacing(1),
      width: "80%",
    },
  },
  surveyHeaderTitle: {
    marginLeft: 18,
    borderBottom: "1px solid #DFDFDF",
    flex: 1,
  },
  surveyScore: {
    paddingLeft: 40,
    marginBottom: 40,
  },
  surveyTitleScore: {
    fontWeight: "bold",
    fontSize: 56,
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
      marginBottom: theme.spacing(1),
    },
  },
  surveyTitleQuestion: {
    fontSize: 16,
    color: "#17457A",
  },
  questionScoreBar: {
    borderRadius: 3,
  },
  tableOptionBtn: {
    maxWidth: 40,
  },
  chartTvWrapper: {
    paddingLeft: 16,
  },
  chartTvTitleWrapper: {
    "& h4": {
      display: "flex",
      alignItems: "center",
    },
    "& h4 img": {
      marginRight: 20,
    },
    "& h4 svg": {
      marginLeft: 8,
    },
  },
  viewsContainer: {
    maxWidth: 400,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& > button": {
      flex: 1,
      marginLeft: 8,
      [theme.breakpoints.down("sm")]: {
        fontSize: 9,
      },
    },
    "& > div > div": {
      marginBottom: 0,
    },
  },
  viewsPaper: {
    width: 240,
  },
  titleViewsPaper: {
    "& span": {
      fontSize: 14,
    },
    "& + div svg": {
      fontSize: 18,
    },
    "& + div": {
      display: "none",
    },
    "&:hover": {
      cursor: "pointer",
      color: "#4f98bc",
    },
    "& + div:hover, &:hover + div, &:hover + div:hover": {
      display: "block",
    },
  },
  viewsField: {
    width: 240,
  },
  viewButtonContainer: {
    width: "100%",
    "& > div": {
      width: "100%",
      padding: theme.spacing(1),
    },
    "& button": {
      width: "100%",
      border: "1px solid #9B9B9B",
      textTransform: "capitalize",
      paddingTop: 8,
    },
    "& span": {
      justifyContent: "space-between",
    },
  },
  viewBtnSelected: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  kpiDisplayContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginTop: 8,
      paddingLeft: 8,
    },
  },
  kpiViewDisplayContainer: {
    maxWidth: 400,
  },
  kpiFilterDisplayContainer: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  optionsFilterContainer: {
    display: "flex",
    flexWrap: "wrap",
    "& > div": {
      width: "28%",
      [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    },
    "& > div:last-child": {
      width: "14%",
      [theme.breakpoints.down("sm")]: {
        width: "48%",
      },
    },
  },
  filterCheckbox: {
    color: "#ececec",
    border: "none",
    padding: 0,
    paddingRight: 5,
    "& + .MuiFormControlLabel-label": {
      fontSize: 14,
    },
  },
  wrapperNewViewOpt: {
    textAlign: "left",
    paddingLeft: 8,
    marginBottom: 8,
  },
  infoAlert: {
    border: "none",
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      fontSize: 11,
    },
  },
  infoAlertSm: {
    border: "none",
    fontSize: 13,
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      fontSize: 11,
    },
  },
  tableHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
    borderBottom: "2px solid #DFDFDF",
    alignItems: "center",
    marginBottom: 16,
  },
  tableHeaderTitleLine: {
    textTransform: "capitalize",
  },
  subjectContainerLink: {
    maxWidth: 160,
    "& button": {
      padding: 0,
      fontSize: 12,
      width: 60,
      textTransform: "capitalize",
      marginLeft: 3,
      visibility: "hidden",
    },
    "&:hover": {
      "& button": {
        visibility: "visible",
      },
    },
  },
  settingContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  settingInnerContainer: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  fullHeightTableLeaderboard: {
    overflowY: "auto",
    height: "100%",
    paddingBottom: 80,
    "& > div": {
      maxHeight: "90% !important",
    },
    "& .table-row-0, & .table-row-0 > td:first-child, & .table-row-0 > td:nth-child(2)":
      {
        backgroundColor: "#EDF5F8",
      },
    "& .table-row-0 > td:nth-child(2)": {
      fontWeight: 600,
      borderRight: "solid 1px #D1D1D1",
    },
    "& .table-row-1, & .table-row-1 > td:first-child, & .table-row-1 > td:nth-child(2)":
      {
        backgroundColor: "#F9F9F9",
      },
    "& .table-row-1 > td:nth-child(2)": {
      borderRight: "solid 1px #D1D1D1",
    },
    "& .table-row-2, & .table-row-2 > td:first-child, & .table-row-2 > td:nth-child(2)":
      {
        backgroundColor: "#F5F5F5",
      },
    "& .table-row-2 > td:nth-child(2)": {
      borderRight: "solid 1px #D1D1D1",
    },
    "& .table-row-3, & .table-row-3 > td:first-child, & .table-row-3 > td:nth-child(2)":
      {
        backgroundColor: "#FFFFFF",
      },
    "& .table-row-3 > td:nth-child(2)": {
      borderRight: "solid 1px #D1D1D1",
    },
    "& .table-row-4, & .table-row-4 > td:first-child, & .table-row-4 > td:nth-child(2)":
      {
        backgroundColor: "#FFFFFF",
      },
    "& .table-row-4 > td:nth-child(2)": {
      borderRight: "solid 1px #D1D1D1",
    },
  },
  dateRangeContainer: {
    backgroundColor: "#EDF5F8",
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 14,
    display: "flex",
    borderRadius: 4,
    justifyContent: "center",
    lineHeight: 1.5,
    "& p": {
      color: "#4f98bc",
      marginRight: 4,
      fontSize: 14,
    },
  },
  centerLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  chartHeaderDetails: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexWrap: "wrap",
    columnGap: 32,
    rowGap: 8,
  },
  chartHeaderItem: {
    display: "flex",
    alignItems: "center",
  },
  chartHeaderBullet: {
    width: 12,
    height: 12,
    borderRadius: "50%",
  },
  chartHeaderLabel: {
    fontSize: 14,
    marginLeft: 8,
  },
  chartHeaderNumber: {
    fontSize: 18,
    fontWeight: "bold",
    marginLeft: 8,
  },
  chartHeaderPercent: {
    fontSize: 14,
    marginLeft: 8,
    color: "#ffffff",
    borderRadius: 4,
    padding: "2px 8px",
  },
  widgetContainerWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
  widgetContainerItem: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: "#F8F8F8",
    borderRadius: 4,
    display: "flex",
    "& > div": {
      width: "50%",
      paddingLeft: theme.spacing(3),
      minHeight: 100,
      borderRight: "1px solid #D8D8D8",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    "& > div:last-child": {
      borderRight: "none",
    },
  },
  money: {
    textAlign: "right",
    marginRight: 8,
    color: "#9CBB65 !important",
    minWidth: 60,
  },
  moneyRed: {
    textAlign: "right",
    marginRight: 8,
    color: "#F07A8C !important",
    minWidth: 60,
  },
  moneyImportant: {
    textAlign: "right",
    marginRight: 8,
    color: "#9CBB65 !important",
    fontWeight: "bold",
    minWidth: 60,
  },
  moneyImportantRed: {
    textAlign: "right",
    marginRight: 8,
    color: "#F07A8C !important",
    fontWeight: "bold",
    minWidth: 60,
  },
  goodIcon: {
    color: "#9CBB65",
    width: 20,
  },
  badIcon: {
    color: "#F07A8C",
    width: 20,
  },
  cellContentContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: 16,
    alignItems: "center",
  },
  tooltipContentValueContainer: {
    paddingBottom: 4,
  },
  tooltipContentValueContainer2: {
    paddingBottom: 8,
    borderBottom: "1px solid #ECECEC",
  },
  tooltipContentValueContainer3: {
    paddingBottom: 4,
    paddingTop: 8,
  },
}));

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default useStyles;
