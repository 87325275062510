export const getUserType = (u) => {
  if (["client"].includes(u?.tier_classification)) return "customer";
  if (
    ["subcontractor_crew", "subcontractor_admin", "subcontractor"].includes(
      u?.tier_classification
    )
  )
    return "partner";
  return "internal";
};

export const initialVisibleLayersByUser = (u) => {
  const userType = getUserType(u);
  switch (userType) {
    case "internal":
      return ["LOCATIONS", "WORKTICKETS", "PARTNERS"];
    case "customer":
      return ["LOCATIONS", "WORKTICKETS"];
    case "partner":
      return ["WORKTICKETS"];
    default:
      return ["WORKTICKETS"];
  }
};
