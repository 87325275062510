import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  categoryButton: {
    color: "#747474",
    fontSize: "12px",
    fontWeight: 400,
    textTransform: "none",
    backgroundColor: "#FFFFFF",
    border: "1px solid #ECECEC",
    borderRadius: "4px",
    padding: "2px 12px 2px 12px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
  },
  suggestedPromptBadge: {
    display: "flex",
    flexShrink: 0,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    padding: "4px",
    width: "24px",
    height: "24px",
  },
  suggestedPromptButton: {
    textAlign: "left",
    whiteSpace: "normal",
    wordWrap: "break-word",
    color: "#747474",
    fontSize: "12px",
    fontWeight: 400,
    textTransform: "none",
    backgroundColor: "#FFFFFF",
    justifyContent: "flex-start",
    borderRadius: "4px",
    padding: "8px 20px 8px 18px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
  },
  categoryButtonSelected: {
    color: "#747474",
    fontSize: "12px",
    fontWeight: 400,
    textTransform: "none",
    backgroundColor: "#FFFFFF",
    border: "1px solid #4F98BC",
    borderRadius: "4px",
    padding: "2px 12px 2px 12px",
    minWidth: "0",
    "& .MuiButton-label": {
      padding: "0",
    },
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
  },
  categoriesButtonContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    marginBottom: "20px",
  },
  suggestedPromptsButtonContainer: {
    display: "flex",
    flexDirection: "column",
  },
  suggestedActionButton: {
    color: "#747474",
    fontSize: "12px",
    fontWeight: 400,
    textTransform: "none",
    backgroundColor: "#FFFFFF",
    justifyContent: "flex-start",
    borderRadius: "4px",
    padding: "8px 20px 8px 18px",
    minWidth: "0",
    width: "auto",
    display: "inline-flex",
    "& .MuiButton-label": {
      padding: "0",
    },
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
  },
  categoriesContainer: {
    display: "flex",
    padding: "18px",
    flexDirection: "column",
  },
  categoriesContainerZero: {
    padding: "0px 18px 18px 18px",
  },
  categoriesContainerLocationZero: {
    padding: "0px 18px 0px 18px",
  },
  categoriesContainerLocations: {
    "&::-webkit-scrollbar": {
      width: "0.7em",
      background: "#F8F8F8",
      borderRadius: 3,
      boxShadow: "inset 0 0 2px #ECECEC",
    },
    "&::-webkit-scrollbar:horizontal": {
      height: "0.7em",
      background: "#F8F8F8",
      borderRadius: 3,
      boxShadow: "inset 0 0 2px #ECECEC",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D9D9D9",
      borderRadius: 5,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#747474",
    },
    [theme.breakpoints.down("xs")]: {
      height: "153px",
      overflowY: "auto",
      paddingBottom: "0px",
    },
  },
  suggestedPromptsContainer: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    flexGrow: 1,
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.7em",
      background: "#F8F8F8",
      borderRadius: 3,
      boxShadow: "inset 0 0 2px #ECECEC",
    },
    "&::-webkit-scrollbar:horizontal": {
      height: "0.7em",
      background: "#F8F8F8",
      borderRadius: 3,
      boxShadow: "inset 0 0 2px #ECECEC",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D9D9D9",
      borderRadius: 5,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#747474",
    },
  },
  suggestedActionTitleContainer: {
    padding: "18px 18px 0px 18px",
  },
  suggestedPromptTitleContainer: {
    padding: "0px 18px",
  },
  suggestedPromptTitleActionContainer: {
    padding: "18px 18px 0px 18px",
  },
  selectedSuggestedPromptContainer: {
    padding: "0px 18px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  selectedSuggestedPrompt: {
    color: "#747474",
    fontSize: "12px",
    fontWeight: 400,
  },
  inputParameterText: {
    color: "747474",
    fontSize: "16px",
    fontWeight: 400,
  },
  title: {
    fontWeight: "600",
    color: "#D9D9D9",
    fontSize: "12px",
    marginBottom: "12px",
  },
  titleActions: {
    fontWeight: "600",
    color: "#D9D9D9",
    fontSize: "12px",
    marginBottom: "12px",
    marginTop: "18px",
  },
  suggestedActionTypography: {
    textAlign: "left",
    fontSize: 12,
    fontWeight: 400,
    color: "#747474",
    lineHeight: "24px",
  },
  suggestedActionPlaceholder: {
    color: "#747474",
    padding: "2px 5px",
    marginLeft: "3px",
    marginRight: "5px",
    border: "1px solid #ECECEC",
    backgroundColor: "#FFFFFF",
    borderRadius: "4px",
    fontSize: "12px",
    fontWeight: 400,
    whiteSpace: "nowrap",
  },
  inputParameterInput: {
    flexGrow: 1,
    color: "#747474",
    fontSize: "16px",
    fontWeight: 400,
    border: "none",
    "& div:first-child": {
      marginRight: 0,
    },
    "& .Mui-focused": {
      border: "none",
    },
    "& > input": {
      padding: "0px 3px",
    },
    "& > fieldset": {
      border: "none",
    },
  },
  customInputPadding: {
    padding: "0px 0px",
  },
  overflowPrompt: {
    padding: "8px 6px 4px 8px",
  },
  noOverflowPrompt: {
    padding: "0px 0px 1px 8px",
  },
  overflowLunaIcon: {
    padding: "6px 0px 0px 5px",
  },
  noOverflowLunaIcon: {
    padding: "6px 0px 1px 5px",
  },
  inputParameterContainer: {
    whiteSpace: "nowrap",
    display: "flex",
    width: "100%",
    alignItems: "center",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      width: "0.3em",
      background: "white",
      borderRadius: 3,
    },
    "&::-webkit-scrollbar:horizontal": {
      height: "0.3em",
      background: "white",
      borderRadius: 3,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D9D9D9",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#747474",
    },
    [theme.breakpoints.down(1501)]: {
      maxWidth: "500px",
    },
    [theme.breakpoints.down(1301)]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down(1101)]: {
      width: "100%",
      maxWidth: "100%",
      marginBottom: "10px",
      clear: "both",
      marginTop: "10px",
      zIndex: 100,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: "1px",
      height: "42px",
    },
  },
  searchIcon: {
    color: "#ececec",
  },
  parameterContainer: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
    maxWidth: "700px",
    height: "42px",
    backgroundColor: "white",
    borderRadius: 4,
    paddingLeft: "2px",
    alignItems: "center",
    border: "solid 1px #6EC4EB",
    [theme.breakpoints.down(1501)]: {
      maxWidth: "500px",
    },
    [theme.breakpoints.down(1301)]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down(1101)]: {
      width: "100%",
      maxWidth: "100%",
      marginBottom: "10px",
      clear: "both",
      marginTop: "10px",
      zIndex: 100,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: "1px",
      height: "42px",
    },
  },
  locationParameterContainer: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
    height: "42px",
    backgroundColor: "white",
    borderRadius: 4,
    paddingLeft: "2px",
    alignItems: "center",
    border: "solid 1px #6EC4EB",
  },
  popperSearch: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "1px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 20px 0px rgba(173, 173, 173, 0.16)",
    borderRadius: "4px",
    height: "580px",
    zIndex: 5,
    [theme.breakpoints.down(1501)]: {
      height: "500px",
    },
    [theme.breakpoints.down(1301)]: {
      height: "450px",
    },
    [theme.breakpoints.down(1101)]: {
      height: "430px",
    },
    [theme.breakpoints.down(850)]: {
      height: "450px",
    },
    [theme.breakpoints.down(431)]: {
      height: "425px",
    },
    "@media (max-height: 800px)": {
      height: "400px",
    },
    "@media (max-height: 600px)": {
      height: "320px",
    },
  },
  popperSearchLocation: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "1px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 20px 0px rgba(173, 173, 173, 0.16)",
    borderRadius: "4px",
    height: "580px",
    zIndex: 5,
    [theme.breakpoints.down(1501)]: {
      height: "500px",
    },
    [theme.breakpoints.down(1301)]: {
      height: "450px",
    },
    [theme.breakpoints.down(1101)]: {
      height: "430px",
    },
    [theme.breakpoints.down(850)]: {
      height: "450px",
    },
    [theme.breakpoints.down(431)]: {
      height: "425px",
    },
    "@media (max-height: 800px)": {
      height: "450px",
    },
    "@media (max-height: 600px)": {
      height: "350px",
    },
  },
}));

export default useStyles;
