import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Typography, Box, Button } from "@material-ui/core";
import SearchableFilter from "pages/dashboard/map/searchableFilter";
import {
  useLocationDetailsContext,
  useLocationDetailsDispatch,
} from "pages/locationDetails/context";
import GlobalUiContext from "contexts/globalUiContext";
import {
  formatStringAllCapsWithUnderscores,
  formatStringReadable,
} from "pages/locationDetails/utils";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 360,
  },
  header: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerText: {
    fontSize: 16,
    fontWeight: 700,
    color: "#4D4D4D",
  },
  content: {
    padding: theme.spacing(2),
    "& .MuiAccordion-root": {
      boxShadow: "none",
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.grey[50],
      "&::before": {
        display: "none",
      },
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.common.white,
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  filterContainer: {
    marginBottom: theme.spacing(1),
  },
  secondaryButton: {
    color: theme.palette.text.secondary,
    minWidth: "auto",
    padding: theme.spacing(0.5, 1),
    height: 24,
    textTransform: "none",
  },
  applyButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    minWidth: "auto",
    padding: theme.spacing(0.5, 2),
    height: 24,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const LocationDetailsFilterDrawer = ({ section }) => {
  const classes = useStyles();
  const {
    filters: {
      data: { filters },
    },
    activeDrawerSection,
    selectedFilterItems,
  } = useLocationDetailsContext();
  const dispatch = useLocationDetailsDispatch();
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const sortedUsers = users.sort((a, b) =>
    a.first_name.localeCompare(b.first_name)
  );
  const currentSection = formatStringAllCapsWithUnderscores(section);

  const [selectedItems, setSelectedItems] = useState({});

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      const initialSelections =
        selectedFilterItems[currentSection] ||
        Object.keys(filters).reduce(
          (acc, filterName) => ({ ...acc, [filterName]: [] }),
          {}
        );
      setSelectedItems(initialSelections);
    }
  }, [filters, currentSection, selectedFilterItems]);

  const handleItemSelect = (filterId) => (newItem) => {
    const currentItems = selectedItems[filterId] || [];

    if (!currentItems.includes(newItem)) {
      setSelectedItems({
        ...selectedItems,
        [filterId]: [...currentItems, newItem],
      });
    }
  };

  const handleItemToggle = (filterId) => (itemToRemove) => {
    const newItems = {
      ...selectedItems,
      [filterId]: selectedItems[filterId].filter(
        (item) => item.id !== itemToRemove.id
      ),
    };
    setSelectedItems(newItems);
  };

  const onClear = () => {
    dispatch({
      type: `SET_${currentSection}_FILTERS`,
      filters: {},
      count: 0,
    });

    dispatch({
      type: "SET_SELECTED_FILTER_ITEMS",
      section: currentSection,
      items: {},
    });

    setSelectedItems({});
  };

  const onClose = () => {
    dispatch({ type: "CLOSE_FILTER_DRAWER" });
  };

  function sanitizeFilters(input) {
    return Object.keys(input).reduce((acc, key) => {
      if (input[key].length > 0) {
        const idString = input[key].map((obj) => obj.id).join(",");
        if (key === "tabs") {
          acc[`filters[${key}]`] = idString;
        } else if (
          [
            "assigned",
            "level_compliance",
            "service_category",
            "completion_method",
          ].includes(key)
        ) {
          acc[`filters[${key}][]`] = idString;
        }
      }
      return acc;
    }, {});
  }

  const onApply = () => {
    const filterParams = sanitizeFilters(selectedItems);

    dispatch({
      type: "SET_SELECTED_FILTER_ITEMS",
      section: currentSection,
      items: selectedItems,
    });

    dispatch({
      type: `SET_${currentSection}_FILTERS`,
      filters: filterParams,
      count: Object.values(selectedItems).flat().length,
    });
    onClose();
  };

  const filterComponents = Object.keys(filters).map((filterName) => {
    const readableFilterName = formatStringReadable(filterName);

    if (filterName === "assigned") {
      filters[filterName] = sortedUsers.map((user) => ({
        name: `${user.first_name} ${user.last_name}`,
        id: user.id,
      }));
    }
    return (
      <SearchableFilter
        key={filterName}
        title={readableFilterName}
        options={filters[filterName]}
        selectedItems={selectedItems[filterName]}
        onItemSelect={handleItemSelect(filterName)}
        onItemToggle={handleItemToggle(filterName)}
      />
    );
  });

  return (
    <Drawer
      anchor="right"
      open={activeDrawerSection === currentSection}
      onClose={onClose}
      hideBackdrop={true}
      onBackdropClick={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
      <Box className={classes.header}>
        <Typography className={classes.headerText}>
          {section} Filters
        </Typography>
        <Box className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            onClick={onClear}
            className={classes.secondaryButton}
          >
            Clear
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            className={classes.secondaryButton}
          >
            Cancel
          </Button>
          <Button onClick={onApply} className={classes.applyButton}>
            Apply
          </Button>
        </Box>
      </Box>
      <Box className={classes.content}>{filterComponents}</Box>
    </Drawer>
  );
};

export default LocationDetailsFilterDrawer;
