import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core/index";
import LunaSearchInputParameters from "./lunaSearchInputParameters";
import LunaSearchPopper from "./lunaSearchPopper";
import { Mixpanel } from "services/mixpanel";
import { getLunaSearchSuggestions } from "services/aiServices";
import clsx from "clsx";
import useStyles from "../SearchBar/styles";
import Box from "@material-ui/core/Box";

const LunaInputSearch = (props) => {
  const {
    enableParameters,
    selectedSuggestedAction,
    onHandleSelectedSuggestedActionParameter,
    handleSearch,
    stateValue,
    id,
    handleChange,
    placeholder,
    isSearchAi,
    isLoadingSearch,
    module,
    startAdornment,
    endAdornment,
    anchor,
    focus,
    blur,
    textFieldRef,
    onHandleSelectedSuggestedAction,
  } = props;
  const classes = useStyles();
  const [suggestions, setSuggestions] = useState([]);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const getSuggestions = async () => {
      const suggestionsResp = await getLunaSearchSuggestions(module);
      setSuggestions(suggestionsResp);
    };
    getSuggestions();
  }, [module]);

  const onHandleFocus = (event) => {
    if (
      (suggestions?.data?.categories.filter(
        (category) => category.value !== "All"
      ).length === 0 &&
        module !== "partners") ||
      (module === "partners" &&
        suggestions?.data?.suggestedPrompts.length === 0)
    )
      return;
    focus(event);
  };

  const onHandleSearch = (ev, stateValue) => {
    handleSearch(ev, stateValue);
    Mixpanel.identify(user.id);
    Mixpanel.track(`Luna Search ${module}`, { searchPrompt: stateValue });
  };

  return (
    <Box
      className={
        module !== "locations"
          ? classes.searchParentContainer
          : classes.locationSearchParentContainer
      }
    >
      {enableParameters ? (
        <LunaSearchInputParameters
          selectedAction={selectedSuggestedAction}
          onHandlePromptFormat={onHandleSelectedSuggestedActionParameter}
          model={module}
        />
      ) : (
        <TextField
          name="searchField"
          onKeyPress={(ev) => onHandleSearch(ev, stateValue)}
          aria-describedby={id}
          value={stateValue}
          onChange={handleChange}
          inputProps={{ autoComplete: "off" }}
          placeholder={placeholder ? placeholder : undefined}
          disabled={isSearchAi && isLoadingSearch}
          InputProps={{
            startAdornment: startAdornment,
            endAdornment: endAdornment,
            style: {
              padding: "1.2px 6px",
              color: "#747474",
            },
          }}
          className={clsx(
            { [classes.workticketSearchAi]: module === "worktickets" },
            {
              [classes.partnerSearchAi]:
                module === "partners" || module === "locations",
            },
            {
              [classes.searchAiLoading]:
                (isLoadingSearch &&
                  (module === "worktickets" ||
                    module === "partners" ||
                    module === "locations")) ||
                Boolean(anchor) ||
                stateValue?.length > 0,
            }
          )}
          variant="outlined"
          size="small"
          onFocus={(e) => onHandleFocus(e)}
          onBlur={blur}
          inputRef={textFieldRef}
        />
      )}
      <LunaSearchPopper
        suggestions={suggestions}
        id={id}
        anchor={anchor}
        model={module}
        isSearching={isLoadingSearch}
        onHandleSelectedAction={onHandleSelectedSuggestedAction}
      />
    </Box>
  );
};

export default LunaInputSearch;
