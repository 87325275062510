import http from "./httpService";
import { apiUrl } from "lib/config";

const apiEndpointLunaSearch = `${apiUrl}/ai`;
const apiEndpointSlack = `${apiUrl}/slack-llm`;

export function getAiSearch(userEmail, userId, search) {
  return http.get(
    `${apiEndpointLunaSearch}/partners-search?user_email=${userEmail}&user_id=${userId}&prompt=${search}`
  );
}

export function getWorkticketAiSearch(
  userEmail,
  userId,
  search,
  latitude,
  longitude,
  radius,
  userType
) {
  return http.get(
    `${apiEndpointLunaSearch}/workticket-search?user_email=${userEmail}&user_id=${userId}&prompt=${search}&latitude=${latitude}&longitude=${longitude}&radius=${radius}&user_type=${userType}`
  );
}

export function getLocationDashboardAiSearch(
  userEmail,
  userId,
  search,
  latitude,
  longitude,
  radius
) {
  return http.get(
    `${apiEndpointLunaSearch}/location-workticket-search?user_email=${userEmail}&user_id=${userId}&prompt=${search}&latitude=${latitude}&longitude=${longitude}&radius=${radius}`
  );
}

export function getReasonFeedback() {
  return http.get(`${apiEndpointSlack}/reason-feedback`);
}

export function storePartnerFeedback(data) {
  return http.post(`${apiEndpointSlack}/store-partners-feedback`, data);
}

export function storeWorkticketFeedback(data) {
  return http.post(`${apiEndpointSlack}/store-worktickets-feedback`, data);
}

export function storeFeedback(data) {
  return http.post(`${apiEndpointSlack}/store-feedback`, data);
}

export function getLunaSearchSuggestions(model) {
  return http.get(
    `${apiEndpointLunaSearch}/luna-search-suggestions?model=${model}`
  );
}

export function getLunaSearchPrompts(promptId) {
  return http.get(
    `${apiEndpointLunaSearch}/luna-search-prompts?suggested_prompt_id=${promptId}`
  );
}
