import { makeStyles, Avatar, Chip } from "@material-ui/core";
import { tradeIcons } from "../../../pages/dashboard/map/tradeIcons";
// import { TradeIconSet } from "components/ui/Trades";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: 32,
    gap: 8,
    borderRadius: 50,
    backgroundColor: theme.palette.background.default,
    border: "1px solid #ECECEC",
    padding: "0 8px",
  },
  avatar: {
    width: "auto !important",
    height: "20px !important",
    flexShrink: 0,
    margin: 0,
    borderRadius: 0,
    marginRight: "-4px !important",
  },
  label: {
    fontSize: 14,
    letterSpacing: "0.1px",
    lineHeight: 1,
    fontWeight: 400,
    color: theme.palette.text.primary,
    padding: 0,
  },
}));

export const TradeChip = ({ label, icon }) => {
  const classes = useStyles();

  return (
    <Chip
      classes={{
        root: classes.root,
        avatar: classes.avatar,
        label: classes.label,
      }}
      avatar={<Avatar src={tradeIcons[icon] || tradeIcons.default} />}
      label={label}
    />
  );
};
