import { useContext, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import { SolidDocument } from "../icons/SolidDocument";
import ThreeDots from "../icons/ThreeDots";
import { chatIconSolid, chatIconOutlined } from "components/common/File/icons";
import ConfirmationDialog from "components/common/File/confirmationDialog";
import CommentDialog from "components/common/File/commentDialog";
import { dateFormatField } from "components/util/timeFormat";
import ViewIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import EditIcon from "@material-ui/icons/Edit";
import { DeleteBin } from "pages/partnerPage/icons/DeleteBin";
import {
  updatePartnerFile,
  deletePartnerFileCompliance,
} from "services/partners/filesService";
import { hasPermission, permissionPartner } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";
import { logException } from "components/util/logUtil";
import useStyles from "../styles";

const getMenuOptions = () => {
  const options = [
    {
      id: 1,
      label: "View",
      value: "VIEW",
      icon: <ViewIcon fontSize="inherit" />,
      permission: null,
    },
    {
      id: 2,
      label: "Edit",
      value: "EDIT",
      icon: <EditIcon fontSize="inherit" />,
      permission: permissionPartner.PARTNER_MANAGE,
    },
    {
      id: 3,
      label: "Delete",
      value: "DELETE",
      icon: <DeleteBin fontSize="inherit" />,
      permission: null,
    },
  ];

  return options;
};

export function FileContainer({ partnerId, file, fetchData }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [newName, setNewName] = useState("");
  const [expirationDate, setExpirationDate] = useState(null);
  const [isApproved, setIsApproved] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;

  useEffect(() => {
    setIsApproved(Boolean(Number(file?.is_approved)));
    setNewName(file?.display_name);
    setExpirationDate(file?.expiration_date);
  }, [file]);

  const handleApprove = async () => {
    if (
      isApproved ||
      hasPermission(permissionPartner.PARTNER_OWNER, permissions) ||
      !hasPermission(permissionPartner.PARTNER_MANAGE, permissions)
    ) {
      return;
    }
    try {
      setIsApproved(true);
      await updatePartnerFile(partnerId, file.id, {
        is_approved: 1,
      });
    } catch (error) {
      setIsApproved(false);
      logException(error, "Error approving file");
    }
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClickComments = (event, row) => {
    setSelectedRow(row);
    setOpenComment(true);
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    const actionHandlers = {
      VIEW: handleViewClick,
      EDIT: handleEditClick,
      DELETE: handleDeleteClick,
    };

    const selectedAction = actionHandlers[action];
    if (selectedAction) {
      selectedAction();
    }
    setAnchorEl(null);
  };

  const handleViewClick = () => {
    if (selectedRow) {
      window.open(selectedRow.file_url, "_blank");
    }
  };

  const handleEditClick = () => {
    setOpenEditDialog(true);
    setSelectedRow(selectedRow);
  };

  const handleDeleteClick = () => {
    setOpenDelete(true);
    setSelectedRow(selectedRow);
  };

  const handleChangeExpirationDate = (date) => {
    setExpirationDate(date);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleConfirmEdit = async () => {
    let expDate = 0;
    if (!expirationDate) {
      expDate = -1;
    } else {
      expDate = expirationDate;
    }
    try {
      setIsLoadingData(true);
      await updatePartnerFile(partnerId, selectedRow.id, {
        display_name: newName,
        expiration_date: expDate,
      });
      setOpenEditDialog(false);
      setIsLoadingData(false);

      fetchData();
    } catch (e) {
      logException(e, "Cannot update file");
      setIsLoadingData(false);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setIsLoadingData(true);
      await deletePartnerFileCompliance(partnerId, selectedRow.id);
      handleCloseDelete();
      setIsLoadingData(false);
      fetchData();
    } catch (e) {
      logException(e, "Cannot remove file");
      setIsLoadingData(false);
    }
  };

  return (
    <>
      <Box className={classes.uploadingFileDottedContainer}>
        <Box className={classes.fileNameAndActionsBarMenu}>
          <Box className={classes.documentIconAndNameContainer}>
            <Box style={{ width: 40 }}>
              <SolidDocument />
            </Box>
            <Typography className={classes.documentNameText}>
              {file?.display_name}
            </Typography>
          </Box>
          <Typography className={classes.documentNameText}>
            {file?.owner}
          </Typography>
          <Typography className={classes.documentNameText}>
            <nobr>{file?.file_size} MB</nobr>
          </Typography>
          <Typography className={classes.documentNameText}>
            {dateFormatField(file?.created_at)}
          </Typography>
          <Box className={classes.filesSmallButtonsContainer}>
            <Box>
              <IconButton
                style={{ padding: 0 }}
                onClick={(event) => handleClickComments(event, file)}
              >
                {parseInt(file.has_comments) === 1
                  ? chatIconSolid()
                  : chatIconOutlined()}
              </IconButton>
            </Box>
            <Box>
              <IconButton
                style={{ padding: 0 }}
                onClick={(event) => handleClick(event, file)}
              >
                <ThreeDots />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box className={classes.expirationDateContainer}>
          <Typography className={classes.expirationDateText}>
            Expiration Date:{" "}
            {file?.expiration_date
              ? dateFormatField(file?.expiration_date)
              : "N/A"}
          </Typography>
          <Button
            onClick={handleApprove}
            className={
              parseInt(file?.is_approved) === 2
                ? classes.expiredFileButton
                : isApproved
                ? classes.approvedFileButton
                : classes.approveFileButton
            }
          >
            {parseInt(file?.is_approved) === 2
              ? "Expired"
              : isApproved
              ? "Approved"
              : hasPermission(permissionPartner.PARTNER_OWNER, permissions) ||
                !hasPermission(permissionPartner.PARTNER_MANAGE, permissions)
              ? "Pending Approval"
              : "Approve"}
          </Button>
        </Box>
      </Box>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl) && selectedRow === file}
        onClose={handleCloseMenu}
      >
        {getMenuOptions()
          .filter(
            (item) =>
              item.permission === null ||
              hasPermission(item.permission, permissions)
          )
          .map((option, index) => (
            <MenuItem key={index} onClick={handleSelect} data={option.value}>
              <Box className={classes.menuIcon}>{option.icon}</Box>
              <Typography variant="caption" className={classes.menuText}>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
      </Menu>
      <ConfirmationDialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        onConfirm={handleConfirmEdit}
        title="File"
        isLoading={isLoadingData}
        body={
          <>
            <FormControl
              className={classes.dialogFormContainer}
              fullWidth
              style={{ textAlign: "left" }}
            >
              <FormLabel className={classes.dialogFormLabel}>Name</FormLabel>
              <TextField
                className={classes.dialogFormText}
                variant="outlined"
                size="small"
                value={newName}
                FormHelperTextProps={{ style: { marginLeft: 0 } }}
                onChange={(e) => {
                  setNewName(e.target.value);
                }}
                inputProps={{ style: { fontSize: 14 } }}
              />
              <FormLabel className={classes.dialogFormLabel}>
                Expiration Date
              </FormLabel>
              <FormInputDateAdo
                name="expiration_date"
                handleChange={handleChangeExpirationDate}
                value={expirationDate}
              />
            </FormControl>
          </>
        }
      />
      <CommentDialog
        open={openComment}
        partnerId={partnerId}
        fileId={selectedRow?.id}
        handleDialogClose={() => setOpenComment(false)}
      />
      <ConfirmationDialog
        open={openDelete}
        onClose={handleCloseDelete}
        onConfirm={handleConfirmDelete}
        title={"Documents"}
        content="Are you sure you want to remove the file?"
        isLoading={isLoadingData}
      />
    </>
  );
}
