import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Typography, Box, Button } from "@material-ui/core";
import SearchableFilter from "./searchableFilter";
import { useFetchMapFilters } from "./useFetchMapFilters";
import { formatStringReadable } from "pages/locationDetails/utils";
import { countryStates } from "../../../constants";
import GlobalUiContext from "contexts/globalUiContext";
import { useMapDispatch, useMapContext } from "./context/useMapContext";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 360,
  },
  header: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  headerText: {
    fontSize: 16,
    fontWeight: 700,
    color: "#4D4D4D",
  },
  content: {
    padding: theme.spacing(2),
    "& .MuiAccordion-root": {
      boxShadow: "none",
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.grey[50],
      "&::before": {
        display: "none",
      },
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.common.white,
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  filterContainer: {
    marginBottom: theme.spacing(1),
  },
  secondaryButton: {
    color: theme.palette.text.secondary,
    minWidth: "auto",
    padding: theme.spacing(0.5, 1),
    textTransform: "none",
    height: 24,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  applyButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    minWidth: "auto",
    padding: theme.spacing(0.5, 2),
    textTransform: "none",
    height: 24,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const EXCLUDED_PARTNER_FILTERS = [
  "director",
  "manager",
  "insights_reports",
  "customer",
  "project",
];

const MapFilterDrawer = ({ open, onClose }) => {
  const classes = useStyles();
  const {
    data: { filters },
  } = useFetchMapFilters();
  const dispatch = useMapDispatch();
  const mapContext = useMapContext();
  const { globalUi } = useContext(GlobalUiContext);
  const { users, role } = globalUi;
  const sortedUsers = users.sort((a, b) =>
    a.first_name.localeCompare(b.first_name)
  );
  const states = countryStates.map((state) => ({
    id: state.value,
    name: state.label,
  }));

  const [selectedItems, setSelectedItems] = useState(mapContext.mapFilters);

  const handleApply = () => {
    dispatch({
      type: "SET_MAP_FILTERS",
      filters: {
        ...selectedItems,
      },
    });
    onClose();
  };

  const handleCancel = () => {
    setSelectedItems(mapContext.mapFilters);
    onClose();
  };

  const handleClearFilters = () => {
    // Clear the local state
    setSelectedItems(
      Object.keys(filters).reduce(
        (acc, filterName) => ({ ...acc, [filterName]: [] }),
        {}
      )
    );
  };

  useEffect(() => {
    if (
      Object.keys(selectedItems).length === 0 &&
      Object.keys(filters).length > 0
    ) {
      setSelectedItems(
        Object.keys(filters).reduce(
          (acc, filterName) => ({ ...acc, [filterName]: [] }),
          {}
        )
      );
    }
  }, [filters, selectedItems]);

  useEffect(() => {
    setSelectedItems(mapContext.mapFilters);
  }, [mapContext.mapFilters]);

  const handleItemSelect = (filterId) => (newItem) => {
    if (selectedItems[filterId] && !selectedItems[filterId].includes(newItem)) {
      setSelectedItems({
        ...selectedItems,
        [filterId]: [...selectedItems[filterId], newItem],
      });
    } else if (!selectedItems[filterId]) {
      setSelectedItems({
        ...selectedItems,
        [filterId]: [newItem],
      });
    }
  };

  const handleItemToggle = (filterId) => (itemToRemove) => {
    setSelectedItems({
      ...selectedItems,
      [filterId]: selectedItems[filterId].filter(
        (item) => item !== itemToRemove
      ),
    });
  };

  const sanitizeFilterOptions = (options) => {
    return options.map((option) => ({
      id: option.id,
      name:
        option.name ||
        option.label ||
        (option.first_name ? `${option.first_name} ${option.last_name}` : "") ||
        option.title ||
        option.job_description,
    }));
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      hideBackdrop={true}
      classes={{ paper: classes.drawerPaper }}
    >
      <Box className={classes.header}>
        <Typography className={classes.headerText}>Filters</Typography>
        <Box className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            onClick={handleCancel}
            className={classes.secondaryButton}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={handleClearFilters}
            className={classes.secondaryButton}
          >
            Clear All
          </Button>
          <Button
            className={classes.applyButton}
            size="small"
            onClick={handleApply}
          >
            Apply
          </Button>
        </Box>
      </Box>

      <Box className={classes.content}>
        {Object.keys(filters)
          .filter(
            (itemKey) =>
              !(
                EXCLUDED_PARTNER_FILTERS.includes(itemKey) &&
                role.category === "subcontractor"
              )
          )
          .map((filterName) => {
            const readableFilterName = formatStringReadable(filterName);
            if (filterName === "assigned") {
              filters[filterName] = sortedUsers;
            }
            if (filterName === "state") {
              filters[filterName] = states;
            }

            const isSearchEndpointFilter =
              filterName === "city" || filterName === "zip_code";
            const options = isSearchEndpointFilter
              ? []
              : sanitizeFilterOptions(filters[filterName]);

            return (
              <SearchableFilter
                key={filterName}
                title={readableFilterName}
                options={options}
                selectedItems={selectedItems[filterName]}
                onItemSelect={handleItemSelect(filterName)}
                onItemToggle={handleItemToggle(filterName)}
                placeholder={
                  isSearchEndpointFilter ? `Enter ${readableFilterName}...` : ""
                }
                useSearchEndpoint={isSearchEndpointFilter}
                filterType={filterName}
                minSearchLength={isSearchEndpointFilter ? 4 : 0}
              />
            );
          })}
      </Box>
    </Drawer>
  );
};

export default MapFilterDrawer;
