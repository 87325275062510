import { useState } from "react";
import { Chip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FilterProvider } from "contexts/filterContext";
import { Skeleton } from "@material-ui/lab";
import {
  Table,
  ViewButton,
  SectionHeader,
  PaginationBar,
  ZeroState,
} from "pages/locationDetails/components";
import { ChatBubbleLeftRightIcon as ChatBubbleLeftRightIconOutline } from "@heroicons/react/24/outline";
import { ChatBubbleLeftRightIcon as ChatBubbleLeftRightIconSolid } from "@heroicons/react/24/solid";
import { Box, makeStyles } from "@material-ui/core";
import {
  useLocationDetailsContext,
  useLocationDetailsDispatch,
} from "pages/locationDetails/context";
import { ClipboardChecklist } from "assets/illustrations";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    width: "100%",
    minWidth: 0,
  },
  statusChip: {
    height: 24,
    borderRadius: 4,
    padding: "0 8px",
  },
  statusLabel: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: "0.25px",
    lineHeight: 1,
    padding: 0,
  },
  zeroState: {
    height: 200,
    justifyContent: "center",
  },
}));

const TableSkeleton = ({ columns, rowCount = 10 }) => {
  const getSkeletonWidth = (columnKey) => {
    switch (columnKey) {
      case "number":
        return "60%";
      case "actions":
        return "24px";
      case "comments":
        return "24px";
      case "request_status":
        return "70%";
      default:
        return "80%";
    }
  };

  return (
    <Table
      columns={columns}
      data={Array(rowCount).fill({})}
      renderCell={(column, _, __, index) => (
        <Skeleton
          variant="text"
          width={getSkeletonWidth(column.key)}
          style={{
            transform: "scale(1, 0.8)",
            animationDelay: `${index * 30}ms`,
          }}
        />
      )}
    />
  );
};

export const ReactiveRequests = () => {
  const history = useHistory();
  const classes = useStyles();
  const {
    reactiveRequests: {
      isLoading,
      isFetching,
      data: { cardData, pagination, metaData },
    },
  } = useLocationDetailsContext();
  const dispatch = useLocationDetailsDispatch();

  const columns = [
    { key: "number", label: "Number" },
    { key: "name", label: "Name" },
    { key: "nte", label: "NTE" },
    { key: "request_status", label: "Status" },
    { key: "comments", label: "" },
    { key: "actions", label: "" },
  ];

  const render = (column, value, row) => {
    switch (column.key) {
      case "nte":
        return `$${value}`;

      case "request_status": {
        const statusConfig = {
          1: {
            label: "Request Submitted",
            backgroundColor: "#EDF5F8",
            textColor: "#4F98BC",
          },
          2: {
            label: "Request Accepted",
            backgroundColor: "#E8F8CB",
            textColor: "#328914",
          },
          3: {
            label: "Request Declined",
            backgroundColor: "#FFDEE3",
            textColor: "#EA5168",
          },
        };

        if (value in statusConfig) {
          return (
            <Chip
              label={statusConfig[value].label}
              classes={{
                root: classes.statusChip,
                label: classes.statusLabel,
              }}
              style={{
                backgroundColor: statusConfig[value].backgroundColor,
                color: statusConfig[value].textColor,
              }}
            />
          );
        }
        return null;
      }

      case "comments":
        return !!value ? (
          <ChatBubbleLeftRightIconSolid
            style={{ width: 20, height: 20 }}
            color="#747474"
          />
        ) : (
          <ChatBubbleLeftRightIconOutline
            style={{ width: 20, height: 20 }}
            color="#747474"
          />
        );

      case "actions":
        return (
          <ViewButton onClick={() => history.push(`/workticket/${row.id}`)} />
        );

      default:
        return value;
    }
  };

  const handlePageChange = (newPage) => {
    dispatch({ type: "SET_REACTIVE_REQUESTS_PAGE", page: newPage });
  };

  return (
    <FilterProvider>
      <Box className={classes.root}>
        <SectionHeader
          title="Reactive Requests"
          actions={["date", "filter"]}
          metaData={metaData}
        />
        {isLoading || isFetching ? (
          <PaginationBar
            data={cardData}
            pagination={pagination}
            onPageChange={handlePageChange}
          >
            {() => <TableSkeleton columns={columns} />}
          </PaginationBar>
        ) : cardData.length > 0 ? (
          <PaginationBar
            data={cardData}
            pagination={pagination}
            onPageChange={handlePageChange}
          >
            {(currentItems) => (
              <Table
                columns={columns}
                data={currentItems}
                renderCell={render}
              />
            )}
          </PaginationBar>
        ) : (
          <ZeroState
            illustration={<ClipboardChecklist height={72} />}
            text="No requests made yet"
            className={classes.zeroState}
          />
        )}
      </Box>
    </FilterProvider>
  );
};
