import { apiUrl, apiSurfaceUrl } from "lib/config";
import http from "./httpService";

const apiEndpoint = apiUrl;

export function getDetailsOfMapLocation(jobId, isSurfaceUser) {
  return http.get(
    isSurfaceUser
      ? `${apiSurfaceUrl}/jobs/${jobId}`
      : `${apiEndpoint}/jobs/${jobId}`,
    {
      params: {},
    }
  );
}

export function getWorkListAllSlider(jobId, isSurfaceUser, dashboardState) {
  let payload = new FormData();
  if (dashboardState?.dasboardPropsData?.checked) {
    payload.append(
      "filters[geo1]",
      dashboardState?.dasboardPropsData.currentMapBounds.sw.lng +
        "," +
        dashboardState?.dasboardPropsData.currentMapBounds.sw.lat
    );
    payload.append(
      "filters[geo2]",
      dashboardState?.dasboardPropsData.currentMapBounds.ne.lng +
        "," +
        dashboardState?.dasboardPropsData.currentMapBounds.ne.lat
    );
  } else {
    payload.append("filters[job_number][]", jobId);
  }
  return http.post(
    (isSurfaceUser ? `${apiEndpoint}` : `${apiEndpoint}`) + "/map/sliders/work",
    payload
  );
}
export function getAssetListAllSlider(
  jobId,
  isSurfaceUser,
  offset,
  dashboardState
) {
  let payload = new FormData();
  if (dashboardState?.dasboardPropsData?.checked) {
    payload.append(
      "filters[geo1]",
      dashboardState?.dasboardPropsData.currentMapBounds.sw.lng +
        "," +
        dashboardState?.dasboardPropsData.currentMapBounds.sw.lat
    );
    payload.append(
      "filters[geo2]",
      dashboardState?.dasboardPropsData.currentMapBounds.ne.lng +
        "," +
        dashboardState?.dasboardPropsData.currentMapBounds.ne.lat
    );
  } else {
    payload.append("filters[job_number][]", jobId);
  }
  return http.post(
    (isSurfaceUser ? `${apiSurfaceUrl}` : `${apiEndpoint}`) +
      `/map/sliders/assets` +
      (jobId
        ? offset
          ? `&offset=${offset}`
          : ""
        : offset
        ? `?offset=${offset}`
        : ""),
    payload
  );
}
export function getAssetDetailsById(jobId, isSurfaceUser) {
  return http.get(
    (isSurfaceUser ? `${apiSurfaceUrl}` : `${apiEndpoint}`) + `/assets/${jobId}`
  );
}

export function followUnfollowWorkTicket(
  workTicketId,
  is_following,
  isSurfaceUser
) {
  return http.post(
    isSurfaceUser
      ? `${apiSurfaceUrl}/worktickets/follow`
      : `${apiEndpoint}/worktickets/follow`,
    {
      workticket_ids: [workTicketId],
      is_unfollow: parseInt(is_following) === 1 ? 1 : 0,
    }
  );
}

export function getJobs(customerId) {
  let filterCustomer = "";
  if (customerId) {
    filterCustomer = `?filters[customer][]=${customerId}`;
  }
  return http.get(`${apiUrl}/map/jobs${filterCustomer}`);
}

export function getMapLocationData(filters, boundingBox, signal) {
  return http.get(`${apiUrl}/map/locations`, {
    params: { filters, boundingBox },
    signal,
  });
}
