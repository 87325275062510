import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import {
  Box,
  Chip,
  makeStyles,
  withStyles,
  Tab as MuiTab,
  Tabs as MuiTabs,
} from "@material-ui/core";
import {
  Table,
  ViewButton,
  SectionHeader,
  PaginationBar,
  ZeroState,
} from "pages/locationDetails/components";
import {
  useLocationDetailsContext,
  useLocationDetailsDispatch,
} from "pages/locationDetails/context";
import { formatDate } from "pages/locationDetails/utils";

const StyledTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #ECECEC",
    minHeight: 0,
    overflowX: "auto",
    display: "flex",
    flexShrink: 0,
    width: "100%",
    minWidth: 0,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  flexContainer: {
    gap: 24,
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      backgroundColor: theme.palette.secondary.main,
      width: "100%",
    },
  },
}))(MuiTabs);

const StyledTab = withStyles((theme) => ({
  root: {
    minHeight: 0,
    minWidth: 0,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    padding: "8px 0",
    margin: 0,
    "&.Mui-selected": {
      color: theme.palette.secondary.main,
    },
  },
}))((props) => <MuiTab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
  chipContainer: {
    display: "flex",
    gap: 4,
  },
  chipRoot: {
    backgroundColor: theme.palette.background.default,
    padding: "0 8px",
    height: 24,
    borderRadius: 50,
    border: "1px solid #ECECEC",
    color: theme.palette.text.primary,
  },
  chipLabel: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: "0.1px",
    lineHeight: 1,
    padding: 0,
  },
  tradeEllipsisButton: {
    borderRadius: 50,
    border: "1px solid #ECECEC",
    width: 24,
    height: 24,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tradeEllipsisIcon: {
    width: 16,
    height: 16,
    fill: theme.palette.text.primary,
  },
  zeroState: {
    height: 400, // Same height as skeleton
    justifyContent: "center",
  },
}));

const TableSkeleton = ({ columns, rowCount = 8 }) => {
  const getSkeletonWidth = (columnKey) => {
    switch (columnKey) {
      case "number":
        return "60%";
      case "actions":
        return "24px"; // Width of ViewButton
      case "trades":
        return "70%";
      default:
        return "80%";
    }
  };

  return (
    <Table
      columns={columns}
      data={Array(rowCount).fill({})}
      renderCell={(column, _, __, index) => (
        <Skeleton
          variant="text"
          width={getSkeletonWidth(column.key)}
          style={{
            transform: "scale(1, 0.8)",
            animationDelay: `${index * 30}ms`,
          }}
        />
      )}
    />
  );
};

export const ActiveWorktickets = () => {
  const history = useHistory();
  const classes = useStyles();
  const {
    notStartedWorktickets: {
      data: { cardData, pagination, metaData },
      isLoading: isLoadingNotStarted,
      isFetching: isFetchingNotStarted,
    },
    inProgressWorktickets: {
      data: { cardData: inProgressCardData, pagination: inProgressPagination },
      isLoading: isLoadingInProgress,
      isFetching: isFetchingInProgress,
    },
    completedWorktickets: {
      data: { cardData: completedCardData, pagination: completedPagination },
      isLoading: isLoadingCompleted,
      isFetching: isFetchingCompleted,
    },
  } = useLocationDetailsContext();
  const dispatch = useLocationDetailsDispatch();

  const hasData =
    cardData.length > 0 ||
    inProgressCardData.length > 0 ||
    completedCardData.length > 0;
  const isLoading =
    isLoadingNotStarted || isLoadingInProgress || isLoadingCompleted;
  const isFetching =
    isFetchingNotStarted || isFetchingInProgress || isFetchingCompleted;

  const columns = [
    { key: "number", label: "Number" },
    { key: "name", label: "Name" },
    { key: "users_assign", label: "Assigned To" },
    { key: "service_category", label: "Service Category" },
    { key: "trades", label: "Trade" },
    { key: "due_date", label: "Due Date" },
    { key: "actions", label: "" },
  ];

  const render = (column, value, row) => {
    switch (column.key) {
      case "users_assign":
        if (!value.length) return null;
        return value
          .map((user) => `${user.first_name} ${user.last_name}`)
          .join(", ");
      case "trades":
        if (!value.length) return null;

        return (
          <Chip
            classes={{
              root: classes.chipRoot,
              label: classes.chipLabel,
            }}
            label={value[0]}
          />
        );

      case "due_date":
        return formatDate(value);

      case "actions":
        return (
          <ViewButton onClick={() => history.push(`/workticket/${row.id}`)} />
        );

      default:
        return value;
    }
  };

  const [activeTab, setActiveTab] = useState(0);
  const [tabPages, setTabPages] = useState({
    "Not Started": 1,
    "In Progress": 1,
    Completed: 1,
  });

  const tabs = ["Not Started", "In Progress", "Completed"];

  const handleChange = (_, newValue) => {
    dispatch({
      type: "SET_ACTIVE_WORKTICKETS_PAGE",
      page: tabPages[tabs[newValue]],
    });
    setActiveTab(newValue);
  };

  const dataPerTab = {
    "Not Started": {
      cardData,
      pagination,
    },
    "In Progress": {
      cardData: inProgressCardData,
      pagination: inProgressPagination,
    },
    Completed: {
      cardData: completedCardData,
      pagination: completedPagination,
    },
  };

  const handlePageChange = (newPage) => {
    setTabPages((prev) => ({
      ...prev,
      [tabs[activeTab]]: newPage,
    }));
    dispatch({ type: "SET_ACTIVE_WORKTICKETS_PAGE", page: newPage });
  };

  return (
    <Box className={classes.root}>
      <SectionHeader
        title="Active Worktickets"
        actions={["date", "filter"]}
        metaData={metaData}
      />
      {isLoading || isFetching ? (
        <>
          <StyledTabs
            value={activeTab}
            onChange={handleChange}
            aria-label="mobile tabs"
            variant="scrollable"
            scrollButtons="off"
            TabIndicatorProps={{ children: <span /> }}
          >
            {tabs.map((tab) => (
              <StyledTab
                key={tab}
                label={`${tab} (${
                  dataPerTab[tab]?.pagination?.totalItems || 0
                })`}
              />
            ))}
          </StyledTabs>
          <PaginationBar
            data={dataPerTab[tabs[activeTab]].cardData}
            pagination={{
              ...dataPerTab[tabs[activeTab]].pagination,
              currentPage: tabPages[tabs[activeTab]],
            }}
            onPageChange={handlePageChange}
          >
            {() => <TableSkeleton columns={columns} />}
          </PaginationBar>
        </>
      ) : hasData ? (
        <>
          <StyledTabs
            value={activeTab}
            onChange={handleChange}
            aria-label="mobile tabs"
            variant="scrollable"
            scrollButtons="off"
            TabIndicatorProps={{ children: <span /> }}
          >
            {tabs.map((tab) => (
              <StyledTab
                key={tab}
                label={`${tab} (${
                  dataPerTab[tab]?.pagination?.totalItems || 0
                })`}
              />
            ))}
          </StyledTabs>
          <PaginationBar
            data={dataPerTab[tabs[activeTab]].cardData}
            pagination={{
              ...dataPerTab[tabs[activeTab]].pagination,
              currentPage: tabPages[tabs[activeTab]],
            }}
            onPageChange={handlePageChange}
          >
            {(currentItems) => (
              <Table
                columns={columns}
                data={currentItems}
                renderCell={render}
              />
            )}
          </PaginationBar>
        </>
      ) : (
        <ZeroState text="No active worktickets" className={classes.zeroState} />
      )}
    </Box>
  );
};
