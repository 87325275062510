import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  ClickAwayListener,
  Paper,
  Box,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import axios from "axios";
import debounce from "lodash/debounce";
import { apiUrl } from "lib/config";
const useStyles = makeStyles((theme) => ({
  accordion: {
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 200ms ease",
    "&.Mui-expanded": {
      transform: "rotate(90deg)",
    },
  },
  accordionDetails: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
  },
  accordionSummary: {
    minHeight: 0,
    margin: 0,
    "&.Mui-expanded": {
      minHeight: 0,
      margin: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: 8,
    },
  },
  checkbox: {
    padding: 0,
  },
  searchResults: {
    position: "absolute",
    width: "100%",
    zIndex: 1,
    marginTop: theme.spacing(0.5),
    maxHeight: 200,
    overflowY: "scroll",
  },
  selectedItems: {
    "& .MuiListItem-root": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  noResults: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  selectedItem: {
    paddingLeft: 4,
  },
}));

const SearchableFilter = ({
  title,
  options: initialOptions,
  selectedItems = [],
  onItemSelect,
  onItemToggle,
  placeholder = "Write a name...",
  useSearchEndpoint = false,
  filterType,
  minSearchLength = 0,
}) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [visibleCount, setVisibleCount] = useState(100);
  const [options, setOptions] = useState(initialOptions);
  const [isLoading, setIsLoading] = useState(false);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async (searchValue) => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${apiUrl}/map/locations/filters-search?type=${filterType}&search=${searchValue}`
        );

        // Convert string array to array of objects with id and name
        setOptions(
          response.data.data.map((item) => ({
            id: item, // Use the string value as both id and name
            name: item,
          }))
        );
      } catch (error) {
        console.error(`Error searching ${filterType}:`, error);
        setOptions([]);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    [filterType]
  );

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchText(value);

    if (useSearchEndpoint) {
      if (value.length >= minSearchLength) {
        setShowResults(true);
        debouncedSearch(value);
      } else {
        setOptions([]);
        setShowResults(false);
      }
    } else {
      setShowResults(true);
      setOptions(initialOptions);
    }

    setVisibleCount(50);
  };

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (bottom && visibleCount < options.length) {
      setVisibleCount((prevCount) => prevCount + 50);
    }
  };

  const handleSelect = (item) => {
    onItemSelect(item);
    setSearchText("");
    setShowResults(false);
  };

  const filteredOptions = useSearchEndpoint
    ? options.filter(
        (option) => !selectedItems.some((selected) => selected.id === option.id)
      )
    : options
        .filter((option) => {
          // First filter out already selected items
          if (selectedItems.some((selected) => selected.id === option.id)) {
            return false;
          }
          // Then apply search text filter
          if (searchText === "") return true;
          if (option?.name) {
            return option.name.toLowerCase().includes(searchText.toLowerCase());
          }
          return false;
        })
        .slice(0, visibleCount);

  return (
    <Accordion
      defaultExpanded={selectedItems.length > 0}
      classes={{ root: classes.accordion }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowRightRoundedIcon />}
        classes={{
          expandIcon: classes.expandIcon,
          root: classes.accordionSummary,
        }}
        aria-controls={`filter-${title}-content`}
        id={`filter-${title}`}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetails }}>
        <Box width="100%">
          <ClickAwayListener onClickAway={() => setShowResults(false)}>
            <Box position="relative">
              <TextField
                variant="outlined"
                fullWidth
                size="small"
                placeholder={
                  useSearchEndpoint && minSearchLength > 0
                    ? `Type at least ${minSearchLength} characters...`
                    : placeholder
                }
                value={searchText}
                onChange={handleSearchChange}
                onFocus={() =>
                  searchText.length >= minSearchLength && setShowResults(true)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <SearchRoundedIcon />
                      )}
                    </InputAdornment>
                  ),
                  notched: false,
                  label: null,
                }}
              />

              {showResults && (
                <Paper
                  className={classes.searchResults}
                  onScroll={handleScroll}
                >
                  <List dense>
                    {filteredOptions.length > 0 ? (
                      filteredOptions.map((option) => (
                        <ListItem
                          key={option.id}
                          button
                          onClick={() => handleSelect(option)}
                        >
                          <ListItemText primary={option.name} />
                        </ListItem>
                      ))
                    ) : (
                      <ListItem>
                        <ListItemText
                          primary="No results found"
                          className={classes.noResults}
                        />
                      </ListItem>
                    )}
                  </List>
                </Paper>
              )}
            </Box>
          </ClickAwayListener>

          {selectedItems.length > 0 && (
            <List dense className={classes.selectedItems}>
              {selectedItems.map((item) => (
                <ListItem key={item?.id} dense className={classes.selectedItem}>
                  <Checkbox
                    edge="start"
                    checked={true}
                    size="small"
                    className={classes.checkbox}
                    onChange={() => onItemToggle(item)}
                  />
                  <ListItemText dense primary={item.name} />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SearchableFilter;
