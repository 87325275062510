import React from "react";
import { Card, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import { MapPinIcon, UserIcon } from "@heroicons/react/24/outline";
import WorkticketAltIcon from "assets/icons/workticketAlt";
import LayersOutlinedIcon from "@material-ui/icons/LayersOutlined";
import { initialVisibleLayersByUser } from "./mapUtils";
const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    gap: theme.spacing(1),
    top: 200,
    right: 16,
  },
  menuButton: {
    padding: theme.spacing(1),
    backgroundColor: "#fff",
    borderRadius: theme.spacing(1),
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#747474",
    width: 40,
    height: 40,
    // border: "1px solid blue",
    zIndex: 2,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
    },
  },

  menuContainer: {
    padding: theme.spacing(1),
    zIndex: 1,
    backgroundColor: "#fff",
    borderRadius: theme.spacing(1),
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    minWidth: 160,
    display: "none",
  },
  menuVisible: {
    display: "block",
  },
  zoomContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: theme.spacing(1),
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",

    // padding: theme.spacing(1),
  },
  zoomInButton: {
    borderBottom: "1px solid #D9D9D9",
    borderRadius: "5px 5px 0 0",
  },
  zoomOutButton: {
    borderRadius: "0 0 5px 5px",
  },

  zoomButton: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
      cursor: "pointer",
    },
  },

  layerItem: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(0.5),
    cursor: "pointer",
    // border: "1px solid green",
    // "&:hover": {
    //   backgroundColor: "rgba(0, 0, 0, 0.04)",
    // },
  },
  layerIcon: {
    // color: "#757575",
    height: 20,
    width: 20,
    fontSize: 20,
    // border: "1px solid red",
  },
  layerText: {
    fontSize: "0.9rem",
  },
  activeLayer: {
    color: theme.palette.secondary.main,
  },
  layerIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ECECEC",
    borderRadius: 4,
    padding: 4,
    height: 28,
    width: 28,
    // border: "1px solid yellow",
  },
  activeLayerIconContainer: {
    backgroundColor: "#EDF5F8",
  },
}));

const LayerToggleControl = ({
  visibleLayers,
  onToggleLayer,
  onMyLocationClick,
  onZoomIn,
  onZoomOut,
}) => {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const layers = [
    {
      id: "LOCATIONS",
      label: "Locations",
      icon: <MapPinIcon className={classes.layerIcon} />,
    },
    {
      id: "WORKTICKETS",
      label: "Worktickets",
      icon: (
        <WorkticketAltIcon
          className={classes.layerIcon}
          width={30}
          height={30}
          fill={visibleLayers.includes("WORKTICKETS") ? "#4F98BC" : "#747474"}
        />
      ),
    },
    {
      id: "PARTNERS",
      label: "Partners",
      icon: <UserIcon className={classes.layerIcon} />,
    },
  ];
  const filteredLayers = layers.filter((layer) =>
    initialVisibleLayersByUser(currentUser).includes(layer.id)
  );

  return (
    <>
      <Box className={classes.buttonsContainer}>
        <Box className={classes.menuButton} onClick={onMyLocationClick}>
          <MyLocationIcon className={classes.layerIcon} />
        </Box>

        {filteredLayers.length > 1 && (
          <Box className={classes.menuButton} onClick={handleToggleMenu}>
            <LayersOutlinedIcon />
          </Box>
        )}

        {filteredLayers.length > 1 && (
          <Card
            className={`${classes.menuContainer} ${
              isMenuOpen ? classes.menuVisible : ""
            }`}
          >
            {filteredLayers.map((layer) => (
              <Box
                key={layer.id}
                onClick={() => onToggleLayer(layer.id)}
                className={`${classes.layerItem} ${
                  visibleLayers.includes(layer.id) ? classes.activeLayer : ""
                }`}
              >
                <Box
                  className={`${classes.layerIconContainer} ${
                    visibleLayers.includes(layer.id)
                      ? classes.activeLayerIconContainer
                      : ""
                  }`}
                >
                  {layer.icon}
                </Box>
                <Typography className={classes.layerText}>
                  {layer.label}
                </Typography>
              </Box>
            ))}
          </Card>
        )}

        <Box className={`${classes.zoomContainer}`}>
          <Box
            className={`${classes.zoomButton} ${classes.zoomInButton}`}
            onClick={onZoomIn}
          >
            <AddRoundedIcon />
          </Box>
          <Box
            className={`${classes.zoomButton} ${classes.zoomOutButton}`}
            onClick={onZoomOut}
          >
            <RemoveRoundedIcon />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LayerToggleControl;
