import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import useStyles from "./styles";
import BadgeIcon from "./badgeIcon";
import Divider from "@material-ui/core/Divider";
import LoadingStateHorizontal from "../LoadingStateHorizontal/LoadingStateHorizontal";

const LunaSearchSuggestedActions = (props) => {
  const {
    selectedSuggestedPrompt,
    suggestedActions,
    loading,
    onHandleSelectedAction,
  } = props;
  const classes = useStyles();

  const renderPromptWithPlaceholders = (prompt, placeholders) => {
    const parts = prompt.split(/(\${placeholder\d+})/g);
    return parts.map((part, index) => {
      const match = part.match(/\${placeholder(\d+)}/);
      if (match) {
        const placeholderIndex = parseInt(match[1], 10) - 1;
        return (
          <Box
            key={index}
            display="inline"
            className={classes.suggestedActionPlaceholder}
          >
            {`<${placeholders[placeholderIndex]}>`}
          </Box>
        );
      }
      return part;
    });
  };

  return (
    <Box className={classes.suggestedPromptsContainer}>
      <Box className={classes.suggestedPromptTitleActionContainer}>
        <Typography className={classes.title}>Suggested Prompts</Typography>
      </Box>
      <Box className={classes.selectedSuggestedPromptContainer}>
        <BadgeIcon
          iconType={selectedSuggestedPrompt.icon}
          color={selectedSuggestedPrompt.iconColor}
          backgroundColor={selectedSuggestedPrompt.backgroundColor}
        />
        <Typography className={classes.selectedSuggestedPrompt}>
          {selectedSuggestedPrompt.value}
        </Typography>
      </Box>
      <Box className={classes.suggestedActionTitleContainer}>
        <Divider style={{ width: "100%", background: "#ECECEC" }} />
        <Typography className={classes.titleActions}>
          Suggested Actions
        </Typography>
      </Box>
      <Box className={classes.suggestedPromptsButtonContainer}>
        {loading ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: "1 1",
              background: "white",
              margin: "170px auto",
            }}
          >
            <Box style={{}}>
              <LoadingStateHorizontal isVisible />
            </Box>
          </Box>
        ) : (
          suggestedActions?.map((action, index) => (
            <Button
              key={index}
              className={classes.suggestedActionButton}
              onClick={() => onHandleSelectedAction(action)}
            >
              <Typography
                component="div"
                className={classes.suggestedActionTypography}
              >
                {renderPromptWithPlaceholders(
                  action.prompt,
                  action.placeholders
                )}
              </Typography>
            </Button>
          ))
        )}
      </Box>
    </Box>
  );
};

export default LunaSearchSuggestedActions;
