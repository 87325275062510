import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Divider } from "@material-ui/core";
import {
  MapPinIcon,
  ClipboardDocumentCheckIcon,
  Square2StackIcon,
  Square3Stack3DIcon,
  BookmarkSquareIcon,
} from "@heroicons/react/24/outline";
import CompassIcon from "assets/icons/compassIcon";
import Popover from "@material-ui/core/Popover";
import StateFilter from "./stateFilter";
import RegionFilter from "./regionFilter";
import TradeFilter from "./tradeFilter";
import WorkticketTypeFilter from "./workticketTypeFilter";
import InsightsFilter from "./insightsFilter";
import { useMapContext, useMapDispatch } from "./context/useMapContext";
import httpService from "services/httpService";
import { apiUrl } from "lib/config";
import LunaSearchInput from "../common/LunaSearchInput/LunaSearchInput";
import { getLocationDashboardAiSearch } from "services/aiServices";
import GlobalUiContext from "contexts/globalUiContext";
import MapFilterDrawer from "./mapFilterDrawer";
import StatusFilter from "./statusFilter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  divider: {
    height: 44,
    margin: "0 16px 12px",
  },
  filtersButton: {
    marginLeft: theme.spacing(2),
    marginTop: 35,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    backgroundColor: "#EDF5F8",
    color: theme.palette.secondary.main,
    borderRadius: 4,
    padding: `12px 16px`,
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#DCE9EF",
      transition: "background-color 0.3s ease",
    },
  },
  filtersButtonCount: {
    marginLeft: theme.spacing(1),
    color: "white",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 16,
    fontSize: 11,
    padding: `3px 12px 2px 12px`,
  },
  filterItemText: {
    fontWeight: 600,
    fontSize: 11,
  },
  filterContainer: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "flex-end",
    //   padding: theme.spacing(1, 0),
    // border: `1px solid red`,
  },
  filterItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    cursor: "pointer",
    minWidth: 90,
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  iconContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  lunaSearchContainer: {
    margin: theme.spacing(1, 2, 1.5, 2),
    // border: `1px solid green`,
    flex: 1,
  },
  mainContainer: {
    // border: `1px solid blue`,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  activeFilter: {
    color: theme.palette.primary.main,
  },
  popover: {
    margin: theme.spacing(1),
  },
  icon: {
    width: 20,
    height: 20,
    marginBottom: theme.spacing(0.5),
  },
  mapView: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "20%",
      height: "60%",
      width: 1,
      backgroundColor: theme.palette.divider,
    },
    color: theme.palette.primary.main,
  },
  filterBadge: {
    position: "absolute",
    top: -5,
    right: -20,
    minWidth: 20,
    height: 20,
    padding: "0 6px",
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    fontSize: 11,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  filterItemContent: {
    display: "flex",
    alignItems: "center",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));

const SpecialFilters = ({
  visibleLayers,
  onLunaSearchResults,
  setIsLoading,
  setLunaSearchActive,
  setLunaTriggerRefresh,
}) => {
  const mapContext = useMapContext();
  const dispatch = useMapDispatch();
  const { globalUi } = useContext(GlobalUiContext);
  const { role } = globalUi;

  const activeFilters = (mapFilters) =>
    Object.keys(mapFilters).filter((key) => mapFilters[key].length > 0);

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);

  const [selectedStates, setSelectedStates] = useState(
    mapContext.mapFilters.state || []
  );
  const [selectedRegions, setSelectedRegions] = useState(
    mapContext.mapFilters.region || []
  );
  const [selectedTrades, setSelectedTrades] = useState(
    mapContext.mapFilters.trades || []
  );
  const [selectedWorkticketTypes, setSelectedWorkticketTypes] = useState(
    mapContext.mapFilters.workticket_types || []
  );
  const [selectedInsights, setSelectedInsights] = useState(
    mapContext.mapFilters.insights_categories || []
  );
  const [selectedStatuses, setSelectedStatuses] = useState(
    mapContext.mapFilters.workticket_status || []
  );

  const handleFilterDrawerClick = () => {
    setFilterDrawerOpen(true);
  };

  const handleFilterDrawerClose = () => {
    setFilterDrawerOpen(false);
  };

  const [insightsFilterOptions, setInsightsFilterOptions] = useState([]);
  const [tradeFilterOptions, setTradeFilterOptions] = useState([]);
  const [insightsLoading, setInsightsLoading] = useState(true);
  const [insightsError, setInsightsError] = useState(null);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  useEffect(() => {
    setSelectedStates(mapContext.mapFilters.state || []);
    setSelectedRegions(mapContext.mapFilters.region || []);
    setSelectedTrades(mapContext.mapFilters.trades || []);
    setSelectedWorkticketTypes(mapContext.mapFilters.workticket_types || []);
    setSelectedInsights(mapContext.mapFilters.insights_categories || []);
    setSelectedStatuses(mapContext.mapFilters.workticket_status || []);
  }, [mapContext.mapFilters]);

  useEffect(() => {
    const fetchSpecialFilterOptions = async () => {
      try {
        const response = await httpService.get(
          `${apiUrl}/map/locations/special-filters`
        );
        setInsightsFilterOptions(
          response?.data?.data?.insights_categories || []
        );
        setTradeFilterOptions(response?.data?.data?.trades || []);
        setInsightsLoading(false);
      } catch (err) {
        console.error("Error fetching insights:", err);
        setInsightsError("Failed to load insights");
        setInsightsLoading(false);
      }
    };

    fetchSpecialFilterOptions();
  }, []);

  const handleFilterClick = (filterName, event) => {
    if (activeFilter === filterName) {
      setAnchorEl(null);
      setActiveFilter(null);
    } else {
      setAnchorEl(event.currentTarget);
      setActiveFilter(filterName);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveFilter(null);
  };

  const handleStateSelect = (newSelectedStates) => {
    setSelectedStates(newSelectedStates);
    dispatch({
      type: "SET_MAP_FILTERS",
      filters: {
        state: newSelectedStates,
      },
    });
  };

  const handleRegionSelect = (newSelectedRegions) => {
    setSelectedRegions(newSelectedRegions);
    dispatch({
      type: "SET_MAP_FILTERS",
      filters: {
        region: newSelectedRegions,
      },
    });
  };

  const handleTradeSelect = (newSelectedTrades) => {
    setSelectedTrades(newSelectedTrades);
    dispatch({
      type: "SET_MAP_FILTERS",
      filters: {
        trades: newSelectedTrades,
      },
    });
  };

  const handleWorkticketTypeSelect = (newSelectedWorkticketTypes) => {
    setSelectedWorkticketTypes(newSelectedWorkticketTypes);
    dispatch({
      type: "SET_MAP_FILTERS",
      filters: {
        workticket_types: newSelectedWorkticketTypes,
      },
    });
  };

  const handleInsightSelect = (newSelectedInsights) => {
    setSelectedInsights(newSelectedInsights);
    dispatch({
      type: "SET_MAP_FILTERS",
      filters: {
        insights_categories: newSelectedInsights,
      },
    });
  };

  const handleStatusSelect = (newSelectedStatuses) => {
    setSelectedStatuses(newSelectedStatuses);
    dispatch({
      type: "SET_MAP_FILTERS",
      filters: {
        workticket_status: newSelectedStatuses,
      },
    });
  };

  const handleLunaSearchResponse = (response) => {
    onLunaSearchResults(response.data.data);
  };

  const handleLunaSearchStart = async (
    userEmail,
    userId,
    query,
    lat,
    lng,
    radius
  ) => {
    setIsLoading(true);
    // Dispatch before API call
    dispatch({ type: "CLEAR_FILTERS" });

    // Call the original search function with all required parameters
    return getLocationDashboardAiSearch(
      userEmail,
      userId,
      encodeURIComponent(query),
      lat,
      lng,
      radius
    );
    // setIsLoading(false);
  };

  const isPartner = role.category === "subcontractor";

  return (
    <div className={classes.root}>
      <Box className={classes.mainContainer}>
        <Box className={classes.topContainer}>
          <Box className={classes.filterContainer}>
            <Box display="flex">
              {!isPartner ? (
                <Box
                  className={`${classes.filterItem} ${
                    activeFilter === "region" ? classes.activeFilter : ""
                  }`}
                  onClick={(e) => handleFilterClick("region", e)}
                >
                  <Box className={classes.iconContainer}>
                    <MapPinIcon className={classes.icon} />
                    {selectedRegions.length > 0 && (
                      <Box className={classes.filterBadge}>
                        {selectedRegions.length}
                      </Box>
                    )}
                  </Box>
                  <Typography
                    className={classes.filterItemText}
                    variant="body2"
                  >
                    Region
                  </Typography>
                </Box>
              ) : null}
              <Box
                className={`${classes.filterItem} ${
                  activeFilter === "state" ? classes.activeFilter : ""
                }`}
                onClick={(e) => handleFilterClick("state", e)}
              >
                <Box className={classes.iconContainer}>
                  <CompassIcon className={classes.icon} />
                  {selectedStates.length > 0 && (
                    <Box className={classes.filterBadge}>
                      {selectedStates.length}
                    </Box>
                  )}
                </Box>
                <Typography className={classes.filterItemText} variant="body2">
                  State
                </Typography>
              </Box>
              {!isPartner ? (
                <Box
                  className={`${classes.filterItem} ${
                    activeFilter === "insights" ? classes.activeFilter : ""
                  }`}
                  onClick={(e) => handleFilterClick("insights", e)}
                >
                  <Box className={classes.iconContainer}>
                    <Square3Stack3DIcon className={classes.icon} />
                    {selectedInsights.length > 0 && (
                      <Box className={classes.filterBadge}>
                        {selectedInsights.length}
                      </Box>
                    )}
                  </Box>
                  <Typography
                    className={classes.filterItemText}
                    variant="body2"
                  >
                    Insights
                  </Typography>
                </Box>
              ) : null}
              <Box
                className={`${classes.filterItem} ${
                  activeFilter === "workticketType" ? classes.activeFilter : ""
                }`}
                onClick={(e) => handleFilterClick("workticketType", e)}
              >
                <Box className={classes.iconContainer}>
                  <Square2StackIcon className={classes.icon} />
                  {selectedWorkticketTypes.length > 0 && (
                    <Box className={classes.filterBadge}>
                      {selectedWorkticketTypes.length}
                    </Box>
                  )}
                </Box>
                <Typography className={classes.filterItemText} variant="body2">
                  WT Type
                </Typography>
              </Box>
              <Box
                className={`${classes.filterItem} ${
                  activeFilter === "workticketStatus"
                    ? classes.activeFilter
                    : ""
                }`}
                onClick={(e) => handleFilterClick("workticketStatus", e)}
              >
                <Box className={classes.iconContainer}>
                  <BookmarkSquareIcon className={classes.icon} />
                  {selectedStatuses.length > 0 && (
                    <Box className={classes.filterBadge}>
                      {selectedStatuses.length}
                    </Box>
                  )}
                </Box>
                <Typography className={classes.filterItemText} variant="body2">
                  WT Status
                </Typography>
              </Box>
              <Box
                className={`${classes.filterItem} ${
                  activeFilter === "trade" ? classes.activeFilter : ""
                }`}
                onClick={(e) => handleFilterClick("trade", e)}
              >
                <Box className={classes.iconContainer}>
                  <ClipboardDocumentCheckIcon className={classes.icon} />
                  {selectedTrades.length > 0 && (
                    <Box className={classes.filterBadge}>
                      {selectedTrades.length}
                    </Box>
                  )}
                </Box>
                <Typography className={classes.filterItemText} variant="body2">
                  Trades
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.lunaSearchContainer}>
            <LunaSearchInput
              setLunaSearchActive={setLunaSearchActive}
              isSearchAi={true}
              module="locations"
              model="location search"
              onHandleLunaSearch={handleLunaSearchStart}
              onHandleSearchResponse={handleLunaSearchResponse}
              setLunaTriggerRefresh={setLunaTriggerRefresh}
            />
          </Box>
        </Box>
        <Divider orientation="vertical" className={classes.divider} />
        <Box
          className={classes.filtersButton}
          onClick={handleFilterDrawerClick}
        >
          Filters
          {activeFilters(mapContext?.mapFilters)?.length > 0 && (
            <Box className={classes.filtersButtonCount}>
              {activeFilters(mapContext.mapFilters).length}
            </Box>
          )}
        </Box>
        <MapFilterDrawer
          open={filterDrawerOpen}
          onClose={handleFilterDrawerClose}
        />
      </Box>

      <Popover
        className={classes.popover}
        open={
          Boolean(anchorEl) &&
          (activeFilter === "state" ||
            activeFilter === "region" ||
            activeFilter === "trade" ||
            activeFilter === "workticketType" ||
            activeFilter === "insights" ||
            activeFilter === "workticketStatus")
        }
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            width: 600,
            maxHeight: 600,
          },
        }}
      >
        {activeFilter === "state" ? (
          <StateFilter
            selectedStates={selectedStates}
            onStateSelect={handleStateSelect}
          />
        ) : activeFilter === "region" ? (
          <RegionFilter
            selectedRegions={selectedRegions}
            onRegionSelect={handleRegionSelect}
          />
        ) : activeFilter === "trade" ? (
          <TradeFilter
            tradeFilterOptions={tradeFilterOptions}
            selectedTrades={selectedTrades}
            onTradeSelect={handleTradeSelect}
          />
        ) : activeFilter === "workticketType" ? (
          <WorkticketTypeFilter
            selectedTypes={selectedWorkticketTypes}
            onTypeSelect={handleWorkticketTypeSelect}
          />
        ) : activeFilter === "insights" ? (
          <InsightsFilter
            insightsFilterOptions={insightsFilterOptions}
            loading={insightsLoading}
            error={insightsError}
            selectedInsights={selectedInsights}
            onInsightSelect={handleInsightSelect}
          />
        ) : activeFilter === "workticketStatus" ? (
          <StatusFilter
            selectedStatuses={selectedStatuses}
            onStatusSelect={handleStatusSelect}
          />
        ) : null}
      </Popover>
    </div>
  );
};

export default SpecialFilters;
