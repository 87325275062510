import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import { useMediaQuery, useTheme } from "@material-ui/core";
import useStyles from "./styles";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";

const LunaSearchCategories = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const { categories, onSelectCategory, model } = props;

  return (
    <Box
      className={clsx(
        classes.categoriesContainer,
        {
          [classes.categoriesContainerZero]:
            categories.length <= 1 || model === "locations",
        },
        {
          [classes.categoriesContainerLocations]: model === "locations",
        },
        {
          [classes.categoriesContainerLocationZero]:
            categories.length <= 1 && model === "locations",
        }
      )}
    >
      {categories.length > 1 && (
        <>
          <Typography className={classes.title}>Categories</Typography>
          <Box className={classes.categoriesButtonContainer}>
            {categories.map((category, index) => (
              <Button
                key={index}
                className={
                  category.selected
                    ? classes.categoryButtonSelected
                    : classes.categoryButton
                }
                onClick={() => onSelectCategory(category.id)}
              >
                {category.value}
              </Button>
            ))}
          </Box>
          {model === "locations" && isXs ? null : (
            <Divider style={{ width: "100%", background: "#ECECEC" }} />
          )}
        </>
      )}
    </Box>
  );
};

export default LunaSearchCategories;
