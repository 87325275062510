import React, { useRef, useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { MAP_CONFIG } from "./mapConfig";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DashboardHeader from "../dashboardHeader";
import { mapIcons } from "./mapIcons";
import { initialVisibleLayersByUser } from "./mapUtils";
import theme from "theme";
import {
  LocationLayerManager,
  WorkticketLayerManager,
  PartnerLayerManager,
  BuildingsLayerManager,
} from "./MapLayerManager";
import LayerToggleControl from "./layerToggleControl";
import { GeoJsonTransformers } from "./geoJsonTransformers";

import SpecialFilters from "./specialFilters";
import { useHistory } from "react-router-dom";
import httpService from "services/httpService";
import { apiUrl, mapBoxKey } from "lib/config";
import {
  useMapContext,
  useMapDispatch,
  buildQueryString,
} from "./context/useMapContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import { debounce } from "lodash";

mapboxgl.accessToken = mapBoxKey;

const MAX_MAP_PITCH_IN_DEGREES = 60;
const MAX_POINTS_OF_INTEREST = 1000;
// const DEFAULT_WORKTICKET_TYPE = 0;
// const DEFAULT_BADGE_STATUS = 0;

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    height: 4, // Same height as LinearProgress
  },
  progress: {
    margin: 0,
  },
  pointsOfInterestToast: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    borderRadius: 4,
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
    lineHeight: 1,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    "& > p:last-child": {
      fontSize: 13,
      opacity: 0.8,
    },
  },
  pointsOfInterestToastText: {
    fontSize: 16,
    fontWeight: 600,
  },
}));

const useInitializeMap = (mapContainer, setMapLoaded, handleZoom) => {
  const map = useRef(null);

  const style = document.createElement("style");
  style.textContent = `
   .mapboxgl-popup-content {
     padding: 0;
     background: none;
     box-shadow: none;
   }
   .mapboxgl-popup-tip {
     display: none;
   }
   .location-hover-popup {
     background-color: #fff;
     border-radius: 4px;
     border: 1px solid rgba(232, 248, 203, 1);
     padding: 5px;
   }    
 `;

  document.head.appendChild(style);

  useEffect(() => {
    const initialMapLoad = async () => {
      if (!map.current && mapContainer.current) {
        let mapCenter = MAP_CONFIG.MAP.DEFAULT_CENTER;
        let mapZoom = MAP_CONFIG.MAP.DEFAULT_ZOOM;
        const savedMapState = JSON.parse(localStorage.getItem("mapState"));
        if (!savedMapState) {
          const response = await httpService.get(`${apiUrl}/map/initial`);
          if (
            response?.data?.centerLongitude &&
            response?.data?.centerLatitude
          ) {
            mapCenter = [
              response.data.centerLongitude,
              response.data.centerLatitude,
            ];
            mapZoom = response.data.zoom_level;
          }
        } else {
          mapCenter = savedMapState.center;
          mapZoom = savedMapState.zoom;
        }

        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: MAP_CONFIG.MAP.STYLE_URL,
          center: mapCenter,
          zoom: mapZoom,
        });
        console.log("Map created successfully");

        map.current.on("style.load", () => {
          setMapLoaded(true);
        });

        map.current.on("zoom", handleZoom);
      }
    };
    console.log("Initializing map...");
    initialMapLoad();

    return () => {
      // if (map.current) {
      //   map.current.remove();
      // }
    };
  }, []);

  return map;
};

const DashboardMapbox = ({ isSurfaceUser }) => {
  const mapContainer = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [lunaSearchActive, setLunaSearchActive] = useState(false);
  const [lunaTriggerRefresh, setLunaTriggerRefresh] = useState(false);
  const isFlyingRef = useRef(false); // Use a ref to track flying status
  const isClickProcessingRef = useRef(false);
  const isZoomingWithButtonsRef = useRef(false);
  const currentPopupRef = useRef(null);
  const hoverPopupRef = useRef(null);
  const currentUser = JSON.parse(localStorage.getItem("user"));

  // Load saved layers or fall back to default
  const savedLayers = localStorage.getItem("mapVisibleLayers");
  const [visibleLayers, setVisibleLayers] = useState(
    savedLayers
      ? JSON.parse(savedLayers)
      : initialVisibleLayersByUser(currentUser)
  );

  const [isLoading, setIsLoading] = useState(false);

  const [layerManagers, setLayerManagers] = useState({
    LOCATIONS: null,
    WORKTICKETS: null,
    PARTNERS: null,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const abortControllerRef = useRef(null);
  const queryModeRef = useRef(false);
  const mapState = useMapContext();
  const dispatch = useMapDispatch();
  const [showToast, setShowToast] = useState(false);
  const [pointsOfInterestCount, setPointsOfInterestCount] = useState(null);
  const [pointsOfInterestCountAll, setPointsOfInterestCountAll] =
    useState(null);
  const [pendingRequests, setPendingRequests] = useState(0);

  const classes = useStyles();

  const pointsOfInterestCategory = (count) => {
    if (visibleLayers.length > 1) {
      return count === 1 ? "Point of Interest" : "Points of Interest";
    } else {
      return count === 1
        ? visibleLayers[0].toLowerCase().slice(0, -1)
        : visibleLayers[0].toLowerCase();
    }
  };

  const pointsOfInterestFullText = (
    visibleLayers,
    count,
    maxPointsOfInterest = MAX_POINTS_OF_INTEREST
  ) => {
    if (visibleLayers.length === 0) return "";
    if (count === null) return "";

    if (maxPointsOfInterest > count) {
      return `Over ${count.toLocaleString()} ${pointsOfInterestCategory(
        visibleLayers,
        count
      )}`;
    } else {
      return `${count.toLocaleString()} ${pointsOfInterestCategory(
        visibleLayers,
        count
      )}`;
    }
  };

  const handleSessionId = (response) => {
    if (response?.data?.sessionId) {
      dispatch({
        type: "SET_SESSION_ID",
        sessionId: response.data.sessionId,
      });
    }
  };

  const fetchLocationData = async (
    filterPath,
    boundingBoxQueryParams = null,
    sessionIdParam = null
  ) => {
    setPendingRequests((prev) => prev + 1);
    try {
      const signal = abortControllerRef.current?.signal;
      const response = await httpService.get(
        `${apiUrl}/map/locations?filters[]=${filterPath}${
          boundingBoxQueryParams ? `${boundingBoxQueryParams}` : ""
        }${sessionIdParam ? `${sessionIdParam}` : ""}`,
        { signal }
      );

      handleSessionId(response);

      return {
        data: GeoJsonTransformers.locationsAsGeoJSON({
          mapLocationList: {
            data: {
              jobs: response?.data?.data,
            },
          },
        }),
        count: response?.data?.count,
        countAll: response?.data?.countAll,
      };
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else if (axios.isCancel(error)) {
        console.log("=== request cancelled ===");
      } else {
        console.log("Error fetching locations:", error);
        setSnackbar({
          open: true,
          message: "Failed to load locations",
          severity: "error",
        });
      }
      return null;
    } finally {
      setPendingRequests((prev) => prev - 1);
    }
  };

  const fetchWorkticketData = async (
    filterPath,
    boundingBoxQueryParams = null,
    sessionIdParam = null
  ) => {
    setPendingRequests((prev) => prev + 1);
    try {
      const signal = abortControllerRef.current?.signal;
      const response = await httpService.get(
        `${apiUrl}/map/locations/worktickets?filters[]=${filterPath}${
          boundingBoxQueryParams ? `${boundingBoxQueryParams}` : ""
        }${sessionIdParam ? `${sessionIdParam}` : ""}`,
        { signal }
      );

      handleSessionId(response);

      return {
        data: GeoJsonTransformers.workticketsAsGeoJSON(
          response?.data?.data,
          0.00001 * Math.sqrt(response?.data?.data?.length)
        ),
        count: response?.data?.count,
        countAll: response?.data?.countAll,
      };
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else if (axios.isCancel(error)) {
        console.log("=== request cancelled ===");
      } else {
        console.log("Error fetching worktickets:", error);
        setSnackbar({
          open: true,
          message: "Failed to load worktickets",
          severity: "error",
        });
      }
      return null;
    } finally {
      setPendingRequests((prev) => prev - 1);
    }
  };

  const fetchPartnerData = async (
    filterPath,
    boundingBoxQueryParams = null,
    sessionIdParam = null
  ) => {
    setPendingRequests((prev) => prev + 1);
    try {
      const signal = abortControllerRef.current?.signal;
      const response = await httpService.get(
        `${apiUrl}/map/locations/partners?filters[]${filterPath}${
          boundingBoxQueryParams ? `${boundingBoxQueryParams}` : ""
        }${sessionIdParam ? `${sessionIdParam}` : ""}`,
        { signal }
      );

      handleSessionId(response);

      return {
        data: GeoJsonTransformers.partnersAsGeoJSON({
          mapPartnerList: {
            data: {
              partners: response?.data?.data,
            },
          },
        }),
        count: response?.data?.count,
        countAll: response?.data?.countAll,
      };
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else if (axios.isCancel(error)) {
        console.log("=== request cancelled ===");
      } else {
        console.log("Error fetching partners:", error);
        setSnackbar({
          open: true,
          message: "Failed to load partners",
          severity: "error",
        });
      }
      return null;
    } finally {
      setPendingRequests((prev) => prev - 1);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleZoom = () => {
    if (!map.current || isFlyingRef.current || isZoomingWithButtonsRef.current)
      return;
    const zoomLevel = map.current.getZoom();
    const targetPitch = calculatePitch(zoomLevel, MAX_MAP_PITCH_IN_DEGREES);
    map.current.setPitch(targetPitch);
  };

  const history = useHistory();
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const refreshMapData = async (layers = visibleLayers) => {
    if (lunaSearchActive) return;
    if (!map.current) return;
    const boundingBox = getBoundingBox();

    setIsLoading(true);
    let boundingBoxQueryParams = null;
    let sessionIdParam = null;

    if (boundingBox) {
      boundingBoxQueryParams = `&ne_lat=${boundingBox.ne.lat}&ne_lng=${boundingBox.ne.lng}&sw_lat=${boundingBox.sw.lat}&sw_lng=${boundingBox.sw.lng}`;
    }

    if (mapState.sessionId) {
      sessionIdParam = `&sessionId=${mapState.sessionId}`;
    }

    // Cancel any pending requests
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    try {
      const filterQuery = buildQueryString(mapState.mapFilters);

      // Fetch all data in parallel
      const fetchPromises = [];
      let newLocationData = null;
      let newWorkticketData = null;
      let newPartnerData = null;
      let newLocationCount = null;
      let newLocationCountAll = null;
      let newWorkticketCount = null;
      let newWorkticketCountAll = null;
      let newPartnerCount = null;
      let newPartnerCountAll = null;

      if (layers.includes("LOCATIONS")) {
        fetchPromises.push(
          fetchLocationData(
            filterQuery,
            boundingBoxQueryParams,
            sessionIdParam
          ).then((response) => {
            const { data, count, countAll } = response;
            newLocationData = data;
            newLocationCount = count;
            newLocationCountAll = countAll;
          })
        );
      }

      if (layers.includes("WORKTICKETS")) {
        fetchPromises.push(
          fetchWorkticketData(
            filterQuery,
            boundingBoxQueryParams,
            sessionIdParam
          ).then((response) => {
            const { data, count, countAll } = response;
            newWorkticketData = data;
            newWorkticketCount = count;
            newWorkticketCountAll = countAll;
          })
        );
      }

      if (layers.includes("PARTNERS")) {
        fetchPromises.push(
          fetchPartnerData(
            filterQuery,
            boundingBoxQueryParams,
            sessionIdParam
          ).then((response) => {
            const { data, count, countAll } = response;
            newPartnerData = data;
            newPartnerCount = count;
            newPartnerCountAll = countAll;
          })
        );
      }

      await Promise.all(fetchPromises);
      // If layers aren't initialized yet, initialize them
      if (!isDataLoaded) {
        const options = {
          hoverPopupRef,
          currentPopupRef,
          isFlyingRef,
          isClickProcessingRef,
          theme,
          history,
        };

        const locationLayerManager = new LocationLayerManager(
          map.current,
          options
        );
        const workticketLayerManager = new WorkticketLayerManager(
          map.current,
          options
        );
        const partnerLayerManager = new PartnerLayerManager(
          map.current,
          options
        );
        const buildingsLayerManager = new BuildingsLayerManager(
          map.current,
          options
        );

        setLayerManagers({
          LOCATIONS: locationLayerManager,
          WORKTICKETS: workticketLayerManager,
          PARTNERS: partnerLayerManager,
          BUILDINGS: buildingsLayerManager,
        });

        const initializationPromises = [];

        initializationPromises.push(
          locationLayerManager.addLayersForType(
            "LOCATIONS",
            newLocationData || null
          )
        );

        initializationPromises.push(
          workticketLayerManager.addLayersForType(
            "WORKTICKETS",
            newWorkticketData || null
          )
        );

        initializationPromises.push(
          partnerLayerManager.addLayersForType(
            "PARTNERS",
            newPartnerData || null
          )
        );

        initializationPromises.push(
          buildingsLayerManager.addLayersForType("BUILDINGS")
        );

        await Promise.all(initializationPromises);

        setIsDataLoaded(true);
        setIsLoading(false);
      } else {
        // Only update visible layers
        const updatePromises = [];

        if (layerManagers.LOCATIONS && layers.includes("LOCATIONS")) {
          updatePromises.push(
            layerManagers.LOCATIONS.updateData(newLocationData)
          );
        }

        if (layerManagers.WORKTICKETS && layers.includes("WORKTICKETS")) {
          updatePromises.push(
            layerManagers.WORKTICKETS.updateData(newWorkticketData)
          );
        }

        if (layerManagers.PARTNERS && layers.includes("PARTNERS")) {
          updatePromises.push(
            layerManagers.PARTNERS.updateData(newPartnerData)
          );
        }

        if (updatePromises.length > 0) {
          await Promise.all(updatePromises);
        }

        console.log("[refreshMapData - update] Setting isLoading to false");
        setIsLoading(false);
      }

      setShowToast(true);

      setPointsOfInterestCount(
        newLocationCount + newWorkticketCount + newPartnerCount
      );

      setPointsOfInterestCountAll(
        newLocationCountAll + newWorkticketCountAll + newPartnerCountAll
      );

      setToastTimeout();
    } catch (error) {
      console.log("[refreshMapData - error]");
      if (error.name !== "AbortError") {
        console.log("Error refreshing map data:", error);
      }
      setIsLoading(false);
      setToastTimeout();
    }
  };

  const handleMapMoveEnd = useCallback(
    debounce(() => {
      if (!map.current || isFlyingRef.current || queryModeRef.current) return;
      const center = map.current.getCenter();
      const zoom = map.current.getZoom();

      localStorage.setItem(
        "mapState",
        JSON.stringify({
          center: [center.lng, center.lat],
          zoom: zoom,
        })
      );

      refreshMapData();
    }, 500),
    [visibleLayers, refreshMapData]
  );

  const getBoundingBox = () => {
    if (!map.current) return null;

    const bounds = map.current.getBounds();
    const boundingBox = {
      ne: { lat: bounds.getNorthEast().lat, lng: bounds.getNorthEast().lng },
      sw: { lat: bounds.getSouthWest().lat, lng: bounds.getSouthWest().lng },
    };
    return boundingBox;
  };

  const map = useInitializeMap(
    mapContainer,
    setMapLoaded,
    handleZoom,
    handleMapMoveEnd
  );
  // Keep minimal dependencies

  // Helper function to get current count of visible features
  const getVisibleFeaturesCount = (layers) => {
    if (!map.current) return null;

    let totalCount = 0;

    if (layers.includes("WORKTICKETS")) {
      const workticketCount = map.current.queryRenderedFeatures({
        layers: ["worktickets-active"],
      }).length;
      console.log("=== total worktickets: ", workticketCount);
      totalCount += workticketCount;
    }

    if (layers.includes("PARTNERS")) {
      const partnerCount = map.current.queryRenderedFeatures({
        layers: ["partners-normal"],
      }).length;
      console.log("=== total partners: ", partnerCount);
      totalCount += partnerCount;
    }

    if (layers.includes("LOCATIONS")) {
      const locationCount = map.current.queryRenderedFeatures({
        layers: ["locations-normal"],
      }).length;
      console.log("=== total locations: ", locationCount);
      totalCount += locationCount;
    }

    console.log("=== totalCount: ", totalCount);
    return totalCount;
  };

  const handleMyLocationClick = () => {
    if (!map.current) return;

    if ("geolocation" in navigator) {
      setSnackbar({
        open: true,
        message: "Fetching your location...",
        severity: "info",
      });

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const coordinates = [longitude, latitude];

          // Clear loading snackbar
          setSnackbar((prev) => ({ ...prev, open: false }));

          isFlyingRef.current = true;

          map.current.flyTo({
            center: coordinates,
            zoom: 12,
            essential: true,
          });

          map.current.once("moveend", () => {
            isFlyingRef.current = false;
          });
        },
        (error) => {
          console.error("Geolocation error:", error);
          setSnackbar({
            open: true,
            message:
              "Unable to access your location. Please check your browser permissions.",
            severity: "error",
          });
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      setSnackbar({
        open: true,
        message: "Geolocation is not supported by your browser",
        severity: "error",
      });
    }
  };

  const handleZoomIn = () => {
    if (!map.current) return;
    isZoomingWithButtonsRef.current = true;
    map.current.easeTo({
      zoom: map.current.getZoom() + 1,
      duration: 300,
    });
    map.current.once("moveend", () => {
      isZoomingWithButtonsRef.current = false;
    });
  };

  const handleZoomOut = () => {
    if (!map.current) return;
    isZoomingWithButtonsRef.current = true;
    map.current.easeTo({
      zoom: map.current.getZoom() - 1,
      duration: 300,
    });
    map.current.once("moveend", () => {
      isZoomingWithButtonsRef.current = false;
    });
  };

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        console.log("=== aborting request ===");
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const toastTimeoutRef = useRef(null);

  const setToastTimeout = useCallback(() => {
    const timeoutInMs = 7500;
    if (toastTimeoutRef.current) {
      clearTimeout(toastTimeoutRef.current);
    }

    toastTimeoutRef.current = setTimeout(() => {
      setShowToast(false);
      setPointsOfInterestCount(null);
    }, timeoutInMs);
  }, []);

  // Add cleanup
  useEffect(() => {
    return () => {
      if (toastTimeoutRef.current) {
        clearTimeout(toastTimeoutRef.current);
      }
    };
  }, []);

  const handleToggleLayer = useCallback(
    (layerType) => {
      const isLayerCurrentlyVisible = visibleLayers.includes(layerType);
      const newVisibleLayers = isLayerCurrentlyVisible
        ? visibleLayers.filter((layer) => layer !== layerType)
        : [...visibleLayers, layerType];

      setVisibleLayers(newVisibleLayers);

      // Save to localStorage
      localStorage.setItem(
        "mapVisibleLayers",
        JSON.stringify(newVisibleLayers)
      );

      // Update layer visibility
      const manager = layerManagers[layerType];
      if (manager) {
        manager.setVisibility(!isLayerCurrentlyVisible);
      }

      // Only refresh map data if we're adding a new layer
      if (!isLayerCurrentlyVisible) {
        refreshMapData(newVisibleLayers);
      }
    },
    [layerManagers, visibleLayers, mapState.mapFilters]
  );

  const isFirstRender = useRef(true);

  useEffect(() => {
    // We're already handling the first render, so we don't need to refresh the map data
    // until the map filters change
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    refreshMapData();
  }, [mapState.mapFilters]);

  useEffect(() => {
    if (!lunaSearchActive && lunaTriggerRefresh) {
      setLunaTriggerRefresh(false);
      refreshMapData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lunaSearchActive, lunaTriggerRefresh]);

  useEffect(() => {
    return () => {
      handleMapMoveEnd.cancel();
    };
  }, [handleMapMoveEnd]);

  useEffect(() => {
    if (mapLoaded && map.current) {
      loadMapImages(map.current);
      refreshMapData();
    }
  }, [mapLoaded]);

  useEffect(() => {
    map.current?.off("moveend", handleMapMoveEnd);
    map.current?.on("moveend", handleMapMoveEnd);

    return () => {
      map.current?.off("moveend", handleMapMoveEnd);
    };
  }, [visibleLayers, handleMapMoveEnd]);

  const handleLunaSearchResults = useCallback(
    (searchResults) => {
      console.log("[handleLunaSearchResults] Setting isLoading to true");
      setIsLoading(true);

      const transformedData = {
        locations: GeoJsonTransformers.locationsAsGeoJSON({
          mapLocationList: {
            data: {
              jobs: searchResults?.locations?.data || [],
            },
          },
        }),
        worktickets: GeoJsonTransformers.workticketsAsGeoJSON(
          searchResults?.worktickets?.data || [],
          0.000001
        ),
        partners: GeoJsonTransformers.partnersAsGeoJSON({
          mapPartnerList: {
            data: {
              partners: searchResults?.partners?.data || [],
            },
          },
        }),
      };

      // Update layers first
      if (layerManagers.LOCATIONS) {
        layerManagers.LOCATIONS.updateData(transformedData.locations);
      }
      if (layerManagers.WORKTICKETS) {
        layerManagers.WORKTICKETS.updateData(transformedData.worktickets);
      }
      if (layerManagers.PARTNERS) {
        layerManagers.PARTNERS.updateData(transformedData.partners);
      }

      // Handle search location if provided
      if (searchResults?.search_location) {
        const { center_lat, center_lon, zoom_level } =
          searchResults.search_location;

        // Set flying ref to prevent other map updates during animation
        isFlyingRef.current = true;

        map.current.flyTo({
          center: [center_lon, center_lat],
          zoom: zoom_level,
          duration: 2000, // 2 seconds animation
          essential: true,
        });
        map.current.once("moveend", () => {
          console.log("=== onMoveEnd");
          isFlyingRef.current = false;
        });
      }

      // After updating layers
      setTimeout(() => {
        console.log(
          "=== [handleLunaSearchResults] visibleLayers: ",
          visibleLayers
        );
        const newCount = getVisibleFeaturesCount(visibleLayers);
        setPointsOfInterestCount(newCount);
        setShowToast(true);
      }, 500);

      console.log("[handleLunaSearchResults] Setting isLoading to false");
      setIsLoading(false);

      // Set timeout to hide toast
      setToastTimeout();
    },
    [layerManagers, map]
  );

  useEffect(() => {
    setIsLoading(pendingRequests > 0);
  }, [pendingRequests]);

  const getPointsOfInterestSubtext = (count, countAll) => {
    if (!count || !countAll) return "";

    return count < countAll
      ? "Zoom in or filter to see more"
      : "Select a point to view more details";
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <DashboardHeader />
        <SpecialFilters
          onLunaSearchResults={handleLunaSearchResults}
          onResetData={refreshMapData}
          setIsLoading={setIsLoading}
          setLunaSearchActive={setLunaSearchActive}
          setLunaTriggerRefresh={setLunaTriggerRefresh}
        />
        <Box className={classes.progressContainer}>
          {(isLoading || !map.current) && (
            <LinearProgress className={classes.progress} />
          )}
        </Box>
        <Box
          ref={mapContainer}
          sx={{
            flexGrow: 1,
            position: "relative",
            "& .mapboxgl-canvas": {
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              height: "100%",
            },
          }}
        />
      </Box>
      {isDataLoaded && (
        <LayerToggleControl
          visibleLayers={visibleLayers}
          onToggleLayer={handleToggleLayer}
          onMyLocationClick={handleMyLocationClick}
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
        />
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
      {!isLoading && pointsOfInterestCount > 0 && (
        <Snackbar
          open={showToast}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onClose={() => setShowToast(false)}
        >
          <Box className={classes.pointsOfInterestToast}>
            <Typography className={classes.pointsOfInterestToastText}>
              {isLoading
                ? ""
                : pointsOfInterestFullText(
                    visibleLayers,
                    pointsOfInterestCount,
                    pointsOfInterestCountAll
                  )}
            </Typography>
            {!isLoading && (
              <Typography>
                {getPointsOfInterestSubtext(
                  pointsOfInterestCount,
                  pointsOfInterestCountAll
                )}
              </Typography>
            )}
          </Box>
        </Snackbar>
      )}
    </>
  );
};
export default DashboardMapbox;

// Helper functions
function calculatePitch(zoomLevel, maxPitch) {
  if (zoomLevel < 13) {
    return 0;
  }
  return Math.min(maxPitch, (zoomLevel - 13) * 12);
}

function loadMapImages(map) {
  const loadImage = (iconName, image) => {
    map.loadImage(image, (error, img) => {
      if (error) {
        console.error(`Failed to load map icon ${iconName}:`, error);
        return;
      }
      if (map.hasImage(iconName)) {
        // Image ${iconName} already loaded, skipping...
        return;
      }
      map.addImage(iconName, img);
    });
  };

  const loadSimpleIcons = () => {
    const simpleIcons = {
      location: ["active", "normal", "hover"],
      partner: ["active", "normal", "hover"],
    };

    Object.entries(simpleIcons).forEach(([type, states]) => {
      const prefix = type.slice(0, 3);
      states.forEach((state) => {
        loadImage(`${prefix}-${state}`, mapIcons[type][state]);
      });
    });
  };

  const loadWorkticketIcons = () => {
    Object.entries(MAP_CONFIG.ICONS.WORKTICKET).forEach(
      ([typeId, typeName]) => {
        Object.values(MAP_CONFIG.WORKTICKET_STATUSES).forEach((status) => {
          Object.values(MAP_CONFIG.ICONS.STATES).forEach((state) => {
            const iconName = `wt-${typeName}-${status.name}${state.suffix}`;
            loadImage(iconName, mapIcons[typeId][status.id][state.id]);
          });
        });
      }
    );
  };

  loadSimpleIcons();
  loadWorkticketIcons();
}
