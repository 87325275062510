import React, { useEffect, useState } from "react";
import { Box, makeStyles, IconButton } from "@material-ui/core";
import classNames from "classnames";
import { TrashIcon } from "@heroicons/react/24/outline";
import { PushPinIcon } from "assets/icons/pushPinIcon";
import { useLocationDetailsContext } from "pages/locationDetails/context";

const useStyles = makeStyles((theme) => ({
  root: {
    aspectRatio: "135 / 98",
    borderRadius: 8,
    position: "relative",
    overflow: "hidden",
    boxShadow: "0px 2px 20px 0px rgba(89, 89, 89, 0.25)",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  actionsContainer: {
    position: "absolute",
    top: 8,
    right: 8,
    // transform: "translateY(-50%)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  actionButton: {
    padding: 0,
    width: 32,
    height: 32,
    backgroundColor: "#F8F8F8",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
    "&:disabled": {
      opacity: 0.75,
      backgroundColor: "#F8F8F8",
    },
  },
  pinnedImageButton: {
    backgroundColor: "#E8F8CB",
    "&:hover": {
      backgroundColor: "#E8F8CB",
    },
    "&:disabled": {
      backgroundColor: "#E8F8CB",
    },
  },
  actionIcon: {
    width: 20,
    height: 20,
    color: "#747474",
  },
  pinnedImageIcon: {
    color: "#328914",
  },
  loadingIcon: {
    animation: "$spin 1.5s linear infinite",
  },
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
}));

export const ImageTile = ({ image, handleAddAction }) => {
  const classes = useStyles();
  const [imageItem, setImageItem] = useState(null);
  const { images } = useLocationDetailsContext();

  useEffect(() => {
    setImageItem(image);
  }, [image]);

  const handlePinImage = () => {
    const newPinStatus = Boolean(Number(imageItem.is_pinned)) ? 0 : 1;
    setImageItem({ ...imageItem, is_pinned: newPinStatus });
    handleAddAction({
      id: imageItem.id,
      action: "ACTION_PIN",
      value: newPinStatus,
    });
  };

  const handleDeleteImage = () => {
    setImageItem(null);
    handleAddAction({
      id: imageItem.id,
      action: "ACTION_DELETE",
    });
  };

  if (!imageItem) return null;

  const isPinned = Number(imageItem.is_pinned);
  const pinningDisabled =
    images.filter((img) => Number(img.is_pinned)).length >= 4;

  return (
    <Box className={classes.root}>
      <img className={classes.image} src={image.file_url} alt="location" />
      <Box className={classes.actionsContainer}>
        <IconButton
          className={classNames(
            classes.actionButton,
            isPinned && classes.pinnedImageButton
          )}
          onClick={handlePinImage}
          disabled={pinningDisabled && !isPinned}
        >
          <PushPinIcon
            className={classes.actionIcon}
            color={isPinned ? "#328914" : "#747474"}
          />
        </IconButton>
        {Number(image.can_delete) ? (
          <IconButton
            className={classes.actionButton}
            onClick={handleDeleteImage}
          >
            <TrashIcon className={classes.actionIcon} />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  );
};
