import React, { useState, useContext } from "react";
import * as classNames from "classnames";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import FormInputTimeAdo from "components/ui/FormContent/formInputTimeAdo";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import MessageDialog from "components/ui/dialog/messageDialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TicketIcon from "@material-ui/icons/Receipt";
import { saveTimekeepingManual } from "services/workticketService";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import { logException } from "components/util/logUtil";
import { formatAppDateTZ } from "components/util/timeFormat";
import useStyles from "./styles";

const WorkticketTimekeepingManual = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { users } = globalUi;
  const [stateContext] = useWorkticketView();
  const [date, setDate] = useState(moment());
  const [dateOut, setDateOut] = useState(moment());
  const [timeIn, setTimeIn] = useState(moment());
  const [timeOut, setTimeOut] = useState(moment());
  const [user, setUser] = useState(0);
  const [drive, setDrive] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [error, setError] = useState([]);

  const { workticket, isLoading } = stateContext ?? null;

  const handleDriveChange = (event) => {
    setDrive(event.target.checked);
  };

  const handleChangeAssignedTo = (event, value) => {
    if (value) {
      setUser(value.id);
    } else {
      setUser(0);
    }
    setError([]);
  };

  const handleSaveTimekeeping = async () => {
    if (!user) {
      setError([
        {
          key: "users",
          message: "Please select at least one user to assign.",
        },
      ]);
      return;
    }
    try {
      let data = {};
      const tz = workticket?.job?.timezone;

      const inDate = `${formatAppDateTZ(
        `${date.format("YYYY-MM-DD")} ${timeIn.format("HH:mm:ss")}`,
        "YYYY-MM-DD HH:mm:00",
        tz
      )}`;

      const outDate = `${formatAppDateTZ(
        `${dateOut.format("YYYY-MM-DD")} ${timeOut.format("HH:mm:ss")}`,
        "YYYY-MM-DD HH:mm:00",
        tz
      )}`;

      if (!drive) {
        data = {
          user_id: user,
          clock_in: inDate,
          clock_out: outDate,
        };
      } else {
        data = {
          user_id: user,
          drive_in: inDate,
          drive_out: outDate,
        };
      }
      await saveTimekeepingManual(workticket.id, data);
      props.handleClose();
      setOpenSuccess(true);
    } catch (e) {
      logException(e, "Cannot manual timekeeping");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            className={classes.wrapperClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
          {!isLoading && (
            <>
              <Grid container spacing={2} className={classes.dialogHeader}>
                <Grid item sm={12}>
                  <Box className={classes.formIconDialog}>
                    <TicketIcon className={classes.iconDialog} />
                  </Box>
                  <Typography
                    variant="h4"
                    className={classes.formTitleDialog}
                    gutterBottom
                  >
                    Workticket {workticket.number}
                  </Typography>
                  <Typography
                    variant="h5"
                    className={classes.formSubtitleDialog}
                    gutterBottom
                  >
                    Enter timekeeping manually
                  </Typography>
                </Grid>
              </Grid>

              <Box className={classes.formContainerTk}>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={12}>
                    <FormInputDateAdo
                      name="date"
                      label="In Date"
                      value={date}
                      handleChange={setDate}
                    />
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <FormInputTimeAdo
                      name="time_in"
                      label="In Time"
                      value={timeIn}
                      handleChange={setTimeIn}
                    />
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <FormInputDateAdo
                      name="date"
                      label="Out Date"
                      value={dateOut}
                      handleChange={setDateOut}
                    />
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <FormInputTimeAdo
                      name="time_out"
                      label="Out Time"
                      value={timeOut}
                      handleChange={setTimeOut}
                    />
                  </Grid>
                  <Grid item md={12} sm={12}>
                    <FormSelectChipsAuto
                      gridSizes={[{ size: "md", val: 12 }]}
                      options={users.filter(
                        (u) => u.tier_classification !== "subcontractor_admin"
                      )}
                      name="users"
                      label="Assigned To"
                      handleChange={handleChangeAssignedTo}
                      value={users.find((usr) => usr.id === user)}
                      error={error}
                    />
                  </Grid>
                </Grid>

                {!props.disableTravel && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={drive}
                        onChange={handleDriveChange}
                        value={true}
                        color="secondary"
                      />
                    }
                    label={`Travel Time`}
                    className={classes.containerOptions}
                  />
                )}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.actionsDialogWrapper}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classNames(classes.button, classes.buttonOutlined)}
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            className={classNames(classes.button, classes.buttonPrimary)}
            onClick={handleSaveTimekeeping}
          >
            Enter
          </Button>
        </DialogActions>
      </Dialog>
      <MessageDialog
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title="Success"
        message="Timekeeping manual entry has been saved."
      />
    </>
  );
};

export default WorkticketTimekeepingManual;
