import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {
  Box,
  Typography,
  makeStyles,
  IconButton,
  Button,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { Input, EditLocationImages, Dropdown } from "./elements";
import { useLocationDetailsContext } from "pages/locationDetails/context";

import {
  useToggleImagePin,
  useDeleteLocationImage,
  useUpdateLocationDetails,
  useFetchTradesList,
} from "pages/locationDetails/api";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    inset: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999,
  },
  modal: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
    padding: "44px 24px 24px",
    maxWidth: 600,
    width: "90%",
    maxHeight: "90vh",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    gap: 40,
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: 20,
    right: 20,
    height: 32,
    width: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeIcon: {
    height: 24,
    width: 24,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    color: "#4D4D4D",
  },
  mapPinIcon: {
    width: 24,
    height: 24,
  },
  headerText: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.1px",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 8,
  },
  footerButton: {
    textTransform: "none",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
    letterSpacing: "0.25px",
    padding: "8px 32px",
  },
  cancelButton: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },
  saveButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.inverted,
    fontWeight: 600,
  },
}));

export const EditLocationDetailsModal = ({
  editLocationModalOpen,
  setEditLocationModalOpen,
}) => {
  const classes = useStyles();
  const modalRef = useRef(null);
  const {
    details: { data },
    images,
  } = useLocationDetailsContext();
  const { mutate: toggleImagePin } = useToggleImagePin();
  const { mutateAsync: updateLocationImages } = useDeleteLocationImage();
  const { mutate: updateLocationDetails } = useUpdateLocationDetails();
  const { data: tradesList } = useFetchTradesList();
  const [actionsForm, setActionsForm] = useState([]);
  const [locationName, setLocationName] = useState("");
  const [locationAddress, setLocationAddress] = useState("");
  const [tradesProvided, setTradesProvided] = useState([]);
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  const closeDialogActions = () => {
    setIsLoadingLocal(true);
    const actionsFiles = actionsForm.filter(
      (action) => action.action === "ACTION_ADD_FILE"
    );
    if (actionsFiles.length > 0) {
      actionsFiles.forEach(async (action) => {
        await updateLocationImages(action.id);
      });
    }
    setIsLoadingLocal(false);
    setEditLocationModalOpen(false);
    setActionsForm([]);
  };

  useEffect(() => {
    if (data) {
      setLocationName(data.name);
      setLocationAddress(data.address);
      setTradesProvided(data?.trades?.map((trade) => trade.id) || []);
    }
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        editLocationModalOpen &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        closeDialogActions();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editLocationModalOpen, setEditLocationModalOpen]);

  const onSaveChanges = () => {
    if (actionsForm.length > 0) {
      actionsForm.forEach((action) => {
        if (action.action === "ACTION_PIN") {
          const imageItem = images.find((image) => image.id === action.id);
          if (
            imageItem &&
            Number(imageItem.is_pinned) !== Number(action.value)
          ) {
            toggleImagePin(action.id);
          }
        }
        if (action.action === "ACTION_DELETE") {
          updateLocationImages(action.id);
        }
      });
    }
    updateLocationDetails({
      name: locationName,
      trades: tradesProvided,
    });
    setEditLocationModalOpen(false);
  };

  const handleAddAction = (item) => {
    const actionsFormCopy = [
      ...actionsForm.filter(
        (a) => a.id !== item.id && a.action === item.action
      ),
    ];
    setActionsForm([...actionsFormCopy, item]);
  };

  return (
    editLocationModalOpen &&
    createPortal(
      <Box className={classes.root}>
        <Box className={classes.modal} ref={modalRef}>
          <IconButton
            className={classes.closeButton}
            onClick={closeDialogActions}
          >
            <Close className={classes.closeIcon} />
          </IconButton>
          <Box className={classes.contentContainer}>
            <Box className={classes.header}>
              <MapPinIcon className={classes.mapPinIcon} />
              <Typography className={classes.headerText}>
                Edit Location Details
              </Typography>
            </Box>
            <Input
              label="Location Name"
              value={locationName}
              onChange={({ target }) => setLocationName(target.value)}
            />
            <Input
              label="Location Address"
              value={locationAddress}
              readOnly
              onChange={({ target }) => setLocationAddress(target.value)}
            />
            <Dropdown
              label="Trades Provided*"
              value={tradesProvided}
              onChange={({ target }) => setTradesProvided(target.value)}
              options={tradesList || []}
            />
            <EditLocationImages handleAddAction={handleAddAction} />
          </Box>
          <Box className={classes.footer}>
            <Button
              className={`${classes.footerButton} ${classes.cancelButton}`}
              variant="outlined"
              onClick={closeDialogActions}
            >
              Cancel
            </Button>
            <Button
              className={`${classes.footerButton} ${classes.saveButton}`}
              variant="contained"
              onClick={onSaveChanges}
              disableElevation
              disabled={isLoadingLocal}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      </Box>,
      document.body
    )
  );
};
