import { useMutation, useQueryClient } from "react-query";
import http from "services/httpService";
import { apiUrl } from "lib/config";
import { useParams } from "react-router-dom";

export const useDeleteLocationImage = () => {
  const { locationId } = useParams();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (fileId) => http.delete(`${apiUrl}/locations/files/${fileId}`),
    onMutate: async (fileId) => {
      await queryClient.cancelQueries(["locationFiles", locationId]);

      const previousImages = queryClient.getQueryData([
        "locationFiles",
        locationId,
      ]);

      queryClient.setQueryData(["locationFiles", locationId], (old) => {
        if (!old?.data?.data?.image) return old;

        const updatedImages = { ...old };

        updatedImages.data.data.image = old.data.data.image.filter(
          (img) => img.id !== fileId
        );

        return updatedImages;
      });

      return { previousImages };
    },
    onError: (_, __, context) => {
      if (context?.previousImages) {
        queryClient.setQueryData(
          ["locationFiles", locationId],
          context.previousImages
        );
      }
    },
    onSettled: () =>
      queryClient.invalidateQueries(["locationFiles", locationId]),
  });
};
