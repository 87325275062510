import React, { useState, useEffect, useRef } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import lunaAnimatedSpinner from "../../../../assets/icons/loadingLunaSearch.gif";
import lunaSolidPlaceholder from "../../../../assets/icons/solidLunaSearch.jpg";
import { makeStyles } from "@material-ui/core/styles";
import LunaInputSearch from "components/common/LunaSearch/lunaInputSearch";
import { Mixpanel } from "services/mixpanel";

const useStyles = makeStyles((theme) => ({
  clearSearchButton: {
    padding: "6px 8px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  SearchButton: {
    padding: "6px 8px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const SearchBar = (props) => {
  const classes = useStyles();
  const [stateValue, setStateValue] = useState("");
  const [anchor, setAnchorEl] = useState(null);
  const [configuredParameters, setConfiguredParameters] = useState(false);
  const [indexTextPosition, setIndexTextPosition] = useState();
  const [enableParameters, setEnableParameters] = useState(false);
  const [selectedSuggestedAction, setSelectedSuggestedAction] = useState();
  const textFieldRef = useRef();
  const {
    handleSearch,
    placeholder,
    isSearchAi,
    isLoadingSearch,
    handleSearchClear,
    module,
  } = props;

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (isLoadingSearch) {
      setAnchorEl(null);
    }
  }, [isLoadingSearch]);

  useEffect(() => {
    if (configuredParameters && indexTextPosition) {
      setEnableParameters(false);
      setConfiguredParameters(false);
      textFieldRef?.current.focus();
      textFieldRef?.current.setSelectionRange(
        indexTextPosition,
        indexTextPosition
      );
    }
  }, [configuredParameters, indexTextPosition, textFieldRef]);

  const handleChange = (event) => {
    setStateValue(event.target.value);
    if (event.target.value === "") {
      handleSearchClear();
      textFieldRef.current.blur();
    }
  };

  const handleClear = () => {
    setStateValue("");
    handleSearchClear();
  };

  const focus = (event) => {
    if (stateValue.length > 0) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const blur = (e) => {
    if (
      e.relatedTarget &&
      (e.relatedTarget.classList.contains("MuiButton-root") ||
        e.relatedTarget.classList.contains("MuiPaper-rounded"))
    ) {
      e.target.focus();
      return;
    }
    setAnchorEl(null);
  };

  const onHandleSelectedSuggestedAction = (suggestedAction) => {
    if (suggestedAction?.placeholders[0] !== "") {
      setEnableParameters(true);
      setSelectedSuggestedAction(suggestedAction);
      setAnchorEl(null);
      return;
    }
    setStateValue(suggestedAction.prompt);
  };

  const onHandleSelectedSuggestedActionParameter = (
    formattedPrompt,
    positionIndex
  ) => {
    setStateValue(formattedPrompt);
    setIndexTextPosition(positionIndex + 1);
    setEnableParameters(false);
    setConfiguredParameters(true);
  };

  const id = "popperMenu";

  const getStartAdornment = () => {
    if (isSearchAi) {
      return (
        <InputAdornment position="start">
          <img
            src={isLoadingSearch ? lunaAnimatedSpinner : lunaSolidPlaceholder}
            width="40"
            height="40"
            alt="Loading"
          />
        </InputAdornment>
      );
    }
    return null;
  };

  const getEndAdornment = () => {
    return (
      <InputAdornment position="end">
        {props.searchTriggered && setStateValue !== "" ? (
          <IconButton
            aria-label="clear"
            className={classes.SearchButton}
            onClick={handleClear}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <IconButton
            aria-label="clear"
            className={classes.clearSearchButton}
            onClick={(ev) => {
              handleSearch(ev, stateValue);
              if (isSearchAi) {
                Mixpanel.identify(user.id);
                Mixpanel.track(`Luna Search ${module}`, {
                  searchPrompt: stateValue,
                });
              }
            }}
          >
            <SearchIcon
              className={classes.searchIcon}
              style={{ color: "#ececec" }}
            />
          </IconButton>
        )}
      </InputAdornment>
    );
  };

  return (
    <LunaInputSearch
      enableParameters={enableParameters}
      selectedSuggestedAction={selectedSuggestedAction}
      onHandleSelectedSuggestedActionParameter={
        onHandleSelectedSuggestedActionParameter
      }
      handleSearch={handleSearch}
      stateValue={stateValue}
      id={id}
      handleChange={handleChange}
      placeholder={placeholder}
      isSearchAi={isSearchAi}
      isLoadingSearch={isLoadingSearch}
      module={module}
      startAdornment={getStartAdornment()}
      endAdornment={getEndAdornment()}
      anchor={anchor}
      focus={focus}
      blur={blur}
      textFieldRef={textFieldRef}
      onHandleSelectedSuggestedAction={onHandleSelectedSuggestedAction}
    />
  );
};

export default SearchBar;
