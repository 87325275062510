import { useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { tradeIcons } from "./tradeIcons"; // You'll need to create this
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: 350,
    backgroundColor: "#ffffff",
    borderRadius: 8,
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  searchField: {
    // marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.12)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.23)",
      },
    },
    "& .MuiInputBase-input": {
      padding: theme.spacing(1),
      fontSize: 14,
    },
    "& .MuiInputAdornment-root": {
      color: "rgba(0, 0, 0, 0.54)",
    },
  },
  selectedTradesContainer: {
    marginTop: theme.spacing(2),
  },
  tradesContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  card: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    boxShadow: "none",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    transition: "all 0.2s ease",
    "&:hover": {
      borderColor: theme.palette.secondary.main,
      backgroundColor: "rgba(25, 118, 210, 0.04)",
    },
    "&.selected": {
      borderColor: theme.palette.secondary.main,
      // backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  },
  cardAction: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 36,
    width: "100%",
  },
  tradeIconContainer: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: 16,
      height: 16,
      color: "rgba(0, 0, 0, 0.54)",
    },
  },
  tradeIcon: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  tradeName: {
    fontSize: 13,
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: 400,
    lineHeight: 1.2,
  },
  searchResults: {
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,
    backgroundColor: "#ffffff",
    borderRadius: 8,
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
    maxHeight: 240,
    overflowY: "auto",
    marginTop: 4,
  },
  searchResultItem: {
    padding: theme.spacing(1, 2),
    cursor: "pointer",
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  searchWrapper: {
    position: "relative",
  },
  selectedTradesSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectedTradesHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  clearAllButton: {
    color: "#ffffff",
    backgroundColor: theme.palette.secondary.main,
    textTransform: "none",
    padding: theme.spacing(0, 1.5),
    minWidth: "auto",
    borderRadius: 4,
    fontSize: 13,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(1),
    backgroundColor: "#f8f8f8",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    marginTop: theme.spacing(2),
  },
  paginationText: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 14,
    marginRight: theme.spacing(1),
  },
  paginationControls: {
    display: "flex",
  },
  paginationButton: {
    padding: 6,
    color: theme.palette.secondary.main,
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)",
    },
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
  },
  selectedTradesHeaderText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  tradeContent: {
    display: "flex",
    alignItems: "center",
  },
  closeIcon: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 16,
    marginLeft: theme.spacing(0.5),
  },
  filterDivider: {
    borderTop: "solid 1px #ececec",
    margin: theme.spacing(2, 0),
  },
}));

const TradeFilter = ({
  tradeFilterOptions = [],
  selectedTrades = [],
  onTradeSelect,
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredTrades = tradeFilterOptions.filter((trade) =>
    trade?.trade_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const selectedTradeObjects = tradeFilterOptions.filter((trade) =>
    selectedTrades.includes(trade.id)
  );

  const availableTrades = tradeFilterOptions.filter(
    (trade) => !selectedTrades.includes(trade.id)
  );

  const paginatedTrades = availableTrades.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(availableTrades.length / itemsPerPage);

  const handleTradeSelect = (tradeId) => {
    if (selectedTrades.includes(tradeId)) {
      onTradeSelect(selectedTrades.filter((id) => id !== tradeId));
    } else {
      onTradeSelect([...selectedTrades, tradeId]);
    }
    setSearchTerm("");
    setShowResults(false);
  };

  const handleClearAll = () => {
    onTradeSelect([]);
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Filter by Trades Provided
      </Typography>

      <Box className={classes.searchWrapper}>
        <TextField
          fullWidth
          placeholder="Find Trade"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setShowResults(true);
          }}
          onFocus={() => setShowResults(true)}
          className={classes.searchField}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="disabled" />
              </InputAdornment>
            ),
          }}
        />

        {showResults && searchTerm && (
          <Box className={classes.searchResults}>
            {filteredTrades.map((trade) => (
              <Box
                key={trade.id}
                className={classes.searchResultItem}
                onClick={() => handleTradeSelect(trade.id)}
              >
                {trade.trade_name}
              </Box>
            ))}
          </Box>
        )}
      </Box>

      {selectedTradeObjects.length > 0 && (
        <Box className={classes.selectedTradesSection}>
          <Box className={classes.selectedTradesHeader}>
            <Typography
              className={classes.selectedTradesHeaderText}
              variant="subtitle2"
            >
              Selected
            </Typography>
            <Button
              className={classes.clearAllButton}
              onClick={handleClearAll}
              size="small"
            >
              Clear All
            </Button>
          </Box>
          <Box className={classes.tradesContainer}>
            {selectedTradeObjects.map((trade) => (
              <Card key={trade.id} className={`${classes.card} selected`}>
                <CardActionArea
                  className={classes.cardAction}
                  onClick={() => handleTradeSelect(trade.id)}
                >
                  <Box className={classes.tradeContent}>
                    <Box className={classes.tradeIconContainer}>
                      <img
                        src={tradeIcons[trade.icon] || tradeIcons.default}
                        alt={`${trade.trade_name} icon`}
                        className={classes.tradeIcon}
                      />
                    </Box>
                    <Typography className={classes.tradeName}>
                      {trade.trade_name}
                    </Typography>
                  </Box>
                  <CloseIcon className={classes.closeIcon} />
                </CardActionArea>
              </Card>
            ))}
          </Box>
          <div className={classes.filterDivider} />
        </Box>
      )}

      {availableTrades.length > 0 && (
        <>
          <Box className={classes.tradesContainer}>
            {paginatedTrades.map((trade) => (
              <Card key={trade.id} className={classes.card}>
                <CardActionArea
                  className={classes.cardAction}
                  onClick={() => handleTradeSelect(trade.id)}
                >
                  <Box className={classes.tradeIconContainer}>
                    <img
                      src={tradeIcons[trade.icon] || tradeIcons.default}
                      alt={`${trade.trade_name} icon`}
                      className={classes.tradeIcon}
                    />
                  </Box>
                  <Typography className={classes.tradeName}>
                    {trade.trade_name}
                  </Typography>
                </CardActionArea>
              </Card>
            ))}
          </Box>

          <Box className={classes.pagination}>
            <Typography className={classes.paginationText}>
              {`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(
                currentPage * itemsPerPage,
                availableTrades.length
              )} of ${availableTrades.length}`}
            </Typography>
            <Box className={classes.paginationControls}>
              <IconButton
                className={classes.paginationButton}
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                size="small"
              >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                className={classes.paginationButton}
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                size="small"
              >
                <ChevronRightIcon />
              </IconButton>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default TradeFilter;
