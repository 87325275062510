import accessControlServicesIcon from "assets/icons/trades/accessControlServices.png";
import airConditioningServicesIcon from "assets/icons/trades/airConditioningServices.png";
import aluminiumManufacturingIcon from "assets/icons/trades/aluminiumManufacturing.png";
import applianceSalesAndServiceIcon from "assets/icons/trades/applianceSales&Service.png";
import asbestosRemovalIcon from "assets/icons/trades/asbestosRemoval.png";
import batControlIcon from "assets/icons/trades/batControl.png";
import bioHazardCleaningIcon from "assets/icons/trades/bioHazardCleaning.png";
import birdControlIcon from "assets/icons/trades/birdControl.png";
import boatDeckingIcon from "assets/icons/trades/boatDecking.png";
import breakAndFixIcon from "assets/icons/trades/breakAndFix.png";
import carpentryIcon from "assets/icons/trades/carpentry.png";
import carpetCareIcon from "assets/icons/trades/carpetCare.png";
import christmasLightsIcon from "assets/icons/trades/christmasLights(rental&Installations).png";
import concreteIcon from "assets/icons/trades/concrete.png";
import constructionCleaningIcon from "assets/icons/trades/constructionCleaning.png";
import coolerFreezerMaintenanceIcon from "assets/icons/trades/coolerFreezerMaintenance.png";
import covidCleaningIcon from "assets/icons/trades/covidCleaning.png";
import crimeCleanUpIcon from "assets/icons/trades/crimeCleanUp.png";
import defaultIcon from "assets/icons/trades/default.png";
import demolitionServicesIcon from "assets/icons/trades/demolitionServices.png";
import deodorizingIcon from "assets/icons/trades/deodorizing.png";
import disasterRestorationIcon from "assets/icons/trades/disasterRestoration.png";
import disinfectionServicesIcon from "assets/icons/trades/disinfectionServices.png";
import drywallIcon from "assets/icons/trades/drywall.png";
import electricalIcon from "assets/icons/trades/electrical.png";
import excavationIcon from "assets/icons/trades/excavation.png";
import fireDamageRestorationIcon from "assets/icons/trades/fireDamageRestoration.png";
import fireLightAndSafetyIcon from "assets/icons/trades/fireLightAndSafety.png";
import floodingCleanupIcon from "assets/icons/trades/floodingCleanup.png";
import floorCareIcon from "assets/icons/trades/floorCare.png";
import floorResurfacingAndInstallationIcon from "assets/icons/trades/floorResurfacingAndInstallation.png";
import generalConstructionIcon from "assets/icons/trades/generalConstruction.png";
import graffitiRemovalIcon from "assets/icons/trades/graffitiRemoval.png";
import gutterCleaningIcon from "assets/icons/trades/gutterCleaning.png";
import handymanIcon from "assets/icons/trades/handyman.png";
import hvacAndMechanicalIcon from "assets/icons/trades/hvacAndMechanical.png";
import irrigationSystemServicesIcon from "assets/icons/trades/irrigationSystemServices.png";
import janitorialIcon from "assets/icons/trades/janitorial.png";
import landscapingIcon from "assets/icons/trades/landscaping.png";
import lightingRetrofitIcon from "assets/icons/trades/lightingRetrofit.png";
import locksmithServicesIcon from "assets/icons/trades/locksmithServices.png";
import moldRemediationIcon from "assets/icons/trades/moldRemediation.png";
import movingServicesIcon from "assets/icons/trades/movingServices.png";
import paintingIcon from "assets/icons/trades/painting.png";
import parkingLotStripingIcon from "assets/icons/trades/parkingLotStriping.png";
import parkingLotSweepingIcon from "assets/icons/trades/parkingLotSweeping.png";
import partEquipmentSupplierIcon from "assets/icons/trades/partEquipmentSupplier.png";
import pestControlIcon from "assets/icons/trades/pestControl.png";
import pigeonControlIcon from "assets/icons/trades/pigeonControl.png";
import pipingIcon from "assets/icons/trades/piping.png";
import plumbingIcon from "assets/icons/trades/plumbing.png";
import portableStorageRentalIcon from "assets/icons/trades/portableStorageRental.png";
import pressureWashingIcon from "assets/icons/trades/pressureWashing.png";
import restorationIcon from "assets/icons/trades/restoration.png";
import roofCleaningIcon from "assets/icons/trades/roofCleaning.png";
import roofingIcon from "assets/icons/trades/roofing.png";
import screenRepairIcon from "assets/icons/trades/screenRepair.png";
import sewageCleanupIcon from "assets/icons/trades/sewageCleanup.png";
import shutterInstallationIcon from "assets/icons/trades/shutterInstallation.png";
import smokeAndFireDamageIcon from "assets/icons/trades/smokeAndFireDamage.png";
import snowRemovalIcon from "assets/icons/trades/snowRemoval.png";
import softWashingIcon from "assets/icons/trades/softWashing.png";
import solarPanelServicesIcon from "assets/icons/trades/solarPanelServices.png";
import staffingServicesIcon from "assets/icons/trades/staffingServices.png";
import terminalCleaningIcon from "assets/icons/trades/terminalCleaning.png";
import tornadoAndHurricaneDamageIcon from "assets/icons/trades/tornadoAndHurricaneDamage.png";
import trashRemovalIcon from "assets/icons/trades/trashRemoval.png";
import upholsteryCleaningIcon from "assets/icons/trades/upholsteryCleaning.png";
import ventCleaningIcon from "assets/icons/trades/ventCleaning.png";
import wasteAndWaterTreatmentIcon from "assets/icons/trades/waste&WaterTreatment.png";
import waterDamageIcon from "assets/icons/trades/waterDamage.png";
import weldingServicesIcon from "assets/icons/trades/weldingServices.png";
import windowCleaningIcon from "assets/icons/trades/windowCleaning.png";
import windowFilmsAndTintingIcon from "assets/icons/trades/windowFilmsAndTinting.png";
import windowInstallationIcon from "assets/icons/trades/windowInstallation.png";
import woodworkRepairsAndRestorationsIcon from "assets/icons/trades/woodworkRepairs&Restorations.png";

export const tradeIcons = {
  accessControlServices: accessControlServicesIcon,
  airConditioningServices: airConditioningServicesIcon,
  aluminiumManufacturing: aluminiumManufacturingIcon,
  "applianceSales&Service": applianceSalesAndServiceIcon,
  asbestosRemoval: asbestosRemovalIcon,
  batControl: batControlIcon,
  bioHazardCleaning: bioHazardCleaningIcon,
  birdControl: birdControlIcon,
  boatDecking: boatDeckingIcon,
  breakAndFix: breakAndFixIcon,
  carpentry: carpentryIcon,
  carpetCare: carpetCareIcon,
  "christmasLights(rental&Installations)": christmasLightsIcon,
  commercialPainting: paintingIcon,
  concrete: concreteIcon,
  constructionCleaning: constructionCleaningIcon,
  coolerFreezerMaintenance: coolerFreezerMaintenanceIcon,
  covidCleaning: covidCleaningIcon,
  crimeCleanUp: crimeCleanUpIcon,
  default: defaultIcon,
  demolitionServices: demolitionServicesIcon,
  deodorizing: deodorizingIcon,
  disasterRestoration: disasterRestorationIcon,
  disinfectionServices: disinfectionServicesIcon,
  drywall: drywallIcon,
  electrical: electricalIcon,
  excavation: excavationIcon,
  fireDamageRestoration: fireDamageRestorationIcon,
  fireLightAndSafety: fireLightAndSafetyIcon,
  floodingCleanup: floodingCleanupIcon,
  floorCare: floorCareIcon,
  floorResurfacingAndInstallation: floorResurfacingAndInstallationIcon,
  generalConstruction: generalConstructionIcon,
  graffitiRemoval: graffitiRemovalIcon,
  gutterCleaning: gutterCleaningIcon,
  handyman: handymanIcon,
  hvacAndMechanical: hvacAndMechanicalIcon,
  irrigationSystemServices: irrigationSystemServicesIcon,
  janitorial: janitorialIcon,
  landscaping: landscapingIcon,
  lightingRetrofit: lightingRetrofitIcon,
  locksmithServices: locksmithServicesIcon,
  moldRemediation: moldRemediationIcon,
  movingServices: movingServicesIcon,
  painting: paintingIcon,
  parkingLotStriping: parkingLotStripingIcon,
  parkingLotSweeping: parkingLotSweepingIcon,
  partEquipmentSupplier: partEquipmentSupplierIcon,
  pestControl: pestControlIcon,
  pigeonControl: pigeonControlIcon,
  piping: pipingIcon,
  plumbing: plumbingIcon,
  portableStorageRental: portableStorageRentalIcon,
  pressureWashing: pressureWashingIcon,
  restoration: restorationIcon,
  roofCleaning: roofCleaningIcon,
  roofing: roofingIcon,
  screenRepair: screenRepairIcon,
  sewageCleanup: sewageCleanupIcon,
  shutterInstallation: shutterInstallationIcon,
  smokeAndFireDamage: smokeAndFireDamageIcon,
  snowRemoval: snowRemovalIcon,
  softWashing: softWashingIcon,
  solarPanelServices: solarPanelServicesIcon,
  staffingServices: staffingServicesIcon,
  terminalCleaning: terminalCleaningIcon,
  tornadoAndHurricaneDamage: tornadoAndHurricaneDamageIcon,
  trashRemoval: trashRemovalIcon,
  upholsteryCleaning: upholsteryCleaningIcon,
  ventCleaning: ventCleaningIcon,
  "waste&WaterTreatment": wasteAndWaterTreatmentIcon,
  waterDamage: waterDamageIcon,
  weldingServices: weldingServicesIcon,
  windowCleaning: windowCleaningIcon,
  windowFilmsAndTinting: windowFilmsAndTintingIcon,
  windowInstallation: windowInstallationIcon,
  "woodworkRepairs&Restorations": woodworkRepairsAndRestorationsIcon,
};
