export const formatDate = (date) => {
  const hasTime =
    date.includes("T") || date.includes(" ") || date.includes(":");

  const localDate = new Date(hasTime ? date : date + "T00:00:00");

  return localDate.toLocaleDateString();
};

export const formatStringAllCapsWithUnderscores = (string) =>
  string?.toUpperCase().replace(/\s+/g, "_");
export const formatStringReadable = (string) =>
  string
    ?.toLowerCase()
    .replace(/_/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
