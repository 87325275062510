import { Box, makeStyles } from "@material-ui/core";
import {
  DesktopHeader,
  DesktopImageGallery,
  LocationInformation,
  Divider,
} from "pages/locationDetails/components";
import {
  RecentInspections,
  ActiveWorktickets,
  CompletedWorktickets,
  LocationInsights,
  ReactiveRequests,
  KeyMetrics,
  Comments,
  RecentSurveys,
} from "pages/locationDetails/sections";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
    flex: 1,
    maxWidth: "100%",
    gap: 20,
    padding: "0",
    backgroundColor: "#FFF",
  },
  mainContent: {
    display: "flex",
    gap: 40,
    maxWidth: "100%",
    paddingLeft: 20,
    paddingRight: 20,
  },
  mainColumn: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingBottom: 60,
    flexShrink: 1,
    minWidth: 0,
    maxWidth: "100%",
  },
  imageAndInfoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
  },
  rightColumn: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F8F8F8",
    gap: 24,
    padding: "24px 24px 60px",
    width: "40%",
    maxWidth: 450,
    flexShrink: 0,
  },
}));

export const DesktopLayout = ({ sectionData }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <DesktopHeader />
      <Box className={classes.mainContent}>
        <Box className={classes.mainColumn}>
          <Box className={classes.imageAndInfoContainer}>
            <DesktopImageGallery />
            <LocationInformation />
          </Box>
          <Divider />
          <RecentInspections />
          <Divider />
          <ActiveWorktickets />
          <Divider />
          <CompletedWorktickets />
          <Divider />
          <LocationInsights />
          <Divider />
          <ReactiveRequests />
          <Divider />
          <KeyMetrics />
        </Box>
        <Box className={classes.rightColumn}>
          <Comments />
          <RecentSurveys />
        </Box>
      </Box>
    </Box>
  );
};
