import { Box, Typography, makeStyles } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  label: {
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 600,
    letterSpacing: "0.5px",
  },
  select: {
    minHeight: 40,
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 400,
    letterSpacing: "0.25px",
    "& .MuiSelect-select": {
      padding: "8px 14px",
    },
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D9D9D9",
      },
      "&:hover fieldset": {
        borderColor: "#D9D9D9",
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
    gap: 4,
  },
  chip: {
    margin: 2,
  },
  menuPaper: {
    maxHeight: 300,
    zIndex: theme.zIndex.modal + 1,
    position: "absolute",
    left: 0,
  },
}));

export const Dropdown = ({ label, value, onChange, options }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.label}>{label}</Typography>
      <FormControl variant="outlined" fullWidth>
        <Select
          id="trades-dropdown-select"
          multiple
          disablePortal
          value={value || []}
          onChange={onChange}
          className={classes.select}
          renderValue={(selected) => (
            <Box className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={options.find((option) => option.id === value)?.name}
                  className={classes.chip}
                  size="small"
                />
              ))}
            </Box>
          )}
          MenuProps={{
            container: () => document.getElementById("trades-dropdown-select"),
            getContentAnchorEl: () =>
              document.getElementById("trades-dropdown-select"),
            PaperProps: {
              className: classes.menuPaper,
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
