import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Fab,
  Portal,
  Snackbar,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Room as RoomIcon } from "@material-ui/icons";
import { MagnifyingGlassPlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import ImageCarousel from "../../../components/common/Carousel/ImageCarousel";
import CommentsIcon from "assets/icons/commentsIcon";
import CommentDialog from "../commentDialog";
import mapIcon from "assets/icons/map-icon.png";
import { addLocationComment } from "services/locationService";
import { Alert } from "@material-ui/lab";
import { getUserType } from "./mapUtils";
import httpService from "services/httpService";
import { apiUrl } from "lib/config";
const LocationCard = ({
  // customer,
  // location,
  id,
  // address,
  // trades,
  onCanvass,
  onFlyTo,
  onClose,
}) => {
  const [cardState, setCardState] = useState("normal");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [locationDetails, setLocationDetails] = useState(null);
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);

  const handleOnFlyTo = () => {
    onFlyTo();
    setCardState("selected");
  };

  const fetchLocationDetails = async () => {
    try {
      const response = await httpService.get(`${apiUrl}/map/locations/${id}`);
      console.log("=== location details response ===", response);
      setLocationDetails(response.data.data);
    } catch (error) {
      console.log("Error fetching location details:", error);
    }
  };

  const handleCommentSubmit = async (commentText) => {
    try {
      const currentUser = JSON.parse(localStorage.getItem("user"));

      await addLocationComment(id, {
        body: commentText,
        tab: getUserType(currentUser),
      });

      setSnackbarMessage("Comment added successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      setCommentDialogOpen(false);
    } catch (error) {
      setSnackbarMessage("Failed to add comment. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      width: 328,
      boxShadow: "0 4px 20px rgba(89, 89, 89, 0.25)",
      borderRadius: 8,
    },
    canvassButton: {
      borderRadius: 4,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.inverted,
      textTransform: "none",
      padding: "4px 8px",
      "&:hover": {
        transition: "0.2s ease-in",
        backgroundColor: theme.palette.secondary.main,
        filter: "brightness(1.1)",
      },
      minWidth: 100,
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
      gap: 8,
      padding: "8px 12px 12px 12px !important",
    },
    fabButton: {
      borderRadius: 50,
      backgroundColor: "white",
      width: 32,
      height: 32,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 0,
      "&:hover": {
        transition: "0.2s ease-in",
        backgroundColor: "white",
        opacity: 0.8,
      },
    },
    fabContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "absolute",
      top: 12,
      right: 12,
      left: 12,
    },
    fabContainerLeft: {
      display: "flex",
      alignItems: "center",
      gap: 5,
    },
    flyToButton: {
      marginTop: 10,
      textTransform: "none",
      color: "#4F98BC",
      borderColor: "#4F98BC",
      "&:hover": {
        transition: "0.2s ease-in",
        backgroundColor: "#4F98BC",
        color: "white",
      },
    },
    customerName: {
      fontSize: 12,
      fontWeight: 400,
      marginBottom: 4,
    },
    locationHeader: {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontWeight: 600,
    },
    imageContainer: {
      position: "relative",
      height: "100%",
    },
    tradesContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
    },
    trade: {
      padding: "4px 8px",
      border: "1px solid #ECECEC",
      borderRadius: 50,
      fontSize: 12,
      lineHeight: 1.1,
    },
    skeleton: {
      backgroundColor: "#F8F8F8",
      opacity: 0.7,
    },
    skeletonText: {
      backgroundColor: "#F8F8F8",
      borderRadius: 4,
      opacity: 0.7,
    },
    disabledButton: {
      opacity: 0.5,
      pointerEvents: "none",
    },
  }));

  const classes = useStyles();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (id) {
      fetchLocationDetails();
    }
  }, [id]);

  if (!locationDetails) {
    return (
      <Box>
        <Card className={classes.root}>
          <Box className={classes.imageContainer}>
            <Skeleton
              variant="rect"
              height={200}
              className={classes.skeleton}
            />
            <Box className={classes.fabContainer}>
              <Box className={classes.fabContainerLeft}>
                <Skeleton
                  variant="circle"
                  width={32}
                  height={32}
                  className={classes.skeleton}
                />
                <Skeleton
                  variant="circle"
                  width={32}
                  height={32}
                  className={classes.skeleton}
                />
              </Box>
              <Skeleton
                variant="circle"
                width={32}
                height={32}
                className={classes.skeleton}
              />
            </Box>
          </Box>
          <CardContent className={classes.cardContent}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column" gap={1}>
                <Skeleton
                  variant="text"
                  width={100}
                  className={classes.skeletonText}
                />
                <Skeleton
                  variant="text"
                  width={150}
                  className={classes.skeletonText}
                />
              </Box>
              <Skeleton
                variant="rect"
                width={100}
                height={32}
                className={classes.skeleton}
              />
            </Box>
            <Box display="flex" alignItems="flex-start">
              <Skeleton
                variant="rect"
                width={18}
                height={18}
                className={classes.skeleton}
              />
              <Skeleton
                variant="text"
                width="80%"
                className={classes.skeletonText}
                style={{ marginLeft: 6 }}
              />
            </Box>
            <Box className={classes.tradesContainer}>
              {[1, 2, 3].map((index) => (
                <Skeleton
                  key={index}
                  variant="rect"
                  width={80}
                  height={26}
                  className={classes.skeleton}
                  style={{ borderRadius: 50 }}
                />
              ))}
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }
  const {
    customer: { name: customerName },
    address,
    trades,
    files = [],
  } = locationDetails;
  return (
    <Box>
      <Card className={classes.root}>
        <Box className={classes.imageContainer}>
          {files.length > 0 && (
            <ImageCarousel
              images={files.map((file) => ({
                url: file.url,
                title: file.display_name,
              }))}
            />
          )}
          <Box className={classes.fabContainer}>
            <Box className={classes.fabContainerLeft}>
              <Fab className={classes.fabButton} onClick={handleOnFlyTo}>
                <MagnifyingGlassPlusIcon
                  width={18}
                  height={18}
                  color="#747474"
                />
              </Fab>
              <Fab
                className={classes.fabButton}
                onClick={() => setCommentDialogOpen(true)}
              >
                <CommentsIcon width={18} height={18} color="#747474" />
              </Fab>
            </Box>
            <Fab className={classes.fabButton} onClick={onClose}>
              <XMarkIcon width={20} height={20} color="#747474" />
            </Fab>
          </Box>
        </Box>
        <CardContent className={classes.cardContent}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" flexDirection="column" gap={4}>
              <Typography className={classes.customerName}>
                {customerName}
              </Typography>
              <Typography className={classes.locationHeader}>
                {address}
              </Typography>
            </Box>
            <Button onClick={onCanvass} className={classes.canvassButton}>
              View Location
            </Button>
          </Box>
          <Box display="flex" alignItems="flex-start">
            <img src={mapIcon} alt="map" style={{ marginRight: 6 }} />
            <Typography variant="body2" noWrap>
              <span style={{ fontWeight: 600 }}>Address:</span> {address}
            </Typography>
          </Box>
          <Box className={classes.tradesContainer}>
            {trades.map((trade) => (
              <Typography key={trade.id} className={classes.trade}>
                {trade.name}
              </Typography>
            ))}
          </Box>
        </CardContent>
      </Card>
      <CommentDialog
        open={commentDialogOpen}
        onClose={() => setCommentDialogOpen(false)}
        title={customerName}
        icon={
          <RoomIcon
            style={{
              fontSize: "2rem",
              marginBottom: "10px",
              color: "#4D4D4D",
            }}
          />
        }
        subtitle={"Add a comment to this location"}
        onSubmit={handleCommentSubmit}
      />
      <Portal>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Portal>
    </Box>
  );
};

export default LocationCard;
